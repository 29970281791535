import { NavLink, useLocation } from 'react-router-dom'
import {
  HomeIcon,
  MoonIcon,
  SunIcon,
  ArrowRightOnRectangleIcon as OutlineLogoutIcon,
  UserCircleIcon as OutlinePersonIcon,
  MusicalNoteIcon as MusicIcon,
  DocumentTextIcon as MusicSheetIcon,
  AcademicCapIcon as TeacherIcon,
  ClipboardDocumentListIcon as ExercisesIcon,
} from '@heroicons/react/24/outline';
import { sidebar_config } from '../../config/config';
import Transition from '../../components/Transition';
import { SystemContext } from '../../stores/System';
import { AccountContext } from '../../stores/Account';
import { useNavigate } from 'react-router-dom';
import getUserInfo from '../../api/getUserInfo';
import Backdrop from '../../components/Backdrop';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useSearchParams } from 'react-router-dom';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import SubitoLogo from '../../components/SubitoLogo';
import SidebarShuffleView from './SidebarShuffleView';

const UploadIcon = () => (
  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
  </svg>
);
// create context
export const SidebarContext = React.createContext()

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowDimensions();

  function toggleSidebar() {
    if (width <= 1024 && width >= 768) {
      setIsExpanded(!isExpanded);
    } else {
      setIsSidebarOpen(!isSidebarOpen);
    }
  }

  function closeSidebar() {
    setIsSidebarOpen(false);
    if (width > 768 && width <= 1024) {
      setIsExpanded(false);
    }
  }

  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
      isExpanded,
    }),
    [isSidebarOpen, isExpanded]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};


function SideBarElement(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  let location = useLocation();

  const iconMapping = {
    home: HomeIcon,
    music: MusicIcon,
    sightreading: MusicSheetIcon,
    profile: OutlinePersonIcon,
    exercises: ExercisesIcon,
    teacherdashboard: TeacherIcon,
    upload: UploadIcon,
  };

  const IconComponent = iconMapping[props.icon] || HomeIcon;

  return (
    <li className="relative px-2 py-1" key={props.route}>
      <NavLink
        to={"/dashboard/" + props.route}
        className={({ isActive }) =>
          isActive
            ? `inline-flex items-center w-full text-sm font-semibold ${props.hideLabelOnIpad ? "justify-center" : "px-4"} py-3 rounded-lg bg-gradient-to-r from-violet-600 to-violet-500 text-white shadow-sm transition-all duration-200`
            : `inline-flex items-center w-full text-sm font-semibold transition-all duration-200 text-black dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 ${props.hideLabelOnIpad ? "justify-center" : "px-4"} py-3 rounded-lg hover:shadow-sm`
        }
      >
        <IconComponent className="w-5 h-5 flex-shrink-0 stroke-[1.5]" aria-hidden="true" />
        <span className={`ml-4 ${props.hideLabelOnIpad ? "md:hidden" : ""}`}>{props.label}</span>
      </NavLink>
    </li>
  )
}

function SidebarContent() {

  const { darkMode, setDarkMode } = useContext(SystemContext);

  const { logout, setUserInfo, userInfo, user, setUser } = useContext(AccountContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const isIpadScreen = window.innerWidth >= 768 && window.innerWidth <= 1024;

  useEffect(() => {
    getUserInfo(user).then((obj) => {
      console.log('user info', obj);
      setUserInfo(obj);
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  useEffect(() => {
    if (userInfo.role === 'teacher' && !sidebar_config.some(route => route.route === 'teacherdashboard')) {
      sidebar_config.push({ label: 'Teacher Dashboard', route: 'teacherdashboard', icon: 'teacherdashboard' });
    }
  }, [userInfo]);

  return (
    <div className="pt-4 shadow text-gray-500 dark:text-gray-400 flex flex-col bg-white dark:bg-gray-900" style={{ height: '100vh' }}>
      <a href="/" className="w-full z-10 px-6 mb-6 dark:bg-gray-900 h-16">
        <SubitoLogo />
      </a>
      <div className="overflow-y-auto flex-grow no-scrollbar">
        <ul className="space-y-1 px-3">
          {sidebar_config.map((route) => <SideBarElement {...route} hideLabelOnIpad={isIpadScreen} />)}
        </ul>
        <div className="px-4 mt-4">
          <SidebarShuffleView />
        </div>
      </div>

      {!searchParams.has('ts_stud_id') ? (
        <div className="sticky bottom-0 z-10 p-4 bg-white dark:bg-gray-900">
          <NavLink to={"/dashboard/profile"} className='flex items-center p-4 rounded-xl bg-gradient-to-r from-violet-50 to-violet-100 dark:from-violet-900/30 dark:to-violet-800/30 hover:shadow-md transition-all duration-200'>
            {!userInfo.profile_pic ? (
              <OutlinePersonIcon className="w-8 h-8 dark:text-white rounded-full mr-4 justify-center" />
            ) : (
              <img src={userInfo.profile_pic} alt="" className="w-10 h-10 rounded-full mr-3 justify-center" />
            )}
            <div className='flex flex-col justify-center overflow-hidden'>
              <p className='mb-0 text-sm lg:text-sm text-black dark:text-white font-bold'>
                {userInfo.first_name || userInfo.last_name ? `${userInfo.first_name} ${userInfo.last_name}`.trim() : 'Subito'}
              </p>
              <p className='mb-0 text-xs lg:text-xs text-gray-600 dark:text-white'>
                {userInfo.email}
              </p>
            </div>
            <OutlineLogoutIcon className="w-8 h-8 ml-3 mr-0 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 transition ease-in-out duration-150" aria-hidden="true" onClick={() => { logout().then(() => { setUser(null); navigate('/') }); }} />
          </NavLink>
          <div className="mt-4 flex items-center justify-between px-4 py-3 rounded-lg bg-gray-50 dark:bg-gray-700/50">
            <div className="flex items-center">
              {!darkMode ? (
                <SunIcon className="w-5 h-5 text-amber-500" aria-hidden="true" />
              ) : (
                <MoonIcon className="w-5 h-5 text-blue-400" aria-hidden="true" />
              )}
              <span className="ml-3 text-sm font-medium">
                {darkMode ? 'Dark Mode' : 'Light Mode'}
              </span>
            </div>
            <label className='relative inline-flex items-center cursor-pointer'>
              <input type="checkbox" value="" className="sr-only peer" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600"></div>
            </label>
          </div>
        </div>
      ) : (
        <div className="sticky bottom-0 z-10 pb-3 px-2">
          <NavLink to={"/dashboard/profile"} className='flex items-center p-4 mt-6 rounded-lg bg-amber-500 dark:bg-amber-600 hover:shadow-sm hover:bg-gray-200 dark:hover:bg-violet-700 dark:hover:shadow-sm transition-colors duration-150'>
            {!userInfo.profile_pic ? (
              <OutlinePersonIcon className="w-8 h-8 dark:text-white rounded-full mr-4 justify-center" />
            ) : (
              <img src={userInfo.profile_pic} alt="" className="w-10 h-10 rounded-full mr-3 justify-center" />
            )}
            <div className='flex flex-col justify-center overflow-hidden'>
              <p className='mb-0 text-sm lg:text-sm text-black dark:text-white font-bold'>
                {userInfo.first_name || userInfo.last_name ? `${userInfo.first_name} ${userInfo.last_name}`.trim() : 'Subito'}
              </p>
              <p className='mb-0 text-xs lg:text-xs text-gray-600 dark:text-white text-nowrap'>
                Exit student dashboard
              </p>
            </div>
            <OutlineLogoutIcon className="w-8 h-8 ml-3 mr-0 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 transition ease-in-out duration-150 rotate-180" aria-hidden="true" onClick={() => { logout().then(() => { setUser(null); navigate('/') }); }} />
          </NavLink>
        </div>
      )}
    </div>
  )
}

function DesktopSidebar(props) {
  return (
    <aside className="z-30 flex-shrink-0 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block shadow-sm">
      <SidebarContent />
    </aside>
  )
}

function IpadSidebar() {
  const { isExpanded, toggleSidebar } = useContext(SidebarContext);

  const { darkMode, setDarkMode } = useContext(SystemContext);

  const { logout, setUserInfo, userInfo, user, setUser } = useContext(AccountContext);

  const navigate = useNavigate();

  useEffect(() => {
    getUserInfo(user).then((obj) => {
      setUserInfo(obj);
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  const sidebarClick = (e) => {
    e.stopPropagation();
    toggleSidebar();
  };

  const modeClick = (e) => {
    e.stopPropagation();
    setDarkMode(!darkMode);
  }

  return (
    <aside className={`fixed inset-y-0 z-30 rounded-r-lg shadow-xl ${isExpanded ? 'w-64' : 'w-16'} overflow-y-auto bg-white dark:bg-gray-800 transition-width duration-300 `}>
      <div className="pt-4 text-gray-500 dark:text-gray-400 flex flex-col shadow-xl rounded-lg" style={{ height: '100vh' }} onClick={sidebarClick}>
        <a href="/" className="w-full px-4 shadow-sm bg-white dark:bg-gray-800 h-16">
          {!isExpanded ? (
            <img className="ml-1 h-8" src="/SubitoLogoNoText.png" alt="Not Expanded Logo" />
          ) : (
            <img className="ml-1 h-8" src="/SubitoLogo.png" alt="Expanded Logo" />
          )}
        </a>
        <div className="overflow-y-auto flex-grow no-scrollbar mt-6">
          <ul>
            {sidebar_config.map((route) => <SideBarElement {...route} hideLabelOnIpad={!isExpanded} />)}
          </ul>
        </div>
        {!isExpanded && (
          <div className="flex items-center justify-center my-4">
            {!userInfo.profile_pic ? (
              <OutlinePersonIcon className="w-8 h-8 dark:text-white rounded-full mr-4 justify-center" />
            ) : (
              <img src={userInfo.profile_pic} alt="" className="w-10 h-10 rounded-full justify-center" />
            )}          </div>
        )}
        <div className="sticky bottom-0 z-10 bg-white dark:bg-gray-800 pb-3 px-2">
          {isExpanded && (
            <NavLink to={"/dashboard/profile"} className='flex items-center p-4 mt-6 rounded-lg bg-gray-100 dark:bg-violet-600 hover:shadow-sm hover:bg-gray-200 dark:hover:bg-violet-700 dark:hover:shadow-sm transition-colors duration-150'>
              {!userInfo.profile_pic ? (
                <OutlinePersonIcon className="w-8 h-8 dark:text-white rounded-full mr-4 justify-center" />
              ) : (
                <img src={userInfo.profile_pic} alt="" className="w-10 h-10 rounded-full mr-3 justify-center" />
              )}
              <div className='flex flex-col justify-center overflow-hidden'>
                <p className='mb-0 text-sm lg:text-sm text-black dark:text-white font-bold'>
                  {userInfo.first_name} {userInfo.last_name}
                </p>
                <p className='mb-0 text-xs lg:text-xs text-gray-600 dark:text-white'>
                  {userInfo.email}
                </p>
              </div>
              <OutlineLogoutIcon className="w-8 h-8 ml-3 mr-0 hover:text-gray-700 dark:text-white dark:hover:text-gray-300 transition ease-in-out duration-150 rotate-180" aria-hidden="true" onClick={() => { logout().then(() => { setUser(null); navigate('/') }); }} />
            </NavLink>
          )}
        </div>
        <div className="flex items-center justify-center px-2 pb-5" >
          <button className="w-5 h-5" onClick={modeClick}>
            {darkMode ? <MoonIcon aria-hidden="true" /> : <SunIcon aria-hidden="true" />}
          </button>
          {isExpanded && (
            <>
              {!darkMode ? (
                <span className="font-semibold flex-grow text-left pl-4 text-sm text-gray-900 dark:text-gray-300">Light Mode</span>
              ) : (
                <span className="font-semibold flex-grow text-left pl-4 text-sm text-gray-900 dark:text-gray-300">Dark Mode</span>
              )}
              <label className='relative inline-flex items-center cursor-pointer'>
                <input type="checkbox" value={darkMode} className="sr-only peer" checked={darkMode}></input>
                <div onClick={modeClick} className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet-600"></div>
              </label>
            </>)}
        </div>
      </div>
    </aside >
  );
}



function MobileSidebar() {
  const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)

  return (
    <Transition show={isSidebarOpen}>
      <>
        <Transition
          enter="transition ease-in-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Backdrop onClick={closeSidebar} />
        </Transition>

        <Transition
          enter="transition ease-in-out duration-150"
          enterFrom="opacity-0 transform -translate-x-20"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 transform -translate-x-20"
        >
          <aside className="fixed inset-y-0 z-50 flex-shrink-0 w-64 mt-0 overflow-y-auto bg-white dark:bg-gray-800 lg:hidden" style={{ height: '100vh' }}>
            <SidebarContent />
          </aside>
        </Transition>
      </>
    </Transition>
  )
}

function Sidebar() {
  const { width } = useWindowDimensions();

  return (
    <>
      {width > 1024 && <DesktopSidebar />}
      {width <= 1024 && width >= 768 && <IpadSidebar />}
      {width < 768 && <MobileSidebar />}
    </>
  )
}

export default Sidebar
