import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import listStudents from '../../api/listStudents';
import { daysSinceDate } from '../../utils/datetime';
import { createImageFromInitials } from "../../components/LetterPicture";
import { capitalize, getInitial } from "../../utils/string";
import PracticeWidget from '../components/PracticeWidget';
import LessonCalendar from '../components/LessonCalendar';
import confirmStudent from '../../api/confirmStudent';
import teacherStudioSummary from '../../api/teacherStudioSummary';

const StatCard = ({ label, value, icon }) => (
    <div className="flex items-center space-x-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl">
        <div className="p-3 bg-violet-100 dark:bg-violet-900/30 rounded-lg">
            {icon}
        </div>
        <div>
            <div className="text-2xl font-bold text-gray-900 dark:text-white">
                {value}
            </div>
            <div className="text-sm text-gray-600 dark:text-gray-400">
                {label}
            </div>
        </div>
    </div>
);

const StudioStats = ({ teacherInfo }) => {
    // Calculate studio statistics
    const [summary, setSummary] = useState({});

    const { user } = useContext(AccountContext);

    useEffect(() => {
        teacherStudioSummary(user).then((dat) => {
            console.log('studio summary', dat);
            setSummary(dat);
        }).catch((err) => {
            console.log(err);
        });
    }, [user]);

    return (
        <div className="mb-8 w-full">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
                {teacherInfo?.business_name || 'Your Studio'}
            </h1>
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <StatCard label="Total Students" value={summary?.total_students} icon={
                        <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    } />

                    <StatCard label="Total Practice Mins" value={summary?.total_practice_minutes} icon={
                        <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    } />


                    <StatCard label="Avg Practice Mins" value={summary?.avg_weekly_practice_minutes} icon={
                        <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg>
                    } />

                    <StatCard label={`Top Student (${summary?.top_weekly_practice_minutes} mins/week)`} value={summary?.top_student} icon={
                        <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                        </svg>
                    } />
                </div>
            </div>
        </div>
    );
};

const TeacherDashboard = () => {
    const [students, setStudents] = useState([]);
    const [requests, setRequests] = useState([]);
    const [expandedStudentId, setExpandedStudentId] = useState(null);
    const { user, userInfo } = useContext(AccountContext);
    const teacherInfo = userInfo?.teacher_info;

    useEffect(() => {
        listStudents(user, 0).then((data) => {
            const students = data.data;
            console.log(students)
            setStudents(students.filter(obj => obj.confirmed === 1));
            setRequests(students.filter(obj => obj.confirmed === 0));
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const acceptRequest = (requestId) => {
        confirmStudent(user, requestId, "accept").then((data) => {
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });
        // const acceptedRequestIndex = requests.findIndex(request => request.student_id === requestId);
        // if (acceptedRequestIndex > -1) {
        //     const acceptedStudent = requests[acceptedRequestIndex];
        //     setRequests(currentRequests => currentRequests.filter(request => request.student_id !== requestId));
        //     setStudents(currentStudents => [...currentStudents, acceptedStudent]);
        // }
    };

    const rejectRequest = (requestId) => {
        confirmStudent(user, requestId, "reject").then((data) => {
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });
        // setRequests(prev => prev.filter(request => request.student_id !== requestId));
    };

    const StudentCard = ({ student, isPending, onAccept, onReject, onView }) => (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 p-4 space-y-4">
            <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                    <img
                        className="w-12 h-12 rounded-full object-cover"
                        src={student.picture || createImageFromInitials(200, getInitial(student.full_name), "#7c3aed")}
                        alt={student.full_name}
                    />
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {student.full_name}
                    </p>
                    <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
                        <span>{capitalize(student.instrument)}</span>
                        <span>•</span>
                        <span>{student.level || "Unknown level"}</span>
                    </div>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                        Joined {daysSinceDate(student.date_joined)}d ago
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    {isPending ? (
                        <>
                            <button
                                onClick={() => onAccept(student.student_id)}
                                className="px-3 py-1 text-sm font-medium text-green-600 bg-green-50 rounded-lg hover:bg-green-100 dark:bg-green-900/20 dark:text-green-400 dark:hover:bg-green-900/30 transition-all duration-200"
                            >
                                Accept
                            </button>
                            <button
                                onClick={() => onReject(student.student_id)}
                                className="px-3 py-1 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 dark:bg-red-900/20 dark:text-red-400 dark:hover:bg-red-900/30 transition-all duration-200"
                            >
                                Reject
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => onView(student.student_id)}
                                className="px-3 py-1 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                            >
                                View Dashboard
                            </button>
                            <button
                                onClick={() => setExpandedStudentId(expandedStudentId === student.student_id ? null : student.student_id)}
                                className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors duration-200"
                            >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d={expandedStudentId === student.student_id
                                            ? "M5 15l7-7 7 7"
                                            : "M19 9l-7 7-7-7"}
                                    />
                                </svg>
                            </button>
                        </>
                    )}
                </div>
            </div>
            {!isPending && expandedStudentId === student.student_id && (
                <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                    <PracticeWidget student_id={student.student_id} />
                </div>
            )}
        </div>
    );

    return (
        <div className="space-y-6 p-6">
            <StudioStats teacherInfo={teacherInfo} />

            {/*<LessonCalendar students={students} />

            {/* Pending Applications */}
            {requests.length > 0 && (
                <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Pending Applications
                        </h2>
                        <span className="px-2.5 py-0.5 text-sm font-medium text-violet-600 bg-violet-100 rounded-full dark:bg-violet-900/30 dark:text-violet-400">
                            {requests.length} {requests.length === 1 ? 'pending' : 'pending'}
                        </span>
                    </div>
                    <div className="space-y-3">
                        {requests.map(request => (
                            <StudentCard
                                key={request.student_id}
                                student={request}
                                isPending={true}
                                onAccept={acceptRequest}
                                onReject={rejectRequest}
                            />
                        ))}
                    </div>
                </div>
            )}

            {/* Registered Students */}
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Registered Students
                    </h2>
                    <span className="px-2.5 py-0.5 text-sm font-medium text-violet-600 bg-violet-100 rounded-full dark:bg-violet-900/30 dark:text-violet-400">
                        {students.length} {students.length === 1 ? 'student' : 'students'}
                    </span>
                </div>
                <div className="space-y-3">
                    {students.map(student => (
                        <StudentCard
                            key={student.student_id}
                            student={student}
                            isPending={false}
                            onView={() => window.location.href = '/dashboard/music?ts_stud_id=' + student.student_id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TeacherDashboard;