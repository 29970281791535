import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import BBoxSelector from './BBoxSelector';
import testImage from './test.jpg';
import Select from 'react-select';
import { useSearchParams } from 'react-router-dom';

const noteColors = {
  'whole': 'rgb(239, 68, 68)',     // red-500
  'half': 'rgb(249, 115, 22)',     // orange-500
  'quarter': 'rgb(234, 179, 8)',   // yellow-500
  '8thflag': 'rgb(34, 197, 94)',   // green-500
  '8thblock': 'rgb(16, 185, 129)', // emerald-500
  '16thflag': 'rgb(6, 182, 212)',  // cyan-500
  '16thblock': 'rgb(99, 102, 241)', // indigo-500
  '32ndflag': 'rgb(168, 85, 247)', // purple-500
  '32ndblock': 'rgb(236, 72, 153)' // pink-500
};

export const noteOptions = [
  { value: 'whole', label: 'Whole Note' },
  { value: 'half', label: 'Half Note' },
  { value: 'quarter', label: 'Quarter Note' },
  { value: '8thflag', label: '8th Note (Flag)' },
  { value: '8thblock', label: '8th Note (Beam)' },
  { value: '16thflag', label: '16th Note (Flag)' },
  { value: '16thblock', label: '16th Note (Beam)' },
  { value: '32ndflag', label: '32nd Note (Flag)' },
  { value: '32ndblock', label: '32nd Note (Beam)' }
];

const STEPS = ['block', 'notehead', 'misc'];
const FINAL_PAGE = '2';

const BBoxAnnotator = React.forwardRef(({ url = testImage, borderWidth = 2, inputMethod = 'text', labels = ['object'], onChange = () => { } }, ref) => {
  const [entries, setEntries] = useState([]);
  const [pointer, setPointer] = useState(null);
  const [offset, setOffset] = useState(null);
  const [multiplier, setMultiplier] = useState(1);
  const [status, setStatus] = useState('free');
  const [bBoxAnnotatorStyle, setBboxAnnotatorStyle] = useState({});
  const [imageFrameStyle, setImageFrameStyle] = useState({});
  const [hoveredId, setHoveredId] = useState(null);
  const [isKeyCollapsed, setIsKeyCollapsed] = useState(false);
  const [activeClass, setActiveClass] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const bBoxAnnotatorRef = useRef(null);
  const [searchParams] = useSearchParams();
  const piece = searchParams.get('piece');
  const page = searchParams.get('page') || '1';
  const step = searchParams.get('step') || 'block';

  useEffect(() => {
    if (onChange) {
      onChange(
        entries.map((entry) => ({
          width: Math.round(entry.width * multiplier),
          height: Math.round(entry.height * multiplier),
          top: Math.round(entry.top * multiplier),
          left: Math.round(entry.left * multiplier),
          label: entry.label,
        })),
      );
    }
  }, [entries, multiplier, onChange]);

  useEffect(() => {
    // Calculate available width accounting for both sidebars
    const leftSidebarWidth = 188; // Standard Tailwind sidebar width (16rem)
    const rightSidebarWidth = 288; // Our annotation sidebar (18rem)
    const containerPadding = 64; // 32px on each side
    const availableWidth = window.innerWidth - leftSidebarWidth - rightSidebarWidth - containerPadding;

    const imageElement = new Image();
    imageElement.src = url;
    imageElement.onload = function () {
      const width = imageElement.width;
      const height = imageElement.height;

      // Calculate scale to fit within available width
      const scale = Math.max(width / availableWidth, 1);
      setMultiplier(scale);

      setBboxAnnotatorStyle({
        width: width / scale,
        height: height / scale,
      });
      setImageFrameStyle({
        backgroundImageSrc: imageElement.src,
        width: width / scale,
        height: height / scale,
      });
    };
    imageElement.onerror = function () {
      throw 'Invalid image URL: ' + url;
    };
  }, [url]);

  const crop = (pageX, pageY) => {
    if (!bBoxAnnotatorRef.current) return { x: 0, y: 0 };

    const rect = bBoxAnnotatorRef.current.getBoundingClientRect();
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    return {
      x: Math.min(
        Math.max(Math.round(pageX - rect.left - scrollX), 0),
        Math.round(imageFrameStyle.width - 1)
      ),
      y: Math.min(
        Math.max(Math.round(pageY - rect.top - scrollY), 0),
        Math.round(imageFrameStyle.height - 1)
      ),
    };
  };

  const updateRectangle = (pageX, pageY) => {
    console.log('updateRectangle', pageX, pageY);
    setPointer(crop(pageX, pageY));
  };

  useEffect(() => {
    const mouseMoveHandler = (e) => {
      console.log('pointer', pointer);
      console.log('offset', offset);
      switch (status) {
        case 'hold':
          updateRectangle(e.pageX, e.pageY);
      }
    };
    window.addEventListener('mousemove', mouseMoveHandler);
    return () => window.removeEventListener('mousemove', mouseMoveHandler);
  }, [status]);

  const addEntry = () => {
    const currentRect = rectangle(); // Get current rectangle values
    console.log('addEntry', currentRect);

    const newEntry = {
      ...currentRect,
      label: activeClass.value,
      id: uuid(),
      showCloseButton: false
    };

    setEntries([...entries, newEntry]);
    setStatus('free');
    setPointer(null);
    setOffset(null);
  };

  useEffect(() => {
    const mouseUpHandler = (e) => {
      switch (status) {
        case 'hold':
          updateRectangle(e.pageX, e.pageY);
          addEntry();
      }
    };
    window.addEventListener('mouseup', mouseUpHandler);
    return () => window.removeEventListener('mouseup', mouseUpHandler);
  }, [status, pointer, offset]);

  const mouseDownHandler = (e) => {
    if (!activeClass) return;

    switch (status) {
      case 'free':
        if (e.button !== 2) {
          const newOffset = crop(e.pageX, e.pageY);
          setOffset(newOffset);
          setPointer(newOffset);
          setStatus('hold');
        }
    }
  };

  const rectangle = () => {
    if (!offset || !pointer) return { left: 0, top: 0, width: 0, height: 0 };

    const x1 = Math.min(offset.x, pointer.x);
    const x2 = Math.max(offset.x, pointer.x);
    const y1 = Math.min(offset.y, pointer.y);
    const y2 = Math.max(offset.y, pointer.y);

    return {
      left: x1,
      top: y1,
      width: x2 - x1 + 1,
      height: y2 - y1 + 1,
    };
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setEntries([]);
    },
  }));

  const rect = rectangle();

  useEffect(() => {
    // Simulate API request to fetch bounding boxes
    const fetchBoundingBoxes = async () => {
      setIsLoading(true);
      try {
        await new Promise(resolve => setTimeout(resolve, 3000)); // 3 second delay
        // Here you would normally set the entries from the API response
        setEntries([]); // For now, just reset entries
      } catch (error) {
        console.error('Error fetching bounding boxes:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBoundingBoxes();
  }, [page]); // Only re-fetch when page changes, removed step dependency

  return (
    <div className="flex justify-center px-8">
      {/* Main Annotator */}
      <div className="inline-block pr-72">
        <div
          className={`relative ${activeClass ? 'cursor-crosshair' : 'cursor-not-allowed'}`}
          style={{
            width: `${bBoxAnnotatorStyle.width}px`,
            height: `${bBoxAnnotatorStyle.height}px`,
          }}
          ref={bBoxAnnotatorRef}
          onMouseDown={mouseDownHandler}
        >
          <div
            className="relative"
            style={{
              width: `${imageFrameStyle.width}px`,
              height: `${imageFrameStyle.height}px`,
            }}
          >
            <img
              src={url}
              alt="Annotation target"
              className="w-full h-full object-contain"
            />
            {/* Add overlay when a box is hovered */}
            {hoveredId && (
              <div
                className="absolute inset-0 bg-black/30 transition-opacity duration-200"
                style={{
                  clipPath: entries
                    .filter(entry => entry.id === hoveredId)
                    .map(entry => `polygon(
                      0% 0%, 
                      100% 0%, 
                      100% 100%, 
                      0% 100%, 
                      0% 0%,
                      ${entry.left}px ${entry.top}px, 
                      ${entry.left}px ${entry.top + entry.height}px, 
                      ${entry.left + entry.width}px ${entry.top + entry.height}px, 
                      ${entry.left + entry.width}px ${entry.top}px,
                      ${entry.left}px ${entry.top}px
                    )`)
                }}
              />
            )}
            {status === 'hold' ? <BBoxSelector rectangle={rect} /> : null}
            {entries && entries.map((entry, i) => (
              <div
                className={`absolute font-mono text-sm transition-all duration-200`}
                style={{
                  border: `${borderWidth}px solid ${noteColors[entry.label]}`,
                  color: noteColors[entry.label],
                  top: `${entry.top - borderWidth}px`,
                  left: `${entry.left - borderWidth}px`,
                  width: `${entry.width}px`,
                  height: `${entry.height}px`,
                  zIndex: hoveredId === entry.id ? 10 : 1
                }}
                key={i}
                onMouseEnter={() => {
                  setHoveredId(entry.id);
                  setEntries((prevEntries) =>
                    prevEntries.map((e) => (e.id === entry.id ? { ...e, showCloseButton: true } : e))
                  );
                }}
                onMouseLeave={() => {
                  setHoveredId(null);
                  setEntries((prevEntries) =>
                    prevEntries.map((e) => (e.id === entry.id ? { ...e, showCloseButton: false } : e))
                  );
                }}
              >
                {entry.showCloseButton && (
                  <div
                    className="absolute -top-1 -right-1 w-4 h-4 flex items-center justify-center text-white bg-violet-600 border border-white rounded-full cursor-pointer select-none hover:bg-violet-700"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={() => {
                      setEntries(entries.filter((e) => e.id !== entry.id));
                    }}
                  >
                    <span className="text-xs leading-none">&times;</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <div className="w-72 bg-white dark:bg-gray-800 p-6 border-l border-gray-200 dark:border-gray-700 overflow-y-auto fixed right-0 top-0 bottom-0">
        {/* Collapsible Key */}
        <div className="mb-6">
          <button
            className="flex items-center justify-between w-full text-left text-sm font-medium text-gray-900 dark:text-white"
            onClick={() => setIsKeyCollapsed(!isKeyCollapsed)}
          >
            <span>Annotation Key</span>
            <svg className={`w-5 h-5 transition-transform ${isKeyCollapsed ? '' : 'rotate-180'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          {!isKeyCollapsed && (
            <div className="grid grid-cols-2 gap-x-4 gap-y-1">
              {noteOptions.map(({ value, label }) => (
                <div key={value} className="flex items-start space-x-1.5">
                  <div
                    className="w-3 mt-1 h-3 rounded"
                    style={{ backgroundColor: noteColors[value] }}
                  />
                  <span className="text-xs text-gray-600 dark:text-gray-300">{label}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Active Class Selector */}
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">Active Class</h3>
          <Select
            value={activeClass}
            onChange={setActiveClass}
            options={noteOptions}
            styles={{
              control: (base) => ({
                ...base,
                borderRadius: '0.5rem',
                borderColor: 'rgb(209 213 219)',
                '&:hover': {
                  borderColor: 'rgb(139 92 246)'
                }
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected ? 'rgb(139 92 246)' : base.backgroundColor,
                '&:hover': {
                  backgroundColor: state.isSelected ? 'rgb(139 92 246)' : 'rgb(237 233 254)'
                }
              })
            }}
            placeholder="Select class to annotate..."
            isClearable
            className="basic-select"
            classNamePrefix="select"
          />
        </div>

        {/* Annotations List */}
        <div className="mt-6">
          <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">Annotations</h3>
          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-2 border-violet-600 border-t-transparent"></div>
            </div>
          ) : (
            <div className="space-y-2">
              {entries.map((entry, index) => (
                <div
                  key={entry.id}
                  className="p-3 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                  onMouseEnter={() => setHoveredId(entry.id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center space-x-2">
                      <div
                        className="w-3 h-3 rounded"
                        style={{ backgroundColor: noteColors[entry.label] }}
                      />
                      <span className="font-medium text-sm text-gray-900 dark:text-white">
                        {noteOptions.find(opt => opt.value === entry.label)?.label}
                      </span>
                    </div>
                    <button
                      onClick={() => setEntries(entries.filter(e => e.id !== entry.id))}
                      className="text-gray-400 hover:text-red-500 dark:hover:text-red-400"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex space-x-3 text-xs text-gray-500 dark:text-gray-400">
                    <span>X: {Math.round(entry.left * multiplier)}</span>
                    <span>Y: {Math.round(entry.top * multiplier)}</span>
                    <span>W: {Math.round(entry.width * multiplier)}</span>
                    <span>H: {Math.round(entry.height * multiplier)}</span>
                  </div>
                </div>
              ))}
              {entries.length === 0 && (
                <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-4">
                  No annotations yet
                </p>
              )}
            </div>
          )}
        </div>

        {/* Fixed Button Group at bottom */}
        <div className="fixed bottom-0 right-0 w-72 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
          <div className="p-4 space-y-3">
            <button
              className="w-full px-4 py-2.5 text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 active:bg-violet-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150 flex items-center justify-center gap-2"
              onClick={async () => {
                setIsSaving(true);
                try {
                  // Simulate API call
                  await new Promise(resolve => setTimeout(resolve, 3000));
                  console.log('Saved annotations:', entries);
                } catch (error) {
                  console.error('Error saving annotations:', error);
                } finally {
                  setIsSaving(false);
                }
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                  <span>Saving...</span>
                </>
              ) : (
                <>
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                  </svg>
                  <span>Save Annotations</span>
                </>
              )}
            </button>
            <div className="grid grid-cols-2 gap-2">
              {/* Only show Previous if not on first page and first step */}
              {!(step === 'block' && page === '1') && (
                <button
                  className="w-full px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150 flex items-center justify-center gap-2"
                  onClick={() => {
                    const currentStepIndex = STEPS.indexOf(step);
                    if (currentStepIndex === 0) {
                      // If we're on the first step (block), go to previous page's last step
                      window.location.href = `/dashboard/annotator?piece=${piece}&page=${parseInt(page) - 1}&step=${STEPS[STEPS.length - 1]}`;
                    } else {
                      // Otherwise, move to previous step on same page
                      window.location.href = `/dashboard/annotator?piece=${piece}&page=${page}&step=${STEPS[currentStepIndex - 1]}`;
                    }
                  }}
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                  <span>Prev {STEPS.indexOf(step) === 0 ? 'Page' : 'Step'}</span>
                </button>
              )}
              <button
                className={`w-full px-4 py-2.5 text-sm font-medium ${page === FINAL_PAGE && step === STEPS[STEPS.length - 1]
                  ? 'bg-violet-600 hover:bg-violet-700 active:bg-violet-800 text-white'
                  : 'text-gray-700 dark:text-gray-200 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150 flex items-center justify-center gap-2 ${!(step === 'block' && page === '1') ? 'col-start-2' : 'col-span-2'
                  }`}
                onClick={() => {
                  const currentStepIndex = STEPS.indexOf(step);
                  if (page === FINAL_PAGE && currentStepIndex === STEPS.length - 1) {
                    // Redirect to reviewer page
                    window.location.href = `/dashboard/reviewer?piece=${piece}`;
                  } else if (currentStepIndex === STEPS.length - 1) {
                    // If we're on the last step (misc), increment page and go back to first step
                    window.location.href = `/dashboard/annotator?piece=${piece}&page=${parseInt(page) + 1}&step=block`;
                  } else {
                    // Otherwise, move to next step on same page
                    window.location.href = `/dashboard/annotator?piece=${piece}&page=${page}&step=${STEPS[currentStepIndex + 1]}`;
                  }
                }}
              >
                {page === FINAL_PAGE && step === STEPS[STEPS.length - 1] ? (
                  <>
                    <span>Submit</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </>
                ) : (
                  <>
                    <span>Next {STEPS.indexOf(step) === STEPS.length - 1 ? 'Page' : 'Step'}</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Adjust bottom padding to account for fixed buttons */}
        <div className="h-28" />
      </div>
    </div>
  );
});

BBoxAnnotator.displayName = 'BBoxAnnotator';

export default BBoxAnnotator;