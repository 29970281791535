export const base_api_url: string = "https://61adx9uf2c.execute-api.us-east-1.amazonaws.com/subito";

export const base_web_url: string = "http://localhost:3000";

export const primary_color: string = "#7c3aed";

export const aws_config = {
  identityPoolId: 'us-east-1:a3172b0d-5505-4ae4-92b1-8be824b0e9ff',
  region: 'us-east-1',
  userPoolId: 'us-east-1_RE9BowPy0',
  userPoolWebClientId: 'ej70pigics2jemri0dff1tu5i',
  oauth: {
    domain: "subitomusic.auth.us-east-1.amazoncognito.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: "https://www.learnsubito.com/dashboard/music",
    redirectSignOut: "https://www.learnsubito.com",
    responseType: "code"
  },
};

export const sidebar_config = [
  { label: 'Home', route: 'home', icon: 'home' },
  { label: 'Music', route: 'music', icon: 'music' },
  // { label: 'Sightreading', route: 'sightreading', icon: 'sightreading' },
  // { label: 'Practice', route: 'practice' },
  // { label: 'Profile', route: 'profile', icon: 'profile' },
  { label: 'Exercises', route: 'exercises', icon: 'exercises' },
  { label: 'Upload', route: 'upload', icon: 'upload' },
  // { label: 'AI Demo', route: 'aidemo', icon: 'music' }
  // { label: 'Teacher Dashboard', route: 'teacherdashboard', icon: 'teacherdashboard' },
]
