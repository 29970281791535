import React, { useState, useRef, useEffect, useContext } from 'react';
import { CloseButtonIcon } from '../../assets/icons';
import { AccountContext } from '../../stores/Account';

const MultiPageViewFlat = ({ onClose, title, composer, sharingKey, scoreId }) => {
    const { userInfo } = useContext(AccountContext);

    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const [showInstructions, setShowInstructions] = useState(true);
    const [isInstructionsFading, setIsInstructionsFading] = useState(false);
    const [isControlsBarOpen, setIsControlsBarOpen] = useState(false);
    const [isHeaderLocked, setIsHeaderLocked] = useState(false);
    const timeoutRef = useRef(null);
    const titleBarRef = useRef(null);

    const containerRef = useRef(null);

    const showHeader = () => {
        if (isHeaderLocked) return;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        setIsHeaderVisible(true);
    };

    const hideHeader = () => {
        if (isHeaderLocked) return;
        const titleBar = titleBarRef.current;
        if (titleBar && titleBar.matches(':hover')) {
            return;
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setIsHeaderVisible(false);
        }, 300);
    };

    const handleHeaderClick = () => {
        if (!isHeaderLocked) {
            setIsHeaderLocked(true);
        }
    };

    useEffect(() => {
        const fadeTimer = setTimeout(() => {
            setIsInstructionsFading(true);
        }, 5000);

        const hideTimer = setTimeout(() => {
            setShowInstructions(false);
        }, 5500);

        return () => {
            clearTimeout(fadeTimer);
            clearTimeout(hideTimer);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <div className="fixed inset-0 z-50 flex justify-center items-center">
            {showInstructions && (
                <div className={`fixed inset-0 z-50 flex items-center justify-center transition-opacity duration-500 ${isInstructionsFading ? 'opacity-0' : 'opacity-100'} pointer-events-none`}>
                    <div className="relative bg-gray-900/95 backdrop-blur-sm text-white px-12 py-8 rounded-2xl shadow-2xl max-w-sm mx-4 transform transition-all border border-white/10 pointer-events-auto">
                        <button
                            onClick={() => setShowInstructions(false)}
                            className="absolute top-2 right-2 text-gray-300 hover:text-white"
                        >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="flex items-center space-x-3 mb-4">
                            <div className="p-2 bg-violet-600/20 rounded-lg">
                                <svg className="w-5 h-5 text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium">Quick Tip</h3>
                        </div>
                        <p className="text-gray-300 text-sm leading-relaxed">
                            Scroll down on the header to hide the header and get a better view of your music. The header will reappear when you scroll back to the top.
                        </p>
                    </div>
                </div>
            )}
            <div
                ref={containerRef}
                className="bg-white overflow-y-hidden h-screen w-screen relative"
            >
                {/* Overlay div with conditional pointer-events */}
                <div
                    className={`absolute top-0 left-0 w-full h-[100px] z-40 ${isHeaderVisible ? 'pointer-events-none' : 'pointer-events-auto'}`}
                    onMouseEnter={showHeader}
                    onMouseLeave={hideHeader}
                ></div>

                {/* Title bar */}
                <div
                    id="title-bar"
                    ref={titleBarRef}
                    className={`absolute top-0 left-0 right-0 z-70 flex bg-gray-900/90 backdrop-blur-sm text-white justify-between items-center px-6 py-3 transition-all duration-300 ${isHeaderVisible ? 'translate-y-0 shadow-lg' : '-translate-y-full'}`}
                    onMouseEnter={showHeader}
                    onMouseLeave={hideHeader}
                    onClick={handleHeaderClick}
                >
                    <div className="flex items-center space-x-4">
                        <button onClick={onClose} className="p-2 hover:bg-white/10 rounded-full transition-colors duration-200">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div>
                            <h2 className="text-xl font-bold tracking-tight">{title}</h2>
                            <p className="text-sm text-gray-300">{composer}</p>
                        </div>
                    </div>
                    <div className="flex items-center ml-auto">
                        <button
                            onClick={() => setIsControlsBarOpen(!isControlsBarOpen)}
                            className="p-2 hover:bg-white/10 rounded-full transition-colors duration-200"
                            title={isControlsBarOpen ? "Hide controls" : "Show controls"}
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        </button>
                    </div>
                    {isHeaderLocked && <div className="flex items-center ml-4">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsHeaderLocked(false);
                                setIsHeaderVisible(false);
                            }}
                            className="p-2 hover:bg-white/10 rounded-full transition-colors duration-200"
                            title="Unpin header"
                        >
                            <svg
                                className="w-5 h-5 text-violet-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M5 15l7-7 7 7"
                                />
                            </svg>
                        </button>
                    </div>}
                </div>
                <iframe
                    src={`https://flat.io/embed/${scoreId}?_l=true&sharingKey=${sharingKey}&layout=page&parts=366f8f5e-976b-3ba8-a693-260f3e44e01f&displayFirstLinePartsNames=false&controlsParts=false&branding=false&noHeader=true&appId=667c53d0e181f889c06e5f62&userId=${userInfo.id}&controlsDisplay=${isControlsBarOpen ? 'true' : 'false'}&themeControlsBackground=rgb(17,24,39,0.9)&themePrimary=rgb(124,58,237)&themeCursorV0=rgb(124,58,237)&themeCursorV1=rgb(124,58,237)&themeCursorV2=rgb(124,58,237)&themeSelection=rgb(124,58,237)&themeSlider=rgb(124,58,237)`}
                    height="100%"
                    width="100%"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay; midi"
                ></iframe>
            </div>
        </div>
    );
};

export default MultiPageViewFlat;