import { useState, useContext } from 'react';
import { Document, Page } from 'react-pdf';
import starPiece from "../../api/starPiece";
import { AccountContext } from "../../stores/Account";
import LoadingView from "./LoadingView";
import useClickOutside from "../../hooks/useClickOutside";
import viewPiece from '../../api/viewPiece';
import AddToList from './AddToList';
import FailedToLoadPDFView from './FailedToLoadPDFView';
import FlatPNGEmbed from './FlatPNGEmbed';
import { format_period } from '../../utils/music';

const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`${filled ? "text-yellow-300" : "text-gray-400"} hover:scale-110 transition-transform duration-200`}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function PieceCard({ piece, isMultiSelect, isSelected, onSelect }) {
  const [starred, setStarred] = useState(piece.starred === 1);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user } = useContext(AccountContext);

  const handleError = (error) => {
    console.error(error);
    setHasError(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickOutside = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutside, isDisabled);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    starPiece(user, piece.id).then(() => {
      setStarred((starred) => !starred)
    }).catch((err) => {
      console.log(err);
    });
  }

  const view = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    viewPiece(user, piece.id)
  }

  return (
    <div
      onClick={() => !isMultiSelect && (window.location.href = "/dashboard/music/piece/" + piece.id)}
      className="group relative bg-white dark:bg-gray-700 rounded-xl shadow-md hover:shadow-lg dark:hover:shadow-2xl transform hover:-translate-y-1 transition-all duration-300"
      style={{ width: '220px', height: '340px' }}
    >
      {/* Preview Section */}
      <div className="relative w-full h-[180px] bg-gray-50 dark:bg-gray-900">
        <div className="overflow-hidden rounded-t-xl">
          <div className="absolute inset-0">
            {piece.type === 'pdf' ? (
              hasError ? (
                <FailedToLoadPDFView width={'220px'} height={'340px'} />
              ) : (
                <Document
                  file={piece.pdf_link}
                  className="w-full no-scrollbar overflow-hidden"
                  onLoadError={handleError}
                  loading={<LoadingView />}
                >
                  <Page pageNumber={1} scale={0.3} />
                </Document>
              )
            ) : piece.type === 'musicxml' ?
              piece.thumbnail ? (
                <img
                  src={piece.thumbnail}
                  alt={piece.title}
                  className="absolute inset-0 w-full h-full object-contain bg-white"
                />
              ) : (
                <FlatPNGEmbed
                  sharingKey={piece.flat_sharing_key}
                  scoreId={piece.flat_score_id}
                  height={180}
                />
              ) : (
                <LoadingView />
              )}
          </div>
        </div>

        {/* Overlay Elements */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

        {/* Star button */}
        <div className="absolute top-2 right-2">
          <StarIcon filled={starred} onClick={star} />
        </div>

        {/* AddToList or Select button */}
        <div className="absolute top-2 left-2">
          {isMultiSelect ? (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect && onSelect(piece.id);
              }}
              className={`w-4 h-4 rounded-full transition-colors duration-200 flex items-center justify-center ${isSelected
                ? 'bg-violet-600 text-white'
                : 'border border-gray-400 dark:border-gray-500 bg-white dark:bg-gray-800'
                }`}
            >
              {isSelected && (
                <svg className="w-3 h-3" fill="none" viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.5}
                    stroke="currentColor"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              )}
            </button>
          ) : (
            <AddToList
              setDisabled={setIsDisabled}
              piece_id={piece.id}
              button_view={
                <button className="p-1.5 rounded-full bg-white/80 dark:bg-gray-800/80 hover:bg-white dark:hover:bg-gray-800 transition-colors duration-200 group">
                  <div className="relative">
                    <svg className="w-4 h-4 text-gray-600 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                    </svg>
                    <svg className="w-3 h-3 absolute -top-1 -right-1 text-violet-600 dark:text-violet-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                    </svg>
                  </div>
                </button>
              }
            />
          )}
        </div>

        {/* Piano Solo label */}
        {/* <div className="absolute bottom-2 right-2">
          <span className="px-2 py-1 text-xs font-medium text-violet-600 dark:text-violet-400 bg-white/80 dark:bg-gray-800/80 rounded-md">
            Piano Solo
          </span>
        </div> */}
      </div>

      {/* Content Section */}
      <div className="p-3 flex flex-col h-[160px]">
        {/* Title and Menu */}
        <div className="flex items-start justify-between gap-2 mb-2">
          <div className="flex-1">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white line-clamp-2 leading-snug">
              {piece.title}
            </h3>
            <p className="text-xs text-gray-600 dark:text-gray-400">
              {piece.composer_fname} {piece.composer_lname}
            </p>
          </div>
        </div>
        {/* Stats with bullet separators */}
        <div className="flex flex-wrap items-center gap-x-2 text-xs text-gray-500 dark:text-gray-400">
          <span>{format_period(piece.time_period) || 'Unknown'}</span>
          <span className="w-1 h-1 bg-gray-300 dark:bg-gray-600 rounded-full"></span>
          <span>{piece.users_viewed || 0} players</span>
          <span className="w-1 h-1 bg-gray-300 dark:bg-gray-600 rounded-full"></span>
          <span>{piece.total_views || 0} views</span>
          <span className="w-1 h-1 bg-gray-300 dark:bg-gray-600 rounded-full"></span>
          <span>{piece.total_stars || 0} favorites</span>
        </div>

        {/* Bottom info */}
        <div className="mt-auto flex justify-between text-[10px] text-gray-400 dark:text-gray-500">
          <span>1/31/25</span>
          <span className="text-violet-600 dark:text-violet-400">Piano Solo</span>
        </div>
      </div>
    </div>
  );
}

export default PieceCard;