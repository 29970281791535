import React from 'react';

const BBoxSelector = ({ rectangle, borderWidth = 2 }) => {
  return (
    <div
      className="absolute border-2 border-dotted border-violet-600"
      style={{
        left: `${rectangle.left - borderWidth}px`,
        top: `${rectangle.top - borderWidth}px`,
        width: `${rectangle.width}px`,
        height: `${rectangle.height}px`,
        borderWidth: `${borderWidth}px`,
      }}
    ></div>
  );
};

export default BBoxSelector;