function SidebarShuffleView(props) {
  return (
    <a 
      href="/dashboard/music?page=1&shuffle=true" 
      className="group flex flex-col items-center justify-center gap-3 rounded-lg bg-gradient-to-br from-violet-500 to-violet-600 hover:from-violet-600 hover:to-violet-700 p-4 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg dark:shadow-violet-500/20"
    >
      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-white/10 backdrop-blur">
        <svg
          className="w-6 h-6 text-white"
          fill="none"
          strokeWidth="2"
          stroke="currentColor" 
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
          />
        </svg>
      </div>
      <div className="text-center">
        <div className="font-semibold text-white mb-1">
          Shuffle Library
        </div>
        <p className="text-xs text-white/80">
          Discover random pieces from your collection
        </p>
      </div>
    </a>
  )
}

export default SidebarShuffleView