import React, { useState, useEffect, useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { AccountContext } from '../../stores/Account';
import practiceTimeChart from '../../api/practiceTimeChart';
import practiceTable from '../../api/practiceTable';
import Pagination from '../components/Pagination';

const timeRanges = [
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 30 days', value: 30 },
    { label: 'Last 3 months', value: 90 },
    { label: 'Last year', value: 365 },
];

const PracticeLog = ({ logs = [] }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const currentLogs = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * itemsPerPage;
        const lastPageIndex = firstPageIndex + itemsPerPage;
        return logs && logs.length > 0 ? logs.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, logs]);

    return (
        <div className="h-full flex flex-col">
            <div className={`flex-1 min-h-0 overflow-auto rounded-lg no-scrollbar`}>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 shadow-sm">
                        <tr>
                            <th scope="col" className="px-6 py-4 font-semibold">Piece/Drill</th>
                            <th scope="col" className="px-6 py-4 font-semibold">Time</th>
                            <th scope="col" className="px-6 py-4 font-semibold">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentLogs.map((log, index) => (
                            <tr key={index} className="bg-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-gray-700/50 border-b dark:border-gray-700 transition-colors">
                                <td className="px-6 py-4 text-nowrap font-medium text-gray-900 dark:text-gray-100">{log.piece_title}</td>
                                <td className="px-6 py-4 text-nowrap">{log.practice_minutes} mins</td>
                                <td className="px-6 py-4 text-nowrap">{new Date(log.practice_date).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric'
                                })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="p-4 bg-gray-50 dark:bg-gray-800/50 rounded-b-lg">
                <Pagination
                    activePage={currentPage}
                    totalResults={logs.length}
                    resultsPerPage={itemsPerPage}
                    onChange={page => setCurrentPage(page)}
                    label="Practice Log Navigation"
                />
            </div>
        </div>
    );
};

const WeeklyPractice = ({ student_id, timeRange: initialTimeRange, showLog, onShowLogChange }) => {
    const [timeRange, setTimeRange] = useState(initialTimeRange);
    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [practiceLogData, setPracticeLogData] = useState([]);
    const { user } = useContext(AccountContext);

    // Compute total practiced minutes from the data array
    const totalPracticeMinutes = data.reduce((sum, minutes) => sum + minutes, 0);

    useEffect(() => {
        practiceTimeChart(user, timeRange, student_id).then((dat) => {
            setCategories(Object.keys(dat));
            setData(Object.values(dat));
        });

        practiceTable(user, student_id).then((dat) => {
            setPracticeLogData(dat);
        });
    }, [timeRange, student_id]);

    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            parentHeightOffset: 0,
            height: '100%',
            background: 'transparent',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            legend: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: false,
                columnWidth: '100%',
                endingShape: 'flat',
                distributed: false,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        grid: {
            show: true,
            borderColor: '#e2e8f0',
            strokeDashArray: 2,
            position: 'back',
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 20
            },
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true,
                    style: {
                        colors: ['#e2e8f0']
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return val + 'm';
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                fontWeight: '500',
                colors: ['#6b7280']
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 0.6,
                opacityFrom: 0.9,
                opacityTo: 0.4,
                stops: [0, 100],
                colorStops: [
                    {
                        offset: 0,
                        color: '#8b5cf6',
                        opacity: 1
                    },
                    {
                        offset: 100,
                        color: '#8b5cf0',
                        opacity: 1
                    }
                ]
            }
        },
        xaxis: {
            type: 'category',
            categories: categories,
            labels: {
                show: true
            },
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            },
            crosshairs: {
                show: true,
                position: 'back',
                stroke: {
                    color: '#8b5cf6',
                    width: 1,
                    dashArray: 5
                }
            }
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    fontSize: '12px',
                    fontFamily: 'inherit',
                    fontWeight: 500,
                    colors: ['#6b7280']
                },
                formatter: (value) => `${value}m`
            },
            axisBorder: {
                show: true
            },
            axisTicks: {
                show: true
            }
        },
        tooltip: {
            enabled: true,
            theme: 'light',
            style: {
                fontSize: '12px',
                fontFamily: 'inherit'
            },
            y: {
                formatter: function (value) {
                    return value + ' minutes';
                }
            },
            marker: {
                show: false
            }
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.9
                }
            }
        },
        theme: {
            mode: 'light',
            palette: 'palette1',
            monochrome: {
                enabled: false
            }
        },
        colors: ['#8b5cf6']
    };

    const series = [{
        name: 'Practice Time',
        data: data
    }];

    return (
        <div className={`flex flex-col ${showLog ? "h-full" : "h-2/3"} dark:bg-gray-900 rounded-xl shadow-sm`}>
            <div className={`flex items-start ${showLog ? "justify-end" : "justify-between"}`}>
                {!showLog && (
                    <div className="flex flex-col">
                        <h2 className="text-4xl font-bold text-gray-900 dark:text-white tracking-tight">
                            {totalPracticeMinutes} <span className="text-2xl text-gray-500 dark:text-gray-400 font-medium">mins</span>
                        </h2>
                        <span className="text-sm text-gray-500 dark:text-gray-400 mt-1">Total Practice Time</span>
                    </div>
                )}
                <div className="flex items-center space-x-3 pt-2">
                    <div className="flex space-x-1 bg-gray-50 dark:bg-gray-700/50 p-0.5 rounded-lg">
                        <button
                            onClick={() => onShowLogChange(true)}
                            className={`px-3 py-1 text-xs font-medium rounded-md transition-all duration-200 ${showLog
                                ? 'bg-white dark:bg-gray-700 text-violet-600 dark:text-violet-400 shadow-sm'
                                : 'text-gray-600 dark:text-gray-300 hover:text-violet-600 dark:hover:text-violet-400'
                                }`}
                        >
                            View Log
                        </button>
                        <button
                            onClick={() => onShowLogChange(false)}
                            className={`px-3 py-1 text-xs font-medium rounded-md transition-all duration-200 ${!showLog
                                ? 'bg-white dark:bg-gray-700 text-violet-600 dark:text-violet-400 shadow-sm'
                                : 'text-gray-600 dark:text-gray-300 hover:text-violet-600 dark:hover:text-violet-400'
                                }`}
                        >
                            View Overview
                        </button>
                    </div>
                    <div className="relative">
                        <button
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="inline-flex items-center px-3 py-1 text-xs font-medium rounded-lg bg-gray-50 dark:bg-gray-700/50 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
                            type="button"
                        >
                            {timeRanges.find(r => r.value === timeRange)?.label}
                            <svg className={`w-3 h-3 ml-1.5 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 9-7 7-7-7" />
                            </svg>
                        </button>
                        {isDropdownOpen && (
                            <div className="absolute right-0 mt-1 w-36 bg-white dark:bg-gray-800 rounded-lg shadow-lg z-10 border border-gray-200 dark:border-gray-700">
                                {timeRanges.map((range) => (
                                    <button
                                        key={range.value}
                                        onClick={() => {
                                            setIsDropdownOpen(false);
                                            setTimeRange(range.value);
                                        }}
                                        className={`block w-full text-left px-3 py-1.5 text-xs hover:bg-gray-100 dark:hover:bg-gray-700 first:rounded-t-lg last:rounded-b-lg
                                            ${timeRange === range.value ? 'text-violet-600 dark:text-violet-400 bg-violet-50 dark:bg-violet-900/20' : 'text-gray-700 dark:text-gray-300'}`}
                                    >
                                        {range.label}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex-1 min-h-0 h-full pt-1">
                {showLog ? (
                    <PracticeLog logs={practiceLogData} removeBorder={true} />
                ) : (
                    <ReactApexChart
                        key={`apex-chart-${timeRange}`}
                        options={options}
                        series={series}
                        type="bar"
                        height="100%"
                        width="100%"
                    />
                )}
            </div>
        </div>
    );
};

export default WeeklyPractice;
