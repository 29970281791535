import { base_api_url } from '../config/config.ts';
import { Auth } from 'aws-amplify';

class API {
  constructor(user) {
    this.auth = user.user.idToken.jwtToken;
    this.host = base_api_url;
    this.student_id = user.student;
    this.user = user;
  }

  async refreshToken() {
    try {
      const currentSession = await Auth.currentSession();
      this.auth = currentSession.getIdToken().getJwtToken();
      this.user.user.idToken.jwtToken = this.auth;
      return this.auth;
    } catch (error) {
      console.error('Error refreshing token:', error);
      await Auth.signOut();
      window.location.href = '/login';
      throw error;
    }
  }

  async getRequest(extension) {
    if (!extension.startsWith('/')) { extension = '/' + extension };
    let url = this.host + extension;
    if (!url.includes('ts_stud_id')) {
      if (url.includes('?')) {
        url = url + "&"
      } else {
        url = url + "?"
      }
      if (this.student_id !== null) {
        url = url + "ts_stud_id=" + this.student_id + "&ts_flag=1"
      } else {
        url = url + "ts_stud_id=-&ts_flag=0"
      }
    }

    const response = await fetch(url, {
      method: 'get',
      headers: new Headers({
        'Authorization': "Bearer " + this.auth,
        'Content-Type': 'application/json',
      }),
    });

    const data = await response.json();

    if (data.message === "The incoming token is expired") {
      await this.refreshToken();
      return this.getRequest(extension);
    }

    return data;
  }

  async postRequest(extension, body) {
    if (!extension.startsWith('/')) { extension = '/' + extension };
    if ('ts_stud_id' in body) {
      body['ts_flag'] = 1;
    } else if (this.student_id !== null && !('ts_stud_id' in body)) {
      body['ts_stud_id'] = this.student_id;
      body['ts_flag'] = 1;
    } else if (!('ts_stud_id' in body)) {
      body['ts_stud_id'] = "-";
      body['ts_flag'] = 0;
    }

    const response = await fetch(this.host + extension, {
      method: 'post',
      headers: new Headers({
        'Authorization': this.auth,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body)
    });

    const data = await response.json();

    if (data?.message === "The incoming token is expired") {
      await this.refreshToken();
      return this.postRequest(extension, body);
    }

    return data;
  }
}

export default API;