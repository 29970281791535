import { useEffect, useRef, useState, useContext } from 'react';
import Embed from 'flat-embed';
import { AccountContext } from '../../stores/Account';

function FlatEmbed(props) {
  const containerRef = useRef(null);
  const embedRef = useRef(null);

  const { userInfo } = useContext(AccountContext);

  useEffect(() => {
    // Clean up the container
    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }

    if (containerRef.current) {
      embedRef.current = new Embed(containerRef.current, {
        width: props.width,
        height: parseInt(props.height),
        off: true,
        lazy: true,
        score: props.scoreId,
        embedParams: {
          layout: 'page',
          zoom: 'auto',
          mode: props.mode,
          themeScoreBackground: 'transparent',
          branding: false,
          noHeader: true,
          displayFirstLinePartsNames: false,
          controlsDisplay: false,
          appId: '667c53d0e181f889c06e5f62',
          sharingKey: props.sharingKey,
          userId: userInfo.id,
          themeControlsBackground: 'rgb(17,24,39,0.9)',
          themePrimary: 'rgb(124,58,237)',
          themeCursorV0: 'rgb(124,58,237)',
          themeCursorV1: 'rgb(124,58,237)',
          themeCursorV2: 'rgb(124,58,237)',
          themeSelection: 'rgb(124,58,237)',
          themeSlider: 'rgb(124,58,237)'
        }
      });
    }

    return () => {
      // Clean up on unmount
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
      embedRef.current = null;
    };
  }, [props.scoreId, props.sharingKey, props.mode]);

  return <>
    <div
      ref={containerRef}
      className="cursor-pointer select-none"
      style={{
        width: '100%',
        height: props.height || '240px',
      }}
    ></div>
  </>;
}

export default FlatEmbed;