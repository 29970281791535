import API from ".";

const practiceSummary = (user) => {
  let url = '/practice/summary?period_length=5';

  return new API(user).getRequest(url).then(json => {
    return json
  });
}

export default practiceSummary;