import API from ".";

const drillGenerate = (user, drill_type, prompt, last_json) => {

  let obj = {
    "drill_type": drill_type ?? '',
    "prompt": prompt ?? '',
    "last_json": last_json ?? '',
  }

  console.log(obj);

  return new API(user).postRequest('/drill/generate', obj);
}

export default drillGenerate;