import { useState, useContext, useEffect } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { composer_to_pic, format_period } from "../../utils/music";
import { daysSinceDate } from "../../utils/datetime";
import { AccountContext } from "../../stores/Account";
import PDFViewer from "../components/PDFViewer";
import FlagPiece from "../components/FlagPiece";
import { pdfjs } from 'react-pdf';
import MultiPageViewPDF from "../components/MultiPageViewPDF";
import getPiece from "../../api/getPiece";
import starPiece from "../../api/starPiece";
import { useParams } from "react-router-dom";
import PieceType from "../../types/piece";
import Heading from "../components/Heading";
import MultiPageViewFlat from "../components/MultiPageViewFlat";
import { getInitial } from "../../utils/string";
import viewPiece from "../../api/viewPiece";
import AddToList from "../components/AddToList";
import CreateDrill from "./CreateDrill";
import FlatEmbed from "../components/FlatEmbed";
import PeriodEmblem from "../components/PeriodEmblem";
import PieceCard from "../components/PieceCard";
import PracticeWidget from '../components/PracticeWidget';
import Pagination from '../components/Pagination';
import { motion } from "framer-motion";
import logPractice from "../../api/logPractice";
import piecePracticeLog from "../../api/piecePracticeLog";
import listDrills from "../../api/listDrills";
import DrillsCard from "../components/DrillsCard";
import createTag from '../../api/createTag';
import { isEmptyString } from "../../utils/string";
import updatePracticeNotes from "../../api/practiceNotes";
import updatePieceNotes from "../../api/pieceNotes";
import { SystemContext } from '../../stores/System';
import flagPiece from '../../api/flagPiece';
import Modal from '../../components/Modal';
const StarIcon = ({ filled, onClick }) => (
  <svg
    style={{ pointerEvents: 'auto' }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill={filled ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={filled ? "text-yellow-300" : "text-gray-400"}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
  </svg>
);

function Piece(props) {
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [fullScreenPDF, setFullScreenPDF] = useState(false);
  const [fullScreenFlat, setFullScreenFlat] = useState(false);
  const [piece, setPiece] = useState(props.piece ?? new PieceType({}));
  const [starred, setStarred] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('view');
  const [currentPage, setCurrentPage] = useState(1);
  const [timeRange, setTimeRange] = useState(7);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const recordsPerPage = 5;
  const [startTime] = useState(Date.now());

  const [practiceLog, setPracticeLog] = useState(null);
  const [exercises, setExercises] = useState(null);

  const [showTagInput, setShowTagInput] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isAddingTag, setIsAddingTag] = useState(false);

  const [showTeacherNoteInput, setShowTeacherNoteInput] = useState(false);
  const [newTeacherNote, setNewTeacherNote] = useState('');
  const [isAddingTeacherNote, setIsAddingTeacherNote] = useState(false);

  const [isEditingPracticeNotes, setIsEditingPracticeNotes] = useState(false);
  const [practiceNotesInput, setPracticeNotesInput] = useState('');
  const [isUpdatingPracticeNotes, setIsUpdatingPracticeNotes] = useState(false);

  const { setSeenKeyboardShortcuts } = useContext(SystemContext);
  const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);

  const [message, setMessage] = useState(null)
  const [status, setStatus] = useState('neutral')

  const timeRanges = [
    { value: 7, label: 'Last 7 days' },
    { value: 30, label: 'Last 30 days' },
    { value: 90, label: 'Last 3 months' },
    { value: 365, label: 'Last year' },
    { value: 1000000, label: 'All time' }
  ];

  const onTimeRangeChange = (value) => {
    setTimeRange(value);
    setCurrentPage(1);
    // Add any additional logic for when time range changes
  };

  // Add click outside handler for dropdown
  const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

  const handleTimeRangeChange = (e) => {
    e.preventDefault();
    setTimeRange(e.target.value);
    setCurrentPage(1);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const { user, userInfo } = useContext(AccountContext);

  useEffect(() => {
    if (piece.id) {
      piecePracticeLog(user, piece.id, timeRange).then((res) => {
        console.log('piece practice log', res);
        setPracticeLog(res);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [piece.id, timeRange])

  useEffect(() => {
    if (piece.id) {
      listDrills(user, piece.id).then((res) => {
        setExercises(res.drills);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [piece.id])

  useEffect(() => {
    if (props.piece) {
      setPiece(props.piece);
    }
  }, [props.piece])

  useEffect(() => {
    if (!props.piece) {
      getPiece(user, props.id ?? id).then((res) => {
        setPiece(res);
        if (res.starred === 1) {
          setStarred(true);
        }
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [id, props.id, props.piece])

  useEffect(() => {
    viewPiece(user, props.id ?? id);
  }, [])

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setIsOpen(prevState => !prevState);
  }

  const handleClickOutsideDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const ref = useClickOutside(handleClickOutsideDropdown, isDisabled);

  const star = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    starPiece(user, piece.id).then(() => {
      setStarred((starred) => !starred)
    }).catch((err) => {
      console.log(err);
    })
  }

  console.log(piece);

  // Mock practice log data - replace with API data
  const practiceLogData = [...Array(35)].map((_, i) => ({
    id: i + 1,
    minutes: Math.floor(Math.random() * 30) + 15,
    // Format date as MM/DD/YY HH:MM:SS
    date: new Date(Date.now() - (i * 24 * 60 * 60 * 1000)).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).replace(',', '')
  }));

  // Calculate pagination
  const indexOfFirstRecord = (currentPage - 1) * recordsPerPage;
  const indexOfLastRecord = indexOfFirstRecord + recordsPerPage;
  const currentRecords = practiceLogData.slice(indexOfFirstRecord, indexOfLastRecord);

  // Function to log practice time
  const logPracticeTime = () => {
    if (piece.id) {
      console.log("Logging practice time", startTime, Date.now(), piece.id);
      logPractice(user, startTime, Date.now(), piece.id)
        .catch(err => console.error('Failed to log practice:', err));
    }
  };

  // Handle page refresh/close
  useEffect(() => {
    const handleBeforeUnload = () => {
      logPracticeTime();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [piece?.id]);

  // Handle navigation away (component unmount)
  useEffect(() => {
    return () => {
      console.log("Unmounting piece");
      logPracticeTime();
    };
  }, [piece?.id]);

  console.log(piece);

  const tagColors = {
    default: 'bg-violet-50 text-violet-700 dark:bg-violet-900/30 dark:text-violet-300'
  };

  const getTagColor = (tag) => {
    return tagColors[tag.toLowerCase()] || tagColors.default;
  };

  const handleAddTag = async (e) => {
    e.preventDefault();
    if (!piece.id) return;

    if (!newTag.trim()) return;

    setIsAddingTag(true);
    try {
      const res = await createTag(user, piece.id, newTag.trim().replaceAll(" ", "_"));
      // Refresh piece data to get updated tags
      window.location.reload();
    } catch (error) {
      console.error('Failed to add tag:', error);
    } finally {
      setIsAddingTag(false);
    }
  };

  const handleAddTeacherNote = async (e) => {
    if (e) e.preventDefault();
    if (!piece.id) return;
    if (!newTeacherNote.trim()) return;

    setIsAddingTeacherNote(true);
    try {
      const res = await updatePieceNotes(user, piece.id, newTeacherNote.trim());
      window.location.reload();
    } catch (error) {
      console.error('Failed to add teacher note:', error);
    } finally {
      setIsAddingTeacherNote(false);
    }
  };

  const handleUpdatePracticeNotes = async () => {
    if (!piece.id) return;

    setIsUpdatingPracticeNotes(true);
    try {
      const res = await updatePracticeNotes(user, piece.id, practiceNotesInput);
      window.location.reload();
    } catch (error) {
      console.error('Failed to update practice notes:', error);
    } finally {
      setIsUpdatingPracticeNotes(false);
    }
  };

  const submit = () => {
    flagPiece(user, props.id, message).then(() => {
      setStatus('success')
      setMessage(null);
      window.location.reload()
    }).catch((err) => {
      setStatus('failed')
      console.log(err);
      window.location.reload()
    })
  }

  // Initialize practice notes when piece loads
  useEffect(() => {
    if (piece?.practice_notes?.notes) {
      setPracticeNotesInput(piece.practice_notes.notes);
    }
  }, [piece]);

  return (
    <div className="flex flex-row gap-2 p-2 h-screen">
      {/* Main Content */}
      <div className="flex flex-col flex-1 min-w-0 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
        {/* Header */}
        <div>
          <div className="flex flex-row justify-between items-center pt-4 pb-2">
            {!piece.title ? (
              <div role="status" className="max-w-sm animate-pulse">
                <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-600 w-96 m-4"></div>
              </div>
            ) : (
              <Heading title={piece.title} />
            )}
          </div>
          {/* Composer Info */}
          <div className="flex flex-row items-center justify-between w-full px-4 mb-4">
            <div className='flex items-center'>
              <div className="flex-shrink-0">
                {composer_to_pic(piece.composer_lname) !== "none" ?
                  <img
                    src={composer_to_pic(piece.composer_lname)}
                    className="h-8 w-8 bg-gray-300 rounded-lg object-cover"
                  /> : <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                    <span className="font-medium text-gray-600 dark:text-gray-300">{getInitial(piece.composer_fname) + getInitial(piece.composer_lname)}</span>
                  </div>
                }
              </div>
              <div className="ml-2">
                <div className="text-xs font-semibold text-black truncate dark:text-white">{piece.composer_fname} {piece.composer_lname}</div>
                <div className="text-xs text-gray-500 truncate dark:text-gray-400">{format_period(piece.time_period)}</div>
              </div>
            </div>
          </div>
        </div>
        {/* Content based on active tab */}
        <div className="flex-1 px-4 overflow-y-auto no-scrollbar">
          {activeTab === 'view' && (
            <div className="relative h-[calc(100vh-150px)]">
              {piece.type === 'pdf' ? (
                <>
                  {fullScreenPDF && <MultiPageViewPDF file={piece.pdf_link} onClose={() => setFullScreenPDF(false)} />}
                  <PDFViewer height="auto" src={piece.pdf_link}>
                    <div className="top-4 right-4 absolute z-10">
                      <svg onClick={() => { setFullScreenPDF(true) }} xmlns="http://www.w3.org/2000/svg" className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300" viewBox="0 0 24 24">
                        <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                      </svg>
                    </div>
                  </PDFViewer>
                </>
              ) : piece.type === 'musicxml' ? (
                <>
                  {fullScreenFlat && (
                    <MultiPageViewFlat
                      title={piece.title}
                      composer={`${piece.composer_fname} ${piece.composer_lname}`}
                      scoreId={piece.flat_score_id}
                      sharingKey={piece.flat_sharing_key}
                      onClose={() => setFullScreenFlat(false)}
                    />
                  )}
                  <div className="relative">
                    <div className="top-4 right-4 absolute z-10">
                      <svg
                        onClick={() => setFullScreenFlat(true)}
                        xmlns="http://www.w3.org/2000/svg"
                        className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300"
                        viewBox="0 0 24 24"
                      >
                        <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                      </svg>
                    </div>
                    <FlatEmbed
                      scoreId={piece.flat_score_id}
                      sharingKey={piece.flat_sharing_key}
                      height="calc(100vh - 170px)"
                      width="100%"
                      zoom="0.5"
                    />
                  </div>
                </>
              ) : null}
            </div>
          )}

          {activeTab === 'practice' && (
            <div className="overflow-y-auto">
              <div className="p-6 space-y-8">
                {/* Overview Statistics */}
                <div className="grid grid-cols-2 md:grid-cols-5 gap-6">
                  <div className="bg-gray-50 dark:bg-gray-900/30 border border-violet-400/30 dark:border-violet-900 rounded-2xl p-6 shadow-sm">
                    <div className="flex flex-col space-y-4">
                      <div className="p-3 bg-violet-200 dark:bg-violet-700/30 rounded-xl w-fit">
                        <svg className="w-6 h-6 text-violet-700 dark:text-violet-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-violet-600 dark:text-violet-300">Total Practice Time</p>
                        <p className="text-2xl font-bold text-violet-900 dark:text-white">
                          {practiceLog?.summary?.total_practice_minutes?.toFixed(0) || "0"}<span className="text-base font-medium"> min</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-900/30 border border-violet-400/30 dark:border-violet-900 rounded-2xl p-6 shadow-sm">
                    <div className="flex flex-col space-y-4">
                      <div className="p-3 bg-violet-200 dark:bg-violet-700/30 rounded-xl w-fit">
                        <svg className="w-6 h-6 text-violet-700 dark:text-violet-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-violet-600 dark:text-violet-300">Daily Average</p>
                        <p className="text-2xl font-bold text-violet-900 dark:text-white">
                          {practiceLog?.summary?.avg_minutes_per_day?.toFixed(0) || "0"}<span className="text-base font-medium"> min</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-900/30 border border-violet-400/30 dark:border-violet-900 rounded-2xl p-6 shadow-sm">
                    <div className="flex flex-col space-y-4">
                      <div className="p-3 bg-violet-200 dark:bg-violet-700/30 rounded-xl w-fit">
                        <svg className="w-6 h-6 text-violet-700 dark:text-violet-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-violet-600 dark:text-violet-300">Days Practiced</p>
                        <p className="text-2xl font-bold text-violet-900 dark:text-white">
                          {practiceLog?.summary?.days_practiced || "0"}<span className="text-base font-medium"> days</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-900/30 border border-violet-400/30 dark:border-violet-900 rounded-2xl p-6 shadow-sm">
                    <div className="flex flex-col space-y-4">
                      <div className="p-3 bg-violet-200 dark:bg-violet-700/30 rounded-xl w-fit">
                        <svg className="w-6 h-6 text-violet-700 dark:text-violet-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-violet-600 dark:text-violet-300">First Practice</p>
                        <p className="text-lg font-bold text-violet-900 dark:text-white">
                          {practiceLog?.summary?.first_date_practiced ? new Date(practiceLog.summary.first_date_practiced).toLocaleDateString() : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 dark:bg-gray-900/30 border border-violet-400/30 dark:border-violet-900 rounded-2xl p-6 shadow-sm">
                    <div className="flex flex-col space-y-4">
                      <div className="p-3 bg-violet-200 dark:bg-violet-700/30 rounded-xl w-fit">
                        <svg className="w-6 h-6 text-violet-700 dark:text-violet-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-violet-600 dark:text-violet-300">Last Practice</p>
                        <p className="text-lg font-bold text-violet-900 dark:text-white">
                          {practiceLog?.summary?.most_recent_date_practiced ? new Date(practiceLog.summary.most_recent_date_practiced).toLocaleDateString() : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Practice Log Section */}
                <div className="bg-white dark:bg-gray-800/50 rounded-2xl shadow-sm border border-gray-100 dark:border-gray-700/50 overflow-hidden">
                  <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                    <div className="flex items-center justify-between">
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Practice Log
                      </h3>
                      <div className="relative" ref={dropdownRef}>
                        <button
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors flex items-center space-x-2"
                        >
                          <span>{timeRanges.find(r => r.value === timeRange)?.label}</span>
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </button>
                        {isDropdownOpen && (
                          <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-xl shadow-lg border border-gray-100 dark:border-gray-600 overflow-hidden z-10">
                            {timeRanges.map((range) => (
                              <button
                                key={range.value}
                                onClick={() => {
                                  setIsDropdownOpen(false);
                                  onTimeRangeChange(range.value);
                                }}
                                className={`w-full px-4 py-3 text-left text-sm transition-colors
                                  ${timeRange === range.value 
                                    ? 'bg-violet-50 dark:bg-violet-900/30 text-violet-700 dark:text-violet-300' 
                                    : 'text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600'}`}
                              >
                                {range.label}
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="max-h-[600px] overflow-y-auto">
                    {(!practiceLog?.log || practiceLog.log.length === 0) ? (
                      <div className="flex flex-col items-center justify-center py-16 px-6">
                        <div className="w-20 h-20 mb-6 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center">
                          <svg className="w-10 h-10 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </div>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                          No practice records yet
                        </h3>
                        <p className="text-gray-500 dark:text-gray-400 text-center max-w-sm">
                          Start practicing this piece and we'll keep track of your progress here.
                        </p>
                      </div>
                    ) : (
                      <div className="divide-y divide-gray-200 dark:divide-gray-700">
                        {practiceLog?.log
                          ?.sort((a, b) => new Date(b.practice_date) - new Date(a.practice_date))
                          .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
                          .map((record) => (
                            <div key={record.practice_date} className="p-6 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <div className="w-12 h-12 rounded-xl bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center">
                                    <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                  </div>
                                  <div>
                                    <p className="text-lg font-semibold text-gray-900 dark:text-white">
                                      {record.practice_minutes} minutes
                                    </p>
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                      {new Date(record.practice_date.replace(/(\d{2})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/, '20$3-$1-$2T$4:$5:$6')).toLocaleDateString()}
                                    </p>
                                  </div>
                                </div>
                                <span className="px-4 py-2 text-sm font-medium text-violet-700 dark:text-violet-300 bg-violet-100 dark:bg-violet-900/30 rounded-lg">
                                  {new Date(record.practice_date).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>

                  {practiceLog?.log?.length > recordsPerPage && (
                    <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                      <Pagination
                        page={currentPage}
                        totalResults={practiceLog?.log?.length || 0}
                        resultsPerPage={recordsPerPage}
                        onChange={page => setCurrentPage(page)}
                        label="Practice Log Navigation"
                      />
                    </div>
                  )}
                </div>

                {/* Practice Notes Section */}
                <div className="bg-white dark:bg-gray-800/50 rounded-2xl p-6 shadow-sm border border-gray-100 dark:border-gray-700/50">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Practice Notes
                    </h3>
                    <div className="flex space-x-3">
                      {isEditingPracticeNotes ? (
                        <>
                          <button
                            onClick={() => {
                              setPracticeNotesInput(piece.practice_notes || '');
                              setIsEditingPracticeNotes(false);
                            }}
                            className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                            disabled={isUpdatingPracticeNotes}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleUpdatePracticeNotes}
                            disabled={isUpdatingPracticeNotes}
                            className="px-4 py-2 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-violet-700 dark:bg-violet-500 dark:hover:bg-violet-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {isUpdatingPracticeNotes ? 'Saving...' : 'Save Changes'}
                          </button>
                        </>
                      ) : (
                        <button
                          onClick={() => setIsEditingPracticeNotes(true)}
                          className="px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-colors flex items-center space-x-2"
                        >
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                          </svg>
                          <span>Edit Notes</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {isEditingPracticeNotes ? (
                    <textarea
                      value={practiceNotesInput}
                      onChange={(e) => setPracticeNotesInput(e.target.value)}
                      rows="4"
                      className="w-full p-4 text-gray-900 bg-gray-50 border border-gray-200 rounded-xl focus:ring-2 focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700/50 dark:border-gray-600 dark:text-white transition-all duration-200"
                      placeholder="Add notes about your practice sessions..."
                    />
                  ) : (
                    <div className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl text-gray-700 dark:text-gray-300">
                      {piece.practice_notes?.notes || "This piece has no practice notes yet."}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'info' && (
            <div className="p-6 space-y-8">
              {/* Historical Context Section */}
              <div className="bg-white dark:bg-gray-800/50 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700/50">
                <div className="flex items-start space-x-6">
                  <div className="flex-shrink-0">
                    <PeriodEmblem period={piece.time_period} />
                  </div>
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                      Historical Context
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400 leading-relaxed text-base">
                      {piece.history || "We do not have a historical context for this piece yet."}
                    </p>
                  </div>
                </div>
              </div>

              {/* Tags Section */}
              {piece && (
                <div className="bg-white dark:bg-gray-800/50 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700/50">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Tags
                    </h2>
                    <button
                      onClick={() => setShowTagInput(true)}
                      className="p-2 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300 rounded-lg hover:bg-violet-50 dark:hover:bg-violet-900/20 transition-colors"
                      title="Add tag"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {showTagInput && (
                      <div className="flex items-center bg-violet-50/50 dark:bg-violet-900/10 p-1 rounded-full">
                        <input
                          type="text"
                          value={newTag}
                          onChange={(e) => setNewTag(e.target.value)}
                          onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
                          className="px-4 py-1.5 text-sm bg-transparent border-none focus:ring-0 dark:text-white"
                          placeholder="Enter new tag..."
                          autoFocus
                        />
                        <div className="flex items-center px-2">
                          <button
                            onClick={handleAddTag}
                            className="p-1.5 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300"
                          >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                          </button>
                          <button
                            onClick={() => {
                              setShowTagInput(false);
                              setNewTag('');
                            }}
                            className="p-1.5 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                          >
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                    {(!piece.tags || piece.tags.length === 0) && !showTagInput && (
                      <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                        No tags yet
                      </p>
                    )}
                    {piece.tags && piece.tags.map((tag, index) => (
                      <span
                        key={index}
                        className={`inline-flex items-center px-4 py-1.5 text-sm font-medium rounded-full ${getTagColor(tag)}`}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {/* Teacher Notes Section */}
              {piece && userInfo?.role === 'teacher' && (
                <div className="bg-white dark:bg-gray-800/50 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700/50">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Teacher Notes
                    </h2>
                    <button
                      onClick={() => setShowTeacherNoteInput(true)}
                      className="p-2 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300 rounded-lg hover:bg-violet-50 dark:hover:bg-violet-900/20 transition-colors"
                      title="Add note"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-col space-y-3">
                    {piece?.piece_notes?.map((note, index) => (
                      <div
                        key={index}
                        className="p-4 bg-violet-50/50 rounded-xl dark:bg-violet-900/10 border border-violet-100 dark:border-violet-800/20"
                      >
                        <div className="flex items-start space-x-3">
                          <div className="flex-shrink-0">
                            {note?.profile_pic ? (
                              <img
                                src={`${note.profile_pic}`}
                                alt={`${note.first_name} ${note.last_name}`}
                                className="w-10 h-10 rounded-full object-cover ring-2 ring-violet-100 dark:ring-violet-800/30"
                              />
                            ) : (
                              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-violet-100 rounded-full dark:bg-violet-900/30 ring-2 ring-violet-100 dark:ring-violet-800/30">
                                <span className="font-medium text-violet-600 dark:text-violet-300">
                                  {getInitial(note.first_name) + getInitial(note.last_name)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="flex items-center justify-between">
                              <p className="text-sm font-medium text-violet-700 dark:text-violet-300">
                                {note.first_name} {note.last_name}
                              </p>
                              <span className="text-xs text-violet-500 dark:text-violet-400">
                                {new Date(note.time).toLocaleDateString('en-US', {
                                  month: 'short',
                                  day: 'numeric',
                                  year: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                })}
                              </span>
                            </div>
                            <p className="mt-2 text-sm text-gray-600 dark:text-gray-300 whitespace-pre-wrap">
                              {note.notes}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    {showTeacherNoteInput && (
                      <div className="flex items-center space-x-2 bg-violet-50/50 dark:bg-violet-900/10 p-3 rounded-xl">
                        <input
                          type="text"
                          value={newTeacherNote}
                          onChange={(e) => setNewTeacherNote(e.target.value)}
                          onKeyPress={(e) => e.key === 'Enter' && handleAddTeacherNote()}
                          className="flex-1 px-4 py-2 text-sm bg-white dark:bg-gray-800 border border-violet-200 dark:border-violet-800/30 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:text-white"
                          placeholder="Add a note..."
                          autoFocus
                        />
                        <button
                          onClick={handleAddTeacherNote}
                          disabled={isAddingTeacherNote || !newTeacherNote.trim()}
                          className="p-2 text-violet-600 hover:text-violet-700 dark:text-violet-400 dark:hover:text-violet-300 disabled:opacity-50 hover:bg-violet-100 dark:hover:bg-violet-900/30 rounded-lg transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </button>
                        <button
                          onClick={() => {
                            setShowTeacherNoteInput(false);
                            setNewTeacherNote('');
                          }}
                          className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
                        >
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Sample Recordings Section */}
              <div className="bg-white dark:bg-gray-800/50 rounded-xl p-6 shadow-sm border border-gray-100 dark:border-gray-700/50">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  Sample Recordings
                </h3>
                <div className="flex flex-col items-center justify-center py-12 text-center bg-gray-50 dark:bg-gray-800/30 rounded-xl">
                  <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                    Coming Soon
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 max-w-md">
                    We're working on bringing you high quality recordings for this piece and more to accelerate your practice.
                  </p>
                </div>
              </div>

              {/* Help Us Improve Section */}
              <div className="bg-violet-50 dark:bg-violet-900/20 rounded-xl p-6 border border-violet-100 dark:border-violet-800/20">
                <p className="text-sm text-violet-600 dark:text-violet-400">
                  If you have more to add to this piece's historical context or can recommend some better recordings,
                  please email us at <a href="mailto:acn57@cornell.edu" className="underline hover:text-violet-700 dark:hover:text-violet-300">
                    acn57@cornell.edu</a>. We would love to improve this page with your help!
                </p>
              </div>
            </div>
          )}

          {activeTab === 'exercises' && (
            <div className="p-6">
              <div className="flex flex-row justify-center sm:justify-start lg:ml-4 flex-wrap gap-1 gap-y-4 overflow-y-scroll no-scrollbar">
                {exercises && exercises.length > 0 && exercises.map((drill) => (
                  <DrillsCard
                    key={drill.id}
                    drill={drill}
                  />
                ))}
                {exercises && exercises.length === 0 && (
                  <div className="w-full col-span-full flex flex-col items-center justify-center py-12">
                    <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                      No exercises yet
                    </h3>
                    <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                      Create your first exercise for this piece to get started!
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Right Sidebar */}
      <div className="w-80 h-full flex-shrink-0 bg-white rounded-2xl shadow-lg dark:bg-gray-800 border border-gray-200/50 dark:border-gray-700/50 overflow-y-auto no-scrollbar">
        <div className="p-4 h-full flex flex-col">
          {/* Primary Actions */}
          <div className="flex space-x-2 mb-6">
            <button
              onClick={() => { window.location.href = "/dashboard/exercises/create-drill" }}
              className="flex-1 inline-flex justify-center items-center px-4 py-2.5 text-sm font-semibold text-white bg-violet-600 rounded-lg hover:bg-violet-700 transition-colors"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              New Exercise
            </button>

            <AddToList
              piece_id={piece.id}
              setDisabled={() => { }}
              button_view={
                <button className="inline-flex justify-center items-center p-2.5 text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-colors">
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                  </svg>
                </button>
              }
            />
          </div>

          {/* Navigation Tabs */}
          <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-1.5 mb-5">
            <nav className="grid grid-cols-2 gap-1">
              <button
                onClick={() => setActiveTab('view')}
                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'view'
                  ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                  : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                  }`}
              >
                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                View
              </button>
              <button
                onClick={() => setActiveTab('info')}
                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'info'
                  ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                  : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                  }`}
              >
                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Piece Info
              </button>
              <button
                onClick={() => setActiveTab('practice')}
                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'practice'
                  ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                  : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                  }`}
              >
                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>
                Practice
              </button>
              <button
                onClick={() => setActiveTab('exercises')}
                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'exercises'
                  ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                  : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                  }`}
              >
                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                </svg>
                Exercises
              </button>
            </nav>
          </div>

          {/* Flag Piece */}
          <Modal title="Report issue" button_text="Report issue" submit={submit} button_view={
            <div className="mb-2 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 cursor-pointer">
              <div className="flex items-center justify-between">
                <div className="flex items-center w-full">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900 dark:text-white">Flag for Review</h3>
                    <p className="text-xs text-gray-500 dark:text-gray-400">Mark this piece for instructor review</p>
                  </div>
                </div>
                <button className="p-2 text-gray-600 dark:text-white rounded-lg">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                    />
                  </svg>
                </button>
              </div>
            </div>
          }>
            <div className="w-full mb-2">
              <label for="message"
                className="block mb-2 text-sm font-medium dark:text-white">What is wrong with the piece?</label>
              <textarea id="message" rows="4"
                onChange={(e) => setMessage(e.target.value)}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                value={message}
                placeholder="Please explain the issue with this piece. Thanks!"></textarea>
            </div>
          </Modal>

          {/* Keyboard Shortcuts */}
          <div onClick={() => setSeenKeyboardShortcuts(false)} className="mb-2 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 cursor-pointer">
            <div className="flex items-center justify-between">
              <div className="flex items-center w-full">
                <div>
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">Keyboard Shortcuts</h3>
                  <p className="text-xs text-gray-500 dark:text-gray-400">Check out the keyboard shortcuts for this page</p>
                </div>
              </div>
              {/* <FlagPiece id={piece.id} /> */}
              <button className="p-2 text-gray-600 dark:text-white rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                </svg>
              </button>
            </div>
          </div>

          {/* Stats Section */}
          <div className="mt-auto">
            <div className="bg-gray-50 dark:bg-gray-900 rounded-xl p-2">
              <div className="flex items-center justify-between mb-4 mt-1">
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white flex items-center">
                  <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                  Practice Statistics
                </h3>
              </div>

              <div className="grid grid-cols-1 gap-5">
                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-400">Total Practice Time</span>
                    <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                      {practiceLog && practiceLog.summary && practiceLog.summary.total_practice_minutes ? practiceLog.summary.total_practice_minutes.toFixed(0) : "0"} minutes
                    </span>
                  </div>
                  <div className="bg-violet-100 dark:bg-violet-900/30 p-2 rounded-lg">
                    <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                </div>

                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-400">Average Practice Time</span>
                    <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                      {practiceLog && practiceLog.summary && practiceLog.summary.avg_minutes_per_day ? practiceLog.summary.avg_minutes_per_day.toFixed(0) : "0"} mins/day
                    </span>
                  </div>
                  <div className="bg-emerald-100 dark:bg-emerald-900/30 p-2 rounded-lg">
                    <svg className="w-5 h-5 text-emerald-600 dark:text-emerald-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg>
                  </div>
                </div>

                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                  <div className="flex flex-col">
                    <span className="text-xs text-gray-500 dark:text-gray-400">Last Practice Session</span>
                    <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                      {practiceLog && practiceLog.summary && practiceLog.summary.most_recent_date_practiced ? new Date(practiceLog.summary.most_recent_date_practiced).toLocaleDateString() : "N/A"}
                    </span>
                  </div>
                  <div className="bg-sky-100 dark:bg-sky-900/30 p-2 rounded-lg">
                    <svg className="w-5 h-5 text-sky-600 dark:text-sky-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Piece