import React, { useState, useEffect, useContext } from 'react';
import { AccountContext } from '../../stores/Account';
import { useSearchParams } from 'react-router-dom';
import useStateParam from '../../hooks/useStateParam';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import DrillsCard from '../components/DrillsCard';
import QueryPiece from '../components/QueryPiece';
import CreateDrill from './CreateDrill';
import Pagination from '../components/Pagination';
import listDrills from '../../api/listDrills';
import { Label } from '../../components/Input';
import ChatInterface from '../components/ChatInterface';
import ExerciseCard from '../components/ExerciseCard';
import ExerciseView from '../components/ExerciseView';
//import deleteDrill from '../../api/deleteDrill';

function Exercises() {
    const { width, height } = useWindowDimensions();
    const isLandscape = width > height;

    const { user } = useContext(AccountContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [drills, setDrills] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [piece, setPiece] = useState(() => {
        const id = searchParams.get('piece_id');
        const name = searchParams.get('piece_name');
        return id && name ? { id, name } : null;
    });
    const [searchWord, setSearchWord] = useStateParam('search', undefined);
    const [page, setPage] = useStateParam('page', "int", 1);
    const [firstRender, setFirstRender] = useState(true);
    const [selectedExercise, setSelectedExercise] = useState(null);

    const resultsPerPage = 10;

    useEffect(() => {
        const newParams = new URLSearchParams(searchParams.toString());
        if (piece) {
            newParams.set('piece_id', piece.id);
            newParams.set('piece_name', piece.name);
        } else {
            newParams.delete('piece_id');
            newParams.delete('piece_name');
        }
        setSearchParams(newParams, { replace: true });
    }, [piece]);

    const fetchDrills = async (page) => {
        setIsLoading(true);
        try {
            const offset = (page - 1) * resultsPerPage;
            const drillsData = await listDrills(
                user,
                piece?.id || "-",
                searchWord || "-",
                offset
            );
            setDrills(drillsData.drills || []);
            setTotalResults(drillsData.count || 0);
            setFirstRender(false);
        } catch (error) {
            console.error('Error fetching drills:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchWord !== undefined && searchWord !== null) {
                setDrills([]);
                setPage(1);
                fetchDrills(1);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchWord]);

    const handlePieceSelect = (piece) => {
        if (piece) {
            setPiece({ id: piece.id, name: piece.title });
        } else {
            setPiece(null);
        }
    };

    useEffect(() => {
        setDrills([]);
        setPage(1);
        fetchDrills(1);
    }, [piece]);

    function onPageChange(page) {
        if (!firstRender) {
            setPage(page);
            setDrills([]);
            fetchDrills(page);
        }
        console.log("Calling on page change");
    };

    const handleDelete = async (exerciseId) => {
        try {
            //await deleteDrill(user, exerciseId);
            //fetchDrills(page);
        } catch (error) {
            console.error('Error deleting exercise:', error);
        }
    };

    const handleExerciseSelect = (exercise) => {
        setSelectedExercise(exercise);
    };

    return (
        <div className="w-full overflow-hidden no-scrollbar">
            <div className="box-border overflow-x-hidden shadow-md w-full dark:bg-gray-800/50 bg-white/50 overflow-hidden no-scrollbar">
                {/* Top bar that spans full width */}
                <div className="w-full border-b border-gray-200 dark:border-gray-600 px-6 py-4">
                    <div className="flex flex-wrap flex-col sm:flex-row gap-2">
                        <div className="flex-1 flex gap-2">
                            <div className="flex-1">
                                <input
                                    type="text"
                                    value={searchWord}
                                    onChange={(e) => setSearchWord(e.target.value)}
                                    className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                    placeholder="Search exercises by name..."
                                />
                            </div>
                            <div className="flex-1">
                                <QueryPiece
                                    placeholder="Filter by piece..."
                                    showSearch={false}
                                    instrument="piano"
                                    setSelectedPiece={handlePieceSelect}
                                    selectedPiece={piece ? { id: piece.id, title: piece.name } : null}
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <button
                                onClick={() => { window.location.href = "/dashboard/exercises/create-drill" }}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                            >
                                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                </svg>
                                <span className="whitespace-nowrap">Create Exercise</span>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Content area with split view */}
                <div className="flex flex-row h-[calc(100vh-5rem)]">
                    {/* Exercise list panel */}
                    <div className={isLandscape ? "w-2/3 p-6 flex flex-col min-h-0" : "w-full p-6 flex flex-col min-h-0"}>
                        {/* Scrollable list container */}
                        <div className="flex-1 overflow-y-auto space-y-2 pr-2 no-scrollbar">
                            {isLoading ? (
                                // Loading skeletons
                                Array.from({ length: 5 }).map((_, i) => (
                                    <div key={i} className="animate-pulse">
                                        <div className="h-20 bg-gray-100 dark:bg-gray-700 rounded-lg"></div>
                                    </div>
                                ))
                            ) : drills && drills.length > 0 ? (
                                drills.map((drill) => (
                                    <div key={drill.id} onClick={() => handleExerciseSelect(drill)}>
                                        <ExerciseCard
                                            exercise={drill}
                                            onDelete={handleDelete}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className="text-center py-12">
                                    <svg className="w-16 h-16 text-gray-400 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                    </svg>
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                                        No exercises found
                                    </h3>
                                    <p className="text-gray-500 dark:text-gray-400 text-center max-w-md mx-auto">
                                        {searchWord || piece
                                            ? "No exercises match your search criteria. Try adjusting your filters."
                                            : "Create your first exercise to get started!"}
                                    </p>
                                </div>
                            )}
                        </div>

                        {/* Pagination fixed at bottom */}
                        <div className="pt-4 mt-auto">
                            <Pagination
                                totalResults={totalResults}
                                resultsPerPage={resultsPerPage}
                                onChange={onPageChange}
                                label="Table navigation"
                            />
                        </div>
                    </div>

                    {/* Right panel - Chat Interface or Exercise View (only in landscape) */}
                    {isLandscape && (
                        <div className="w-1/3 border-l p-2 border-gray-200 dark:border-gray-600">
                            {selectedExercise ? (
                                <ExerciseView
                                    exercise={selectedExercise}
                                    onClose={() => setSelectedExercise(null)}
                                />
                            ) : (
                                <ChatInterface />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Exercises;
