import React from 'react';
import FlatEmbed from './FlatEmbed';

function ExerciseView({ exercise, onClose }) {
  return (
    <div className="flex flex-col h-full">
      {/* Header with back button */}
      <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-600 flex items-center">
        <button
          onClick={onClose}
          className="mr-3 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">Exercise Details</h2>
      </div>

      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto p-4 space-y-6">
        {/* Title and metadata */}
        <div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
            {exercise.drill_name}
          </h1>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Created by {exercise.created_by} • {exercise.created_time}
          </div>
        </div>

        {/* Score preview */}
        <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-4">
          <FlatEmbed
            scoreId={exercise.flat_score_id}
            sharingKey={exercise.flat_sharing_key}
            height="300px"
          />
        </div>

        {/* Exercise details */}
        <div className="space-y-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
              Exercise Information
            </h3>
            <div className="space-y-2 text-sm text-gray-600 dark:text-gray-300">
              <div>
                <span className="font-medium">Base Piece:</span>{' '}
                {exercise.piece_id ? exercise.piece_title : 'Standalone exercise'}
              </div>
              <div>
                <span className="font-medium">Practice Time:</span>{' '}
                {exercise.practice_time || 0} minutes
              </div>
              <div>
                <span className="font-medium">Last Practiced:</span>{' '}
                {exercise.last_viewed || 'Never'}
              </div>
            </div>
          </div>

          {/* Exercise configuration */}
          <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
              Configuration
            </h3>
            <pre className="text-xs text-gray-600 dark:text-gray-300 bg-gray-50 dark:bg-gray-900 rounded p-3 overflow-x-auto">
              {JSON.stringify(exercise.creation_json, null, 2)}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExerciseView; 