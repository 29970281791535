import React, { useContext } from 'react';
import { AccountContext } from '../../stores/Account';

function ExerciseCard({ exercise, onDelete }) {
  const handleClick = (e) => {
    // Only trigger parent click if not clicking buttons
    if (!e.target.closest('button')) {
      e.currentTarget.click();
    }
  };


  const { userInfo } = useContext(AccountContext);
  return (
    <div
      className="flex items-center p-4 bg-white dark:bg-gray-800 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200 cursor-pointer min-w-[700px]"
      onClick={handleClick}
    >
      {/* Left side - Icon */}
      <div className="flex-shrink-0 mr-4">
        {/* TODO: Add icon for variations */}
        {exercise.piece_id ? (
          <div className="p-2 bg-violet-50 dark:bg-violet-900/20 rounded-lg">
            <svg className="w-6 h-6 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
              />
            </svg>
          </div>
        ) : (
          <div className="p-2 bg-gray-50 dark:bg-gray-700 rounded-lg">
            <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
              />
            </svg>
          </div>
        )}
      </div>

      {/* Title and Base Piece Info */}
      <div className="flex-1 min-w-0">
        <h3 className="text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
          {exercise.drill_name ? exercise.drill_name : 'Unnamed Exercise'}
        </h3>
        <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 whitespace-nowrap">
          {exercise.piece_id ? (
            <span>Based on: {exercise.piece_title}</span>
          ) : (
            <span className="italic">Standalone exercise</span>
          )}
        </div>
      </div>

      {/* Creation Info */}
      <div className="flex-shrink-0 mx-6 text-right whitespace-nowrap">
        <div className="text-xs text-gray-500 dark:text-gray-400">
          Created by {userInfo?.first_name} {userInfo?.last_name}
        </div>
        <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
          {exercise.created_time
            ? `Created: ${exercise.created_time.split(' ')[0]}`
            : 'Created: Unknown'}
        </div>
      </div>

      {/* Practice Stats */}
      <div className="flex-shrink-0 mx-6 text-right whitespace-nowrap">
        <div className="text-sm font-medium text-violet-600 dark:text-violet-400">
          {exercise.practice_time || 0} mins
        </div>
        <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
          {exercise.last_viewed
            ? `Last Viewed: ${exercise.last_viewed.split(' ')[0]}`
            : 'Last Viewed: Never'}
        </div>
      </div>

      {/* Actions */}
      <div className="flex items-center gap-3 ml-6">
        {/* <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete && onDelete(exercise.id);
          }}
          className="p-1.5 text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors duration-200"
          title="Delete exercise"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button> */}
        <button
          onClick={() => window.location.href = `/dashboard/exercises/${exercise.id}`}
          className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-colors duration-200"
        >
          <span className="mr-2">Practice</span>
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default ExerciseCard; 