import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StarIcon, ClockIcon, PauseCircleIcon, TrophyIcon } from '@heroicons/react/24/solid';
import { AccountContext } from '../../stores/Account';
import PieceCard from '../components/PieceCard';
import { createImageFromInitials } from "../../components/LetterPicture";
import { capitalize, getInitial } from "../../utils/string";
import ProfileStats from '../components/ProfileStats';
import getRecentPieces from '../../api/getRecentPieces';
import getAlbums from '../../api/getAlbums';
import practiceSummary from '../../api/practiceSummary';
import practiceDistribution from '../../api/practiceDistribution';
import CreateList from '../components/CreateList';
import WeeklyPractice from '../components/WeeklyPractice';
import listDrills from '../../api/listDrills';
const Profile = () => {
  const { user, userInfo } = useContext(AccountContext);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [recentPieces, setRecentPieces] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [practiceStats, setPracticeStats] = useState(null);
  const [distributionLabels, setDistributionLabels] = useState([]);
  const [distributionData, setDistributionData] = useState([]);
  const [showPracticeLog, setShowPracticeLog] = useState(false);
  const [drills, setDrills] = useState([]);

  useEffect(() => {
    if (user?.user) {
      getRecentPieces(user).then(data => {
        const sortedPieces = (data.pieces || []).sort((a, b) => b.total_time - a.total_time);
        setRecentPieces(sortedPieces);
      });

      getAlbums(user).then(data => {
        setAlbums(data.albums || []);
      });

      practiceSummary(user).then(data => {
        setPracticeStats(data);
      });

      practiceDistribution(user, 7, user.id).then(dat => {
        setDistributionLabels(dat.labels || []);
        setDistributionData(dat.data || []);
      });

      listDrills(user).then(data => {
        setDrills(data.drills || []);
      });
    }
  }, [user]);

  return (
    <div className="h-screen no-scrollbar items-center justify-center overflow-x-hidden bg-gray-50 dark:bg-gray-800/30 px-2 py-2">
      <div className="h-full grid grid-cols-12 gap-2 pb-2">
        {/* Title and Stats Section - Top Left */}
        <div className="col-span-9 row-span-2 col-start-1 row-start-1 bg-white dark:bg-gray-900 rounded-xl shadow">
          <div className="h-full flex flex-col px-6 pt-4">
            {/* Welcome and Logo Section */}
            <div className="flex items-start justify-center mb-4">
              {/* Logo - Now on the left */}
              <div className="flex items-center justify-center rounded-xl pt-1 mr-4">
                <img
                  src="/SubitoLogoNoText.png"
                  alt="Subito Logo"
                  className="w-16 h-16 object-contain"
                />
              </div>

              {/* Welcome Text and Buttons */}
              <div className="flex-1 flex justify-between items-start">
                <div>
                  <h1 className="text-3xl font-bold text-gray-900 dark:text-white text-nowrap">
                    Welcome back, {userInfo?.first_name ? `${userInfo?.first_name}`.trim() : 'Musician'}!
                  </h1>
                  <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-300">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="text-sm">
                      You've practiced <span className="font-semibold text-violet-600 dark:text-violet-400">{practiceStats?.today_practice || 0}</span> minutes today
                    </p>
                  </div>
                </div>

                {/* Settings and Teacher Dashboard Buttons */}
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => navigate('/dashboard/settings')}
                    className="p-2 rounded-lg bg-violet-100 hover:bg-violet-200 dark:bg-violet-900/30 dark:hover:bg-violet-900/50 text-violet-600 dark:text-violet-400 transition-colors"
                    title="Settings"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => navigate('/dashboard/teacherdashboard')}
                    className="p-2 rounded-lg bg-violet-100 hover:bg-violet-200 dark:bg-violet-900/30 dark:hover:bg-violet-900/50 text-violet-600 dark:text-violet-400 transition-colors"
                    title="Teacher Dashboard"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M12 14l9-5-9-5-9 5 9 5z"
                      />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0L3 9m9 5v7m0-7l9-5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Profile Section - Right Side */}
        <div className="col-span-3 row-span-12 col-start-10 row-start-1 bg-white dark:bg-gray-900 rounded-xl shadow flex flex-col min-h-0 p-1">
          <div className="p-4 bg-violet-50 dark:bg-violet-900/30 m-2 rounded-xl outline outline-2 outline-violet-600/30">
            <div className="flex flex-col items-center">
              <div className="relative group">
                <img
                  src={selectedImage || userInfo?.profile_pic || createImageFromInitials(200, getInitial(userInfo?.first_name + " " + userInfo?.last_name), "#7c3aed")}
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover ring-4 ring-violet-600/30 shadow-lg transition-transform duration-300 hover:scale-105"
                />
                {/* <div className="absolute -bottom-2 -right-2">
                  <div className="p-2 rounded-full bg-violet-100 dark:bg-violet-900/50 text-violet-600 dark:text-violet-400 shadow-md hover:bg-violet-200 dark:hover:bg-violet-900/70 transition-colors cursor-pointer">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                </div> */}
              </div>
              <div className="mt-1 text-center">
                <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                  {userInfo?.first_name} {userInfo?.last_name}
                </h2>
                <div className="mt-1 flex items-center justify-center space-x-2">
                  <span className="inline-flex items-center px-6 rounded-full text-xs font-bold bg-violet-600 text-white shadow-sm">
                    {capitalize(userInfo?.role) || "Student"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col min-h-0">
            {/* Recent Pieces Section */}
            <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700 bg-gray-50 rounded-lg dark:bg-gray-900/50 flex justify-between items-center mb-1">
              <div className="flex items-center space-x-3">
                <h3 className="text-sm font-semibold text-gray-900 dark:text-white">Recent Pieces</h3>
              </div>
              <button
                onClick={() => navigate('/dashboard/music?pieces=viewed')}
                className="inline-flex items-center justify-center rounded-lg bg-violet-100 hover:bg-violet-200 dark:bg-violet-900/30 dark:hover:bg-violet-900/50 p-2 transition-colors group"
                title="View Library"
              >
                <svg className="w-4 h-4 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                    d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                  />
                </svg>

              </button>
            </div>
            <div className="overflow-y-auto no-scrollbar max-h-[250px] divide-y divide-gray-100 dark:divide-gray-700/50">
              {recentPieces.map((piece) => (
                <div
                  key={piece.id}
                  className="group px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-700/50 cursor-pointer transition-colors rounded-lg"
                  onClick={() => navigate(`/dashboard/piece/${piece.id}`)}
                >
                  <div className="flex items-start justify-between">
                    <div className="flex-1 min-w-0 pr-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white truncate group-hover:text-violet-600 dark:group-hover:text-violet-400 transition-colors">
                        {piece.title}
                      </h4>
                      <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                        {piece.composer || 'Unknown composer'}
                      </p>
                    </div>
                    <div className="flex flex-col items-end">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-violet-100 dark:bg-violet-900/30 text-violet-600 dark:text-violet-400">
                        {Math.round(piece.total_time) || 0} mins
                      </span>
                      <div className="mt-1 flex items-center text-xs text-gray-400 dark:text-gray-500">
                        <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        {piece.last_practice_time ? (
                          new Date(piece.last_practice_time).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric'
                          })
                        ) : 'Never practiced'}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Albums Section */}
            <div className="flex flex-col flex-1 min-h-0 rounded-lg shadow">
              <div className="px-2 py-2 border-t border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900/50 flex justify-between items-center rounded-t-lg">
                <div className="flex items-center space-x-2">
                  <h3 className="text-sm font-medium text-gray-900 dark:text-white">Albums</h3>
                </div>
                <CreateList button_view={<button
                  className="inline-flex items-center justify-center rounded-lg bg-violet-100 hover:bg-violet-200 dark:bg-violet-900/30 dark:hover:bg-violet-900/50 p-2 transition-colors"
                  title="Create Album"
                >
                  <svg className="w-3 h-3 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                  </svg>
                </button>} />
              </div>
              <div className="flex-1 overflow-y-scroll no-scrollbar">
                {albums.map((album) => (
                  <div key={album.id}
                    className="px-2 py-2 border-b border-gray-50 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer flex items-center"
                    onClick={() => window.location.href = `/dashboard/music/album/${album.id}`}
                  >
                    {/* Album Image or Fallback Icon */}
                    {album.picture ? (
                      <img
                        src={album.picture}
                        alt={album.name}
                        className="w-10 h-10 rounded-lg object-cover flex-shrink-0"
                        onError={(e) => {
                          e.target.style.display = 'none';
                          e.target.nextSibling.style.display = 'flex';
                        }}
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-lg bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center flex-shrink-0">
                        <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="ml-3 flex-1 min-w-0">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white truncate">
                        {album.name}
                      </h4>
                      <div className="flex items-center space-x-2 text-xs text-gray-500 dark:text-gray-400">
                        <span className="truncate">
                          {album.piece_count || 0} {album.piece_count === 1 ? 'piece' : 'pieces'}
                        </span>
                        <span className="text-gray-300 dark:text-gray-600">•</span>
                        <span className="truncate">
                          Updated {new Date(album.last_updated).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric'
                          })}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = `/dashboard/music/album/${album.id}?mode=play`;
                      }}
                      className="ml-4 p-1.5 rounded-lg bg-violet-100 hover:bg-violet-200 dark:bg-violet-900/30 dark:hover:bg-violet-900/50 text-violet-600 dark:text-violet-400 transition-colors flex-shrink-0"
                      title="Play Album"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Practice Breakdown Section */}
        <div className="col-span-6 row-span-10 col-start-1 row-start-3 p-4 bg-white dark:bg-gray-900 rounded-xl shadow overflow-hidden">
          <div className="h-full">
            <div className="flex flex-col h-full">
              {/* Chart or Log takes full height */}
              <WeeklyPractice
                timeRange={7}
                showLog={showPracticeLog}
                onShowLogChange={setShowPracticeLog}
              />

              {/* Only show piece breakdown when not showing log */}
              {!showPracticeLog && (
                <>
                  {/* Divider */}
                  <div className="dark:border-t dark:border-gray-700" />

                  {/* Piece breakdown with smaller header and extra padding */}
                  <div className="flex-1 p-4 overflow-y-auto no-scrollbar">
                    <h4 className="text-xs font-semibold text-gray-600 dark:text-gray-400 mb-2">Piece Practice Breakdown</h4>
                    <div className="space-y-2">
                      {distributionLabels.slice(0, 4).map((label, index) => {
                        const percentage = distributionData && distributionData[index] && distributionData[index].length == 2 ? distributionData[index][1] : 0;
                        const minutes = distributionData && distributionData[index] && distributionData[index].length == 2 ? distributionData[index][0] : 0;
                        return (
                          <div key={label} className="flex space-x-3">
                            <div className="flex-1">
                              <div className="flex justify-between items-center">
                                <div className="text-xs font-medium text-gray-700 dark:text-white truncate">
                                  {label}
                                </div>
                                <div className="text-xs text-gray-500 dark:text-gray-400 whitespace-nowrap ml-4">
                                  {minutes} mins
                                </div>
                              </div>
                              <div className="flex items-center mt-1">
                                <div className="w-full bg-gray-50 rounded-full h-2 dark:bg-gray-700">
                                  <div
                                    className="bg-violet-600 h-2 rounded-full transition-all duration-300"
                                    style={{ width: `${percentage}%` }}
                                  ></div>
                                </div>
                                <span className="ml-2 text-[10px] leading-none text-gray-500 dark:text-gray-400">{percentage}%</span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Summary Section */}
        <div className="col-span-3 row-span-5 col-start-7 row-start-3 bg-white dark:bg-gray-900 rounded-xl shadow overflow-hidden flex flex-col h-[270px]">
          <div className="p-2 grid grid-cols-2 gap-2 overflow-y-hidden">
            {/* Weekly Average */}
            <div className="col-span-2 rounded-xl dark:bg-gray-900/50 border border-violet-600/30 dark:border-violet-900 p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="bg-violet-200 dark:bg-violet-800 p-2 rounded-lg">
                    <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-medium font-medium text-violet-900 dark:text-violet-300">Daily Average</p>
                    <p className="text-2xl font-bold text-violet-700 dark:text-violet-200">
                      {parseFloat(practiceStats?.average_minutes_per_day || 0).toFixed(1)}
                      <span className="text-sm font-normal text-violet-600 dark:text-violet-400 ml-1">minutes</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Practice Stats Grid */}
            <div className="space-y-2">
              {/* Practice Streak */}
              <div className="dark:bg-gray-900/50 border border-violet-600/30 dark:border-violet-900 rounded-xl p-2">
                <div className="flex items-center space-x-3">
                  <div className="bg-violet-200 dark:bg-violet-800 p-2 rounded-lg">
                    <svg className="w-4 h-4 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs font-medium text-violet-900 dark:text-violet-300">Practice Streak</p>
                    <p className="text-lg font-bold text-violet-700 dark:text-violet-200">
                      {practiceStats?.days_over_5_min || 0}
                      <span className="text-xs font-normal text-violet-600 dark:text-violet-400 ml-1">days</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Total Pieces */}
              <div className="dark:bg-gray-900/50 border border-violet-600/30 dark:border-violet-900 rounded-xl p-2">
                <div className="flex items-center space-x-3">
                  <div className="bg-violet-200 dark:bg-violet-800 p-2 rounded-lg">
                    <svg className="w-4 h-4 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs font-medium text-violet-900 dark:text-violet-300">Total Pieces</p>
                    <p className="text-lg font-bold text-violet-700 dark:text-violet-200">
                      {practiceStats?.total_items_practiced || 0}
                      <span className="text-xs font-normal text-violet-600 dark:text-violet-400 ml-1">pieces</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              {/* Monthly Total */}
              <div className="dark:bg-gray-900/50 border border-violet-600/30 dark:border-violet-900 rounded-xl p-2">
                <div className="flex items-center space-x-3">
                  <div className="bg-violet-200 dark:bg-violet-800 p-2 rounded-lg">
                    <svg className="w-4 h-4 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs font-medium text-violet-900 dark:text-violet-300">Monthly Total</p>
                    <p className="text-lg font-bold text-violet-700 dark:text-violet-200">
                      {practiceStats?.month_practice || 0}
                      <span className="text-xs font-normal text-violet-600 dark:text-violet-400 ml-1">mins</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* Total Time */}
              <div className="dark:bg-gray-900/50 border border-violet-600/30 dark:border-violet-900 rounded-xl p-2">
                <div className="flex items-center space-x-3">
                  <div className="bg-violet-200 dark:bg-violet-800 p-2 rounded-lg">
                    <svg className="w-4 h-4 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-xs font-medium text-violet-900 dark:text-violet-300">Total Time</p>
                    <p className="text-lg font-bold text-violet-700 dark:text-violet-200">
                      {Math.round((practiceStats?.total_minutes || 0) / 60)}
                      <span className="text-xs font-normal text-violet-600 dark:text-violet-400 ml-1">hrs</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Drills Section */}
        <div className="col-span-3 row-span-5 col-start-7 row-start-8 bg-white dark:bg-gray-900 rounded-xl shadow overflow-hidden flex flex-col h-[400px]">
          <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-900/50 flex justify-between items-center">
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white">Practice Exercises</h3>
          </div>
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto no-scrollbar">
              {drills.map((drill) => (
                <div
                  key={drill.id}
                  className="group p-2 border-b border-gray-100 dark:border-gray-700/50 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors cursor-pointer"
                >
                  <div className="flex justify-between items-center w-full">
                    <div className="flex flex-col">
                      <h4 className="text-xs font-semibold text-gray-900 dark:text-white">{drill.drill_name || "Drill"}</h4>
                      <span className="mt-1 text-xs text-gray-400 dark:text-gray-500">{drill.piece_title || "No base piece"}</span>
                    </div>
                    <div className="flex flex-col items-end">
                      <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${drill.practice_time > 0
                        ? "bg-violet-100 dark:bg-violet-900/30 text-violet-700 dark:text-violet-400"
                        : "bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300"
                        }`}>
                        {drill.practice_time} mins
                      </span>
                      <span className="mt-1 text-xs text-gray-400 dark:text-gray-500">
                        {drill.last_viewed
                          ? `Last Viewed: ${drill.last_viewed.split(' ')[0]}`
                          : 'Last Viewed: Never'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
