import { useState, useContext, useEffect, useRef } from 'react';
import { AccountContext } from '../../stores/Account';
import { createImageFromInitials } from "../../components/LetterPicture";
import { capitalize, getInitial } from "../../utils/string";
import saveUserInfo from '../../api/saveUserInfo';
import TeacherRegistrationModal from '../components/TeacherRegistrationModal';
import saveTeacherInfo from '../../api/saveTeacherInfo';
import affiliateWithTeacher from '../../api/affiliateWithTeacher';
import disaffiliateWithTeacher from '../../api/disaffiliateWithTeacher';
import TeacherAffiliationModal from '../components/TeacherAffiliationModal';

const TeacherAffiliation = ({ userInfo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { user } = useContext(AccountContext);
  const [originalData, setOriginalData] = useState(null);
  const [formData, setFormData] = useState({
    business_name: userInfo?.teacher_info?.business_name || '',
    business_street_addr: userInfo?.teacher_info?.business_street_addr || '',
    business_city: userInfo?.teacher_info?.business_city || '',
    business_state: userInfo?.teacher_info?.business_state || '',
    business_country: userInfo?.teacher_info?.business_country || '',
    business_zipcode: userInfo?.teacher_info?.business_zipcode || '',
    studio_size: userInfo?.teacher_info?.studio_size || '',
    hourly_rate: userInfo?.teacher_info?.hourly_rate || '',
    lesson_types: userInfo?.teacher_info?.lesson_types || '',
    subito_use: userInfo?.teacher_info?.subito_use || ''
  });

  const handleStartEditing = () => {
    setOriginalData({ ...formData });
    setIsEditing(true);
  };

  const handleCancel = () => {
    setFormData(originalData);
    setIsEditing(false);
  };

  const handleUpdateTeacherInfo = async () => {
    setIsUpdating(true);
    try {
      await saveTeacherInfo(user, formData);
      window.location.reload();
    } catch (error) {
      console.error('Failed to update teacher info:', error);
    } finally {
      setIsEditing(false);
      setOriginalData(null);
      setIsUpdating(false);
    }
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(userInfo.teacher_info.teacher_code);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  useEffect(() => {
    const teacherInfo = userInfo?.teacher_info || {};
    setFormData(teacherInfo);
    setOriginalData(teacherInfo);
  }, [userInfo]);


  const [teacherCode, setTeacherCode] = useState('');

  const handleAffiliate = async () => {
    try {
      const res = await affiliateWithTeacher(user, teacherCode);
      console.log(res);
    } catch (err) {
      console.error('Failed to affiliate with teacher:', err);
    }
  };

  const handleDisaffiliate = async () => {
    try {
      await disaffiliateWithTeacher(user);
      window.location.reload();
    } catch (err) {
      console.error('Failed to disaffiliate from teacher:', err);
    }
  };

  // If user is a teacher, show teacher info
  if (userInfo?.role === 'teacher' && userInfo?.teacher_info) {
    return (
      <div className="space-y-4 w-full">
        <div className="flex items-center justify-between">
          <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
            Teacher Information
          </h4>
          <div className="flex space-x-3">
            {isEditing ? (
              <>
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateTeacherInfo}
                  disabled={isUpdating}
                  className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200 disabled:opacity-50"
                >
                  {isUpdating ? 'Saving...' : 'Save Changes'}
                </button>
              </>
            ) : (
              <button
                onClick={handleStartEditing}
                className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
              >
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
                Edit Teacher Info
              </button>
            )}
          </div>
        </div>

        <div className="flex items-center mb-4 max-w-xs">
          <div className="w-full">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">Teacher Code</p>
            <div className="relative w-full">
              <input
                id="teacher-code"
                type="text"
                className="bg-violet-50 border border-violet-200 text-violet-600 font-medium text-base rounded-lg block w-full px-3 py-2 dark:bg-violet-900/20 dark:border-violet-800 dark:text-violet-400"
                value={userInfo.teacher_info.teacher_code}
                disabled
                readOnly
              />
              <button
                onClick={handleCopyCode}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className="absolute end-2 top-[calc(50%)] -translate-y-1/2 text-violet-600 dark:text-violet-400 hover:bg-violet-100 dark:hover:bg-violet-900/30 rounded-lg p-1.5 inline-flex items-center justify-center transition-all duration-200"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  {!showCopied ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    />
                  )}
                </svg>
              </button>
              {showTooltip && (
                <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm -top-10 left-1/2 -translate-x-1/2 dark:bg-gray-700">
                  {showCopied ? 'Copied!' : 'Copy to clipboard'}
                  <div className="tooltip-arrow" />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Business Name</p>
            {isEditing ? (
              <input
                type="text"
                value={formData.business_name}
                onChange={(e) => setFormData({ ...formData, business_name: e.target.value })}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            ) : (
              <p className="text-lg font-medium text-gray-900 dark:text-white">
                {userInfo.teacher_info.business_name}
              </p>
            )}
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Studio Size</p>
            {isEditing ? (
              <input
                type="number"
                value={formData.studio_size}
                onChange={(e) => setFormData({ ...formData, studio_size: e.target.value })}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              />
            ) : (
              <p className="text-lg font-medium text-gray-900 dark:text-white">
                {userInfo.teacher_info.studio_size} students
              </p>
            )}
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Lesson Types</p>
            {isEditing ? (
              <select
                value={formData.lesson_types}
                onChange={(e) => setFormData({ ...formData, lesson_types: e.target.value })}
                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              >
                <option value="in_person">In Person Only</option>
                <option value="online">Online Only</option>
                <option value="hybrid">Hybrid (Both)</option>
              </select>
            ) : (
              <p className="text-lg font-medium text-gray-900 dark:text-white capitalize">
                {userInfo.teacher_info.lesson_types.replace('_', ' ')}
              </p>
            )}
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Hourly Rate</p>
            {isEditing ? (
              <div className="flex items-center">
                <span className="mr-2 text-lg">$</span>
                <input
                  type="number"
                  value={formData.hourly_rate}
                  onChange={(e) => setFormData({ ...formData, hourly_rate: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
                <span className="ml-2 text-lg">/hour</span>
              </div>
            ) : (
              <p className="text-lg font-medium text-gray-900 dark:text-white">
                ${userInfo.teacher_info.hourly_rate}/hour
              </p>
            )}
          </div>
        </div>

        <div>
          <p className="text-sm text-gray-600 dark:text-gray-400">Business Address</p>
          {isEditing ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div className="md:col-span-2">
                <input
                  type="text"
                  placeholder="Street Address"
                  value={formData.business_street_addr}
                  onChange={(e) => setFormData({ ...formData, business_street_addr: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="City"
                  value={formData.business_city}
                  onChange={(e) => setFormData({ ...formData, business_city: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="State"
                  value={formData.business_state}
                  onChange={(e) => setFormData({ ...formData, business_state: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Country"
                  value={formData.business_country}
                  onChange={(e) => setFormData({ ...formData, business_country: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Zip Code"
                  value={formData.business_zipcode}
                  onChange={(e) => setFormData({ ...formData, business_zipcode: e.target.value })}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                />
              </div>
            </div>
          ) : (
            <p className="text-lg font-medium text-gray-900 dark:text-white">
              {userInfo.teacher_info.business_street_addr}<br />
              {userInfo.teacher_info.business_city}, {userInfo.teacher_info.business_state} {userInfo.teacher_info.business_zipcode}<br />
              {userInfo.teacher_info.business_country}
            </p>
          )}
        </div>

        <div>
          <p className="text-sm text-gray-600 dark:text-gray-400">Subito Usage</p>
          {isEditing ? (
            <textarea
              value={formData.subito_use}
              onChange={(e) => setFormData({ ...formData, subito_use: e.target.value })}
              rows="3"
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            />
          ) : (
            <p className="text-lg font-medium text-gray-900 dark:text-white">
              {userInfo.teacher_info.subito_use}
            </p>
          )}
        </div>
      </div>
    );
  }

  console.log('userInfo', userInfo);
  // If user is a student, show the original teacher affiliation UI
  return (
    <div className="max-w-md">
      {userInfo?.role !== 'teacher' && (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col md:flex-row md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex-1">
              {userInfo?.teacher_name ? (
                <div className="flex items-center justify-between bg-white dark:bg-gray-800 rounded-lg shadow-sm">
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {userInfo?.pending_confirmation ? 'Waiting for Teacher Confirmation' : 'Current Teacher'}
                    </p>
                    <p className="font-medium text-gray-900 dark:text-white">{userInfo?.teacher_name}</p>
                  </div>
                  <button
                    onClick={handleDisaffiliate}
                    className="whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 dark:bg-red-900/20 dark:text-red-400 dark:hover:bg-red-900/30 transition-all duration-200"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    Leave Studio
                  </button>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <TeacherAffiliationModal
                    setDisabled={() => { }}
                    button_view={
                      <button className="whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                        </svg>
                        Affiliate with Teacher
                      </button>
                    }
                  />

                  <TeacherRegistrationModal
                    setDisabled={() => { }}
                    button_view={
                      <button className="whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200">
                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                        </svg>
                        Register as Teacher
                      </button>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


const Settings = () => {
  const { user, userInfo } = useContext(AccountContext);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    first_name: userInfo?.first_name || '',
    last_name: userInfo?.last_name || '',
    instrument: userInfo?.instrument || 'piano',
    profile_pic: userInfo?.profile_pic || '',
    student_level: userInfo?.student_level || '',
    street_addr: userInfo?.street_addr || '',
    city_addr: userInfo?.city_addr || '',
    state_addr: userInfo?.state_addr || '',
    country_addr: userInfo?.country_addr || '',
    zipcode_addr: userInfo?.zipcode_addr || '',
    ts_stud_id: userInfo?.ts_stud_id || '',
    ts_flag: userInfo?.ts_flag || 0
  });

  useEffect(() => {
    setFormData(userInfo);
  }, [userInfo]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const res = await saveUserInfo(user, {
        ...formData,
        profile_pic: selectedImage || formData.profile_pic
      });
      console.log('save user info response', res);
      window.location.reload();
    } catch (err) {
      setError(err.message || 'Failed to update profile');
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getProfilePicture = () => {
    if (selectedImage) {
      return selectedImage;
    }
    if (userInfo?.profile_pic) {
      return userInfo.profile_pic;
    }
    return createImageFromInitials(
      200,
      getInitial(userInfo?.first_name + ' ' + userInfo?.last_name),
      "#7c3aed"
    );
  };


  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-md">
      <div className="p-8">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold text-gray-900 dark:text-white">
            Account Information
          </h3>
          <div className="flex space-x-3">
            {isEditing ? (
              <>
                <button
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  disabled={isLoading}
                  className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200 disabled:opacity-50"
                >
                  {isLoading ? 'Saving...' : 'Save Changes'}
                </button>
              </>
            ) : (
              <button
                onClick={() => setIsEditing(true)}
                className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
              >
                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
                Edit Profile
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-8 w-full">
          {/* Left side - User info and details */}
          <div className="flex-1 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">First Name</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.first_name}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                ) : (
                  <p className="text-lg font-medium text-gray-900 dark:text-white">
                    {formData.first_name}
                  </p>
                )}
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Last Name</p>
                {isEditing ? (
                  <input
                    type="text"
                    value={formData.last_name}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                ) : (
                  <p className="text-lg font-medium text-gray-900 dark:text-white">
                    {formData.last_name}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Instrument</p>
                {isEditing ? (
                  <select
                    value={formData.instrument}
                    onChange={(e) => setFormData({ ...formData, instrument: e.target.value })}
                    className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="piano">Piano</option>
                  </select>
                ) : (
                  <p className="text-lg font-medium text-gray-900 dark:text-white">
                    {formData.instrument.charAt(0).toUpperCase() + formData.instrument.slice(1)}
                  </p>
                )}
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Age Level</p>
                {isEditing ? (
                  <select
                    value={formData.student_level}
                    onChange={(e) => setFormData({ ...formData, student_level: e.target.value })}
                    className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="">Select Age Level</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                  </select>
                ) : (
                  <p className="text-lg font-medium text-gray-900 dark:text-white">
                    {formData.student_level ? formData.student_level.charAt(0).toUpperCase() + formData.student_level.slice(1) : "(Not specified)"}
                  </p>
                )}
              </div>
            </div>

            <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                Location
              </h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">Street Address</p>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.street_addr}
                      onChange={(e) => setFormData({ ...formData, street_addr: e.target.value })}
                      className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter street address"
                    />
                  ) : (
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {formData.street_addr || "(No address)"}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">City</p>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.city_addr}
                      onChange={(e) => setFormData({ ...formData, city_addr: e.target.value })}
                      className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter city"
                    />
                  ) : (
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {formData.city_addr || "(No city)"}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">State/Province</p>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.state_addr}
                      onChange={(e) => setFormData({ ...formData, state_addr: e.target.value })}
                      className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter state"
                    />
                  ) : (
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {formData.state_addr || "(No state)"}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">Country</p>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.country_addr}
                      onChange={(e) => setFormData({ ...formData, country_addr: e.target.value })}
                      className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter country"
                    />
                  ) : (
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {formData.country_addr || "(No country)"}
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">ZIP/Postal Code</p>
                  {isEditing ? (
                    <input
                      type="text"
                      value={formData.zipcode_addr}
                      onChange={(e) => setFormData({ ...formData, zipcode_addr: e.target.value })}
                      className="w-full px-3 py-2 text-lg border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter ZIP code"
                    />
                  ) : (
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {formData.zipcode_addr || "(No ZIP code)"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Profile picture */}
          <div className="lg:w-1/3 flex flex-col items-center">
            <div className="relative group">
              <img
                className="w-48 h-48 rounded-full ring-4 ring-violet-600/30 object-cover shadow-lg"
                src={getProfilePicture()}
                alt={`${userInfo?.first_name} ${userInfo?.last_name}`}
              />
              {isEditing && (
                <div
                  className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => fileInputRef.current?.click()}
                >
                  <div className="text-white text-center">
                    <svg className="w-8 h-8 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <span className="text-sm font-medium">Change Photo</span>
                  </div>
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                className="hidden"
              />
            </div>
            <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
              Member since {new Date(userInfo.date_joined).toLocaleDateString()}
            </p>
            <span className="mt-2 text-sm font-medium px-3 py-1 rounded-full bg-violet-100 text-violet-800 dark:bg-violet-900/30 dark:text-violet-300">
              {capitalize(userInfo.role) || "Student"}
            </span>
          </div>
        </div>
        <div className="pt-4 mt-4 border-t border-gray-200 dark:border-gray-700 w-full">
          <TeacherAffiliation userInfo={userInfo} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
