import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import Modal from '../../components/Modal';
import { Label } from '../../components/Input';
import QueryPiece from '../components/QueryPiece';
import createDrill from '../../api/createDrill';
import { AccountContext } from '../../stores/Account';
import Select from 'react-select';
import FlatEmbed from '../components/FlatEmbed';
import ChatInterface from '../components/ChatInterface';
import { useSearchParams } from 'react-router-dom';
import { SystemContext } from '../../stores/System';
import drillGenerate from '../../api/drillGenerate';
import useClickOutside from '../../hooks/useClickOutside';
import debounce from 'lodash/debounce';

const rhythmOptions = [
  { value: '1', label: 'Whole (1)' },
  { value: '1/2', label: 'Half (1/2)' },
  { value: '1/2.', label: 'Dotted Half (1/2.)' },
  { value: '1/4', label: 'Quarter (1/4)' },
  { value: '1/4.', label: 'Dotted Quarter (1/4.)' },
  { value: '1/8', label: 'Eighth (1/8)' },
  { value: '1/8.', label: 'Dotted Eighth (1/8.)' },
  { value: '1/16', label: 'Sixteenth (1/16)' },
  { value: '1/32', label: 'Thirty-Second (1/32)' }
];

const pitchOptions = [
  { value: 'C', label: 'C' },
  { value: 'C#', label: 'C♯' },
  { value: 'D', label: 'D' },
  { value: 'D#', label: 'D♯' },
  { value: 'E', label: 'E' },
  { value: 'F', label: 'F' },
  { value: 'F#', label: 'F♯' },
  { value: 'G', label: 'G' },
  { value: 'G#', label: 'G♯' },
  { value: 'A', label: 'A' },
  { value: 'A#', label: 'A♯' },
  { value: 'B', label: 'B' }
];

const customSelectStyles = (darkMode) => ({
  control: (base, state) => ({
    ...base,
    borderRadius: '0.5rem',
    borderColor: darkMode ? 'rgb(75 85 99)' : 'rgb(209 213 219)',
    backgroundColor: darkMode ? '#33363A' : 'white',
    '&:hover': {
      borderColor: 'rgb(139 92 246)'
    },
    boxShadow: state.isFocused ? '0 0 0 1px rgb(139 92 246)' : 'none',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: darkMode ? '#33363A' : 'white',
    border: darkMode ? '1px solid rgb(75 85 99)' : '1px solid rgb(209 213 219)',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: darkMode ? 'rgb(76 29 149)' : 'rgb(245 243 255)',
    borderRadius: '9999px',
    padding: '2px 4px',
    '& > div': {
      color: darkMode ? 'rgb(221 214 254)' : 'rgb(124 58 237)'
    }
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: darkMode ? 'rgb(221 214 254)' : 'rgb(124 58 237)',
    ':hover': {
      backgroundColor: darkMode ? 'rgb(91 33 182)' : 'rgb(237 233 254)',
      color: darkMode ? 'rgb(237 233 254)' : 'rgb(109 40 217)'
    },
    borderRadius: '9999px'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? darkMode
        ? 'rgb(109 40 217)'
        : 'rgb(139 92 246)'
      : state.isFocused
        ? darkMode
          ? 'rgb(76 29 149)'
          : 'rgb(237 233 254)'
        : base.backgroundColor,
    color: darkMode ? 'rgb(229 231 235)' : 'rgb(17 24 39)',
    '&:hover': {
      backgroundColor: state.isSelected
        ? darkMode
          ? 'rgb(109 40 217)'
          : 'rgb(139 92 246)'
        : darkMode
          ? 'rgb(76 29 149)'
          : 'rgb(237 233 254)'
    }
  }),
  input: (base) => ({
    ...base,
    color: darkMode ? 'rgb(229 231 235)' : 'rgb(17 24 39)'
  }),
  singleValue: (base) => ({
    ...base,
    color: darkMode ? 'rgb(229 231 235)' : 'rgb(17 24 39)'
  }),
  placeholder: (base) => ({
    ...base,
    color: darkMode ? 'rgb(156 163 175)' : 'rgb(107 114 128)'
  }),
  clearIndicator: (base) => ({
    ...base,
    color: darkMode ? 'rgb(156 163 175)' : 'rgb(107 114 128)',
    '&:hover': {
      color: darkMode ? 'rgb(229 231 235)' : 'rgb(17 24 39)'
    }
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: darkMode ? 'rgb(156 163 175)' : 'rgb(107 114 128)',
    '&:hover': {
      color: darkMode ? 'rgb(229 231 235)' : 'rgb(17 24 39)'
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: darkMode ? 'rgb(75 85 99)' : 'rgb(209 213 219)'
  })
});

const VoiceRow = ({
  staffId,
  voiceIndex,
  voice,
  onDelete,
  onRhythmChange,
  onPitchPatternChange,
  onCustomPitchChange,
  onRepeatRhythmChange,
  onOctaveChange,
  interval_mapper,
  selectStyles
}) => {
  const selectedRhythms = voice.rhythmPattern.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    }));

  const selectedPitches = voice.customPitchPattern?.split(',')
    .map(p => p.trim())
    .filter(p => p)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: pitchOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  return (
    <div className="mb-4 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          Voice {voiceIndex + 1}
        </h4>
        <button
          onClick={() => onDelete(staffId, voiceIndex)}
          className="p-1 text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-colors duration-200"
          title="Delete voice"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      </div>
      <div className="space-y-3">
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Rhythm Pattern
            </label>
          </div>
          <div className="space-y-2">
            <Select
              isMulti
              value={selectedRhythms}
              options={rhythmOptions.map(opt => ({
                ...opt,
                value: `${opt.value}-${selectedRhythms.length}`,
                actualValue: opt.value
              }))}
              onChange={(selected) => {
                const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                onRhythmChange(staffId, voiceIndex, rhythmPattern);
              }}
              styles={selectStyles}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select rhythm values..."
            />
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Note that across all voices the rhythm pattern must be the same beat length.
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Pitch Pattern
            </label>
          </div>
          <div className="space-y-2">
            <select
              value={voice.pitchPatternType}
              onChange={(e) => onPitchPatternChange(staffId, voiceIndex, e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="Scale">Scale</option>
              <option value="Arpeggio">Arpeggio</option>
              <option value="Interval">Interval</option>
              <option value="Custom">Custom</option>
            </select>

            {voice.pitchPatternType === 'Interval' && (
              <select
                value={voice.selectedInterval}
                onChange={(e) => onPitchPatternChange(staffId, voiceIndex, 'Interval', e.target.value)}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {Object.entries(interval_mapper).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            )}

            {voice.pitchPatternType === 'Custom' && (
              <div className="space-y-2">
                <Select
                  isMulti
                  value={selectedPitches}
                  options={pitchOptions.map(opt => ({
                    ...opt,
                    value: `${opt.value}-${selectedPitches.length}`,
                    actualValue: opt.value
                  }))}
                  onChange={(selected) => {
                    const pitchPattern = selected.map(s => s.actualValue).join(', ');
                    onCustomPitchChange(staffId, voiceIndex, pitchPattern);
                  }}
                  styles={selectStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select notes..."
                />
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  Select the notes in order. The accidentals will not be adjusted based on the key signature.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="flex-1">
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Bottom Octave
            </label>
            <select
              value={voice.bottomOctave}
              onChange={(e) => onOctaveChange(staffId, voiceIndex, 'bottom', e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <option
                  key={num}
                  value={num}
                  disabled={num > voice.topOctave}
                >
                  {num}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Top Octave
            </label>
            <select
              value={voice.topOctave}
              onChange={(e) => onOctaveChange(staffId, voiceIndex, 'top', e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                <option
                  key={num}
                  value={num}
                  disabled={num < voice.bottomOctave}
                >
                  {num}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center">
          <input
            id={`repeat-rhythm-${staffId}-${voiceIndex}`}
            type="checkbox"
            checked={voice.repeatRhythm}
            onChange={(e) => onRepeatRhythmChange(staffId, voiceIndex, e.target.checked)}
            className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor={`repeat-rhythm-${staffId}-${voiceIndex}`}
            className="ml-2 text-xs text-gray-600 dark:text-gray-400"
          >
            Repeat rhythm on each pitch?
          </label>
        </div>
      </div>
    </div>
  );
};

const ModificationRow = ({
  modification,
  index,
  onDelete,
  onTypeChange,
  onRhythmSwitchChange,
  onKeyChangeSelect,
  onTransposeIntervalChange,
  onTransposeDirectionChange,
  keySignatures,
  interval_mapper,
  selectStyles
}) => {
  const selectedOriginalRhythms = modification.originalRhythm?.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  const selectedNewRhythms = modification.newRhythm?.split(',')
    .map(r => r.trim())
    .filter(r => r)
    .map((value, index) => ({
      value: `${value}-${index}`,
      label: rhythmOptions.find(opt => opt.value === value)?.label || value,
      actualValue: value
    })) || [];

  return (
    <div className="mb-4 last:mb-0">
      <div className="flex items-center justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
          Modification {index + 1}
        </h4>
        <button
          onClick={() => onDelete(index)}
          type="button"
          className="p-1 text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-colors duration-200"
          title="Delete modification"
        >
          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
        </button>
      </div>
      <div className="space-y-3">
        <div>
          <div className="flex items-center justify-between mb-1">
            <label className="text-xs text-gray-500 dark:text-gray-400">
              Type
            </label>
          </div>
          <select
            value={modification.type}
            onChange={(e) => onTypeChange(index, e.target.value)}
            className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="rhythm_switch">Rhythm switch</option>
            <option value="key_change">Key change</option>
            <option value="transpose">Transpose</option>
            {/*<option value="interval_switch">Interval switch</option>*/}
          </select>
        </div>

        {modification.type === 'rhythm_switch' && (
          <div className="space-y-3">
            <div>
              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                Original Rhythm
              </label>
              <Select
                isMulti
                value={selectedOriginalRhythms}
                options={rhythmOptions.map(opt => ({
                  ...opt,
                  value: `${opt.value}-${selectedOriginalRhythms.length}`,
                  actualValue: opt.value
                }))}
                onChange={(selected) => {
                  const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                  onRhythmSwitchChange(index, 'originalRhythm', rhythmPattern);
                }}
                styles={selectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select rhythm values..."
              />
            </div>
            <div>
              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                New Rhythm
              </label>
              <Select
                isMulti
                value={selectedNewRhythms}
                options={rhythmOptions.map(opt => ({
                  ...opt,
                  value: `${opt.value}-${selectedNewRhythms.length}`,
                  actualValue: opt.value
                }))}
                onChange={(selected) => {
                  const rhythmPattern = selected.map(s => s.actualValue).join(', ');
                  onRhythmSwitchChange(index, 'newRhythm', rhythmPattern);
                }}
                styles={selectStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select rhythm values..."
              />
            </div>
          </div>
        )}

        {modification.type === 'key_change' && (
          <div>
            <div className="flex items-center justify-between mb-1">
              <label className="text-xs text-gray-500 dark:text-gray-400">
                Key to Change To
              </label>
            </div>
            <select
              value={modification.targetKey}
              onChange={(e) => onKeyChangeSelect(index, e.target.value)}
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              {keySignatures.map((key) => (
                <option key={key} value={key}>
                  {key.replace('_', ' ')}
                </option>
              ))}
            </select>
          </div>
        )}

        {modification.type === 'transpose' && (
          <div className="space-y-3">
            <div>
              <div className="flex items-center justify-between mb-1">
                <label className="text-xs text-gray-500 dark:text-gray-400">
                  Interval
                </label>
              </div>
              <select
                value={modification.transposeInterval}
                onChange={(e) => onTransposeIntervalChange(index, e.target.value)}
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              >
                {Object.entries(interval_mapper).map(([key, label]) => (
                  <option key={key} value={key}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <input
                id={`transpose-direction-${index}`}
                type="checkbox"
                checked={modification.transposeDirection === 'up'}
                onChange={(e) => onTransposeDirectionChange(index, e.target.checked ? 'up' : 'down')}
                className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor={`transpose-direction-${index}`}
                className="ml-2 text-xs text-gray-600 dark:text-gray-400"
              >
                Transpose down
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function CreateDrill(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [drillName, setDrillName] = useState('');
  const [drillType, setDrillType] = useState('from_scratch');
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [keySignature, setKeySignature] = useState('C_major');
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [excerptType, setExcerptType] = useState('full');
  const [startMeasure, setStartMeasure] = useState(1);
  const [endMeasure, setEndMeasure] = useState(1);
  const [tempStartMeasure, setTempStartMeasure] = useState('1');
  const [tempEndMeasure, setTempEndMeasure] = useState('1');
  const [isModificationsExpanded, setIsModificationsExpanded] = useState(true);
  const [modifications, setModifications] = useState([]);
  const [isVariation, setIsVariation] = useState(false);
  const [voices, setVoices] = useState({
    staff1: [],
    staff2: []
  });
  const [expandedStaffs, setExpandedStaffs] = useState({
    staff1: true,
    staff2: true
  });
  const [letAllVoicesFinish, setLetAllVoicesFinish] = useState(false);
  const [activeTab, setActiveTab] = useState('natural');  // 'natural' or 'details'
  const [hasUnseenChanges, setHasUnseenChanges] = useState(false);
  const [lastJson, setLastJson] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);

  const [previewFlatSharingKey, setPreviewFlatSharingKey] = useState(null);
  const [previewFlatScoreId, setPreviewFlatScoreId] = useState(null);

  const MAX_VOICES_PER_STAFF = 5;

  const keySignatures = [
    'C_major', 'D_major', 'E_major', 'F_major', 'G_major', 'A_major', 'B_major', 'C#_major',
    'D#_major', 'F#_major', 'G#_major', 'A#_major', 'C_minor', 'D_minor', 'E_minor', 'F_minor',
    'G_minor', 'A_minor', 'B_minor', 'C#_minor', 'D#_minor', 'F#_minor', 'G#_minor', 'A#_minor'
  ];

  const interval_mapper = {
    'P1': 'Perfect Unison',
    'm2': 'Minor Second',
    'M2': 'Major Second',
    'm3': 'Minor Third',
    'M3': 'Major Third',
    'P4': 'Perfect Fourth',
    'A4': 'Augmented Fourth',
    'd5': 'Diminished Fifth',
    'P5': 'Perfect Fifth',
    'm6': 'Minor Sixth',
    'M6': 'Major Sixth',
    'm7': 'Minor Seventh',
    'M7': 'Major Seventh',
    'P8': 'Perfect Octave'
  };

  const { darkMode } = useContext(SystemContext);
  const { user } = useContext(AccountContext);

  const [searchParams] = useSearchParams();
  const initialPrompt = searchParams.get('prompt');

  const [previewId, setPreviewId] = useState(null);

  // Handle initial prompt when component mounts
  useEffect(() => {
    if (initialPrompt) {
      // Add user message
      setChatMessages([{ role: 'user', content: initialPrompt }]);

      // Trigger the chat submission
      handleChatSubmit(initialPrompt);
    }
  }, []);  // Empty dependency array so it only runs once

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true);
    if (previewId) {
      try {
        await createDrill(user, drillName, previewId, "", 0);
        window.location.href = `/dashboard/exercises`;
      } catch (error) {
        console.error('Error creating drill:', error);
      } finally {
        setIsCreating(false);
      }
    } else {
      try {
        const creationJson = build_creation_json();
        await createDrill(user, drillName, selectedPiece?.id, JSON.stringify(JSON.stringify(creationJson)).slice(1, -1), 0);
        window.location.href = `/dashboard/exercises`;
      } catch (error) {
        console.error('Error creating drill:', error);
      } finally {
        setIsCreating(false);
      }
    };
  }

  const build_creation_json = () => {
    if (drillType === 'from_scratch') {
      // Convert voice data into the required format
      const convertVoices = (staffVoices) => {
        return staffVoices.map(voice => {
          const voiceObj = {
            rhythm: voice.rhythmPattern.split(',').map(r => r.trim()),
            repeat_rhythm_on_each_pitch: voice.repeatRhythm,
            octave_interval: [voice.bottomOctave, voice.topOctave]
          };

          // Handle different pitch pattern types
          if (voice.pitchPatternType === 'Scale') {
            voiceObj.pitch_pattern = 'scale';
          } else if (voice.pitchPatternType === 'Arpeggio') {
            voiceObj.pitch_pattern = 'arpeggio';
          } else if (voice.pitchPatternType === 'Interval') {
            voiceObj.pitch_pattern = 'interval';
            voiceObj.interval = voice.selectedInterval;
          } else if (voice.pitchPatternType === 'Custom') {
            voiceObj.pitch_pattern = voice.customPitchPattern.split(',').map(p => p.trim());
          }

          return voiceObj;
        });
      };

      return {
        base: 'free',
        is_variation: isVariation ? 1 : 0,
        key: keySignature,
        let_all_finish: letAllVoicesFinish,
        staffs: [
          convertVoices(voices.staff1),
          convertVoices(voices.staff2)
        ]
      };
    } else {
      // Handle from_piece case
      const json = {
        base: 'piece',
        is_variation: isVariation ? 1 : 0,
        key: keySignature,
        seq: excerptType,
        excerpt_bounds: excerptType === 'excerpt' ? [startMeasure, endMeasure] : null,
        focus: [],
        subdiv_args: { original: [], new: [] },
        subdiv_note_settings: 'repeat',  // Default to repeat
        new_key_to_change_to: '',
        transpose_dir: 1,  // Default to up
        interval_switch_args: []
      };

      // Process modifications
      modifications.forEach(mod => {
        switch (mod.type) {
          case 'rhythm_switch':
            if (!json.focus.includes('subdiv')) {
              json.focus.push('subdiv');
            }
            json.subdiv_args.original.push(mod.originalRhythm.split(',').map(r => r.trim()));
            json.subdiv_args.new.push(mod.newRhythm.split(',').map(r => r.trim()));
            break;

          case 'key_change':
            if (!json.focus.includes('change_key')) {
              json.focus.push('change_key');
            }
            json.new_key_to_change_to = mod.targetKey;
            break;

          case 'transpose':
            if (!json.focus.includes('transpose')) {
              json.focus.push('transpose');
            }
            json.transpose_dir = mod.transposeDirection === 'up' ? 1 : -1;
            json.transpose = mod.transposeInterval;
            break;

          case 'interval_switch':
            if (!json.focus.includes('switch_interval')) {
              json.focus.push('switch_interval');
            }
            // Handle interval switch args if needed
            break;
        }
      });

      // If excerpt_bounds is null, set it to indicate full piece
      if (!json.excerpt_bounds) {
        json.excerpt_bounds = [1, -1];
      }

      return json;
    }
  };

  useEffect(() => {
    console.log(build_creation_json());
  }, [voices, modifications]);

  const handleAddVoice = (staff) => {
    if (voices[staff].length >= MAX_VOICES_PER_STAFF) {
      return;
    }
    setVoices(prev => ({
      ...prev,
      [staff]: [...prev[staff], {
        rhythmPattern: '',
        pitchPattern: '',
        pitchPatternType: 'Scale',
        selectedInterval: 'P1',
        repeatRhythm: false,
        bottomOctave: 3,
        topOctave: 4
      }]
    }));
  };

  const handleRhythmPatternChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            rhythmPattern: value
          };
        }
        return voice;
      })
    }));
  };

  const handleDeleteVoice = (staffId, voiceIndex) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].filter((_, idx) => idx !== voiceIndex)
    }));
  };

  const handleRepeatRhythmChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) =>
        idx === voiceIndex ? { ...voice, repeatRhythm: value } : voice
      )
    }));
  };

  const handleOctaveChange = (staffId, voiceIndex, type, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          if (type === 'bottom') {
            return {
              ...voice,
              bottomOctave: parseInt(value),
              topOctave: Math.max(parseInt(value), voice.topOctave)
            };
          } else {
            return {
              ...voice,
              topOctave: parseInt(value),
              bottomOctave: Math.min(parseInt(value), voice.bottomOctave)
            };
          }
        }
        return voice;
      })
    }));
  };

  const handlePitchPatternChange = (staffId, voiceIndex, type, interval = null) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            pitchPatternType: type,
            selectedInterval: interval || voice.selectedInterval,
            customPitchPattern: voice.customPitchPattern || ''
          };
        }
        return voice;
      })
    }));
  };

  const handleCustomPitchPatternChange = (staffId, voiceIndex, value) => {
    setVoices(prev => ({
      ...prev,
      [staffId]: prev[staffId].map((voice, idx) => {
        if (idx === voiceIndex) {
          return {
            ...voice,
            customPitchPattern: value
          };
        }
        return voice;
      })
    }));
  };

  const validateMeasures = useCallback(
    debounce((start, end) => {
      const startNum = parseInt(start);
      const endNum = parseInt(end);

      if (!isNaN(startNum)) {
        const validStart = Math.max(1, startNum);
        setStartMeasure(validStart);
        setTempStartMeasure(validStart.toString());

        if (!isNaN(endNum)) {
          const validEnd = Math.max(validStart, endNum);
          setEndMeasure(validEnd);
          setTempEndMeasure(validEnd.toString());
        }
      }
    }, 500),
    []
  );

  const handleStartMeasureChange = (e) => {
    const value = e.target.value;
    setTempStartMeasure(value);
    validateMeasures(value, tempEndMeasure);
  };

  const handleEndMeasureChange = (e) => {
    const value = e.target.value;
    setTempEndMeasure(value);
    validateMeasures(tempStartMeasure, value);
  };

  const handleAddModification = () => {
    setModifications(prev => [...prev, {
      type: 'rhythm_switch',
      originalRhythm: '',
      newRhythm: '',
      targetKey: 'C_major',
      transposeInterval: 'P1',
      transposeDirection: 'up'
    }]);
  };

  const handleRhythmSwitchChange = (index, field, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, [field]: value } : mod
    ));
  };

  const handleModificationTypeChange = (index, type) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? {
        ...mod,
        type,
        targetKey: type === 'key_change' ? 'C_major' : mod.targetKey,
        transposeInterval: type === 'transpose' ? 'P1' : mod.transposeInterval
      } : mod
    ));
  };

  const handleTransposeIntervalChange = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, transposeInterval: value } : mod
    ));
  };

  const handleTransposeDirectionChange = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, transposeDirection: value } : mod
    ));
  };

  const handleKeyChangeSelect = (index, value) => {
    setModifications(prev => prev.map((mod, idx) =>
      idx === index ? { ...mod, targetKey: value } : mod
    ));
  };

  const handleDeleteModification = (index) => {
    setModifications(prev => prev.filter((_, idx) => idx !== index));
  };

  const handleUpdatePreview = async (e) => {
    // Implementation of handleUpdatePreview function
    e.preventDefault();
    setIsPreviewLoading(true);
    try {
      const creationJson = build_creation_json();
      const response = await createDrill(user, drillName, selectedPiece?.id, JSON.stringify(JSON.stringify(creationJson)).slice(1, -1), 1);
      setPreviewId(response.id);
      setPreviewFlatSharingKey(response.flat_sharing_key);
      setPreviewFlatScoreId(response.flat_score_id);
    } catch (error) {
      console.error('Error creating drill:', error);
    } finally {
      setIsPreviewLoading(false);
    }
  };

  const handleChatResponse = (response) => {
    if (response.output_json) {
      // Update key signature
      if (response.output_json.key) {
        setKeySignature(response.output_json.key);
      }

      // Update let all voices finish
      if (response.output_json.let_all_finish !== undefined) {
        setLetAllVoicesFinish(response.output_json.let_all_finish);
      }

      // Update voices
      if (response.output_json.staffs) {
        const newVoices = {
          staff1: [],
          staff2: []
        };

        response.output_json.staffs.forEach((staff, staffIndex) => {
          const staffKey = `staff${staffIndex + 1}`;
          staff.forEach(voice => {
            newVoices[staffKey].push({
              rhythmPattern: voice.rhythm.join(', '),
              pitchPatternType: voice.pitch_pattern.charAt(0).toUpperCase() + voice.pitch_pattern.slice(1),
              repeatRhythm: voice.repeat_rhythm_on_each_pitch,
              bottomOctave: voice.octave_interval[0],
              topOctave: voice.octave_interval[1],
              selectedInterval: voice.interval ? voice.interval.toString() : 'P1',
              customPitchPattern: '' // Reset custom pitch pattern as it's not in the output
            });
          });
        });

        setVoices(newVoices);
      }

      // Set unseen changes flag if we're not on the details tab
      if (activeTab === 'natural') {
        setHasUnseenChanges(true);
      }
    }
  };

  const handleChatSubmit = async (prompt) => {
    try {
      const lastJson = JSON.stringify(JSON.stringify(build_creation_json())).slice(1, -1);
      const response = await drillGenerate(user, drillType, prompt, lastJson);
      console.log(response);
      handleChatResponse(response);
      return response;
    } catch (error) {
      console.error('Error generating drill:', error);
      throw error;
    }
  };

  const toggleStaff = (staff) => {
    setExpandedStaffs(prev => ({
      ...prev,
      [staff]: !prev[staff]
    }));
  };

  const typeDropdownRef = useClickOutside(() => setIsTypeDropdownOpen(false));

  return (
    <div className="flex flex-col h-full bg-white">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
        <div className="px-6 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Create Exercise
              </h1>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setIsVariation(!isVariation)}
                className={`
                  px-4 py-2 text-sm font-medium rounded-md
                  transition-all duration-300 ease-in-out
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500
                  ${isVariation
                    ? "bg-violet-600 text-white hover:bg-violet-700 active:bg-violet-800 dark:bg-violet-500 dark:hover:bg-violet-600 dark:active:bg-violet-700"
                    : "bg-violet-100 text-violet-700 hover:bg-violet-200 active:bg-violet-300 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                  }
                `}
              >
                <div className="flex items-center">
                  {isVariation ? (
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  ) : (
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                  {isVariation ? "Remix the Exercise" : "Don't Remix Exercise"}
                </div>
              </button>
              <button
                onClick={handleUpdatePreview}
                disabled={isPreviewLoading}
                className="px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isPreviewLoading ? (
                  <>
                    <svg className="inline w-4 h-4 mr-1.5 animate-spin" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <span className="whitespace-nowrap">Updating Preview...</span>
                  </>
                ) : (
                  <>
                    <svg className="w-4 h-4 mr-1.5 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <span className="whitespace-nowrap">Update Preview</span>
                  </>
                )}
              </button>
              <button
                onClick={handleSubmit}
                disabled={isCreating}
                className="px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isCreating ? (
                  <>
                    <svg className="inline w-4 h-4 mr-1.5 animate-spin" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <span className="whitespace-nowrap">Creating Exercise...</span>
                  </>
                ) : (
                  <>
                    <svg className="w-4 h-4 mr-1.5 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="whitespace-nowrap">Finish Creating</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-row overflow-hidden bg-white dark:bg-gray-800">
        {/* Right side - Configuration */}
        <div className="w-1/2 flex flex-col">
          {/* Common Form Inputs */}
          <div className="px-6 pt-4">
            <div className="flex items-end space-x-2">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Exercise Name
                </label>
                <input
                  type="text"
                  value={drillName}
                  onChange={(e) => setDrillName(e.target.value)}
                  className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Enter exercise name"
                />
              </div>
              <div className="flex-none relative flex flex-col" ref={typeDropdownRef}>
                <button
                  onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
                  className="inline-flex items-center justify-between w-64 p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="block">
                    <div className="w-full text-sm font-semibold text-left">
                      {drillType === 'from_scratch' ? 'From Scratch' : 'From Piece'}
                    </div>
                    <div className="w-full text-xs text-left">
                      {drillType === 'from_scratch'
                        ? 'Create a drill entirely from scratch'
                        : 'Create a drill from an existing piece'
                      }
                    </div>
                  </div>
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {isTypeDropdownOpen && (
                  <div className="absolute z-10 w-64 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700">
                    <div
                      onClick={() => {
                        setDrillType('from_scratch');
                        setIsTypeDropdownOpen(false);
                      }}
                      className={`p-2 cursor-pointer ${drillType === 'from_scratch'
                        ? 'text-violet-600 bg-violet-50 dark:bg-violet-900/20 dark:text-violet-400'
                        : 'text-gray-500 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700'
                        }`}
                    >
                      <div className="block text-left">
                        <div className="w-full text-sm font-semibold text-left">From Scratch</div>
                        <div className="w-full text-xs text-left">Create a drill entirely from scratch</div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setDrillType('from_piece');
                        setIsTypeDropdownOpen(false);
                      }}
                      className={`p-2 cursor-pointer ${drillType === 'from_piece'
                        ? 'text-violet-600 bg-violet-50 dark:bg-violet-900/20 dark:text-violet-400'
                        : 'text-gray-500 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700'
                        }`}
                    >
                      <div className="block text-left">
                        <div className="w-full text-sm font-semibold text-left">From Piece</div>
                        <div className="w-full text-xs text-left">Create a drill from an existing piece</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Fixed tabs for Natural Language vs Details */}
          <div className="px-6 pt-4">
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="flex">
                <button
                  onClick={() => setActiveTab('natural')}
                  className={`px-4 py-2 text-sm font-medium border-b-2 ${activeTab === 'natural'
                    ? 'border-violet-600 text-violet-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                >
                  Natural Language
                </button>
                <button
                  onClick={() => {
                    setActiveTab('details');
                    setHasUnseenChanges(false);
                  }}
                  className={`relative px-4 py-2 text-sm font-medium border-b-2 ${activeTab === 'details'
                    ? 'border-violet-600 text-violet-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                >
                  Details
                  {hasUnseenChanges && (
                    <span className="absolute top-1 right-1 w-2 h-2 bg-violet-600 rounded-full" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Scrollable content area with padding */}
          <div className="flex-1 overflow-y-auto px-6 no-scrollbar">
            {activeTab === 'natural' ? (
              <div className="pt-4 px-2 flex flex-col h-full">
                <ChatInterface
                  onSubmit={handleChatSubmit}
                  onResponse={handleChatResponse}
                  messages={chatMessages}
                  setMessages={setChatMessages}
                  initialPrompt={initialPrompt}
                />
              </div>
            ) : (
              <div className="space-y-4 py-4 pb-8">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Key Signature
                    </label>
                    <select
                      value={keySignature}
                      onChange={(e) => setKeySignature(e.target.value)}
                      className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    >
                      {keySignatures.map((key) => (
                        <option key={key} value={key}>
                          {key.replace('_', ' ')}
                        </option>
                      ))}
                    </select>
                  </div>

                  {drillType === 'from_piece' && (
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Select Piece
                        </label>
                        <QueryPiece
                          selectedPiece={selectedPiece}
                          ptype="musicxml"
                          setSelectedPiece={setSelectedPiece}
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                          Excerpt Type
                        </label>
                        <select
                          value={excerptType}
                          onChange={(e) => setExcerptType(e.target.value)}
                          className="w-full px-3 py-2 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                          <option value="full">Full Piece</option>
                          <option value="excerpt">Excerpt</option>
                        </select>
                      </div>

                      {excerptType === 'excerpt' && (
                        <div>
                          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                            Measure Range
                          </label>
                          <div className="flex items-center space-x-4">
                            <div className="flex-1">
                              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                                Start Measure
                              </label>
                              <input
                                type="number"
                                value={tempStartMeasure}
                                onChange={(e) => handleStartMeasureChange(e)}
                                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                              />
                            </div>
                            <div className="flex-1">
                              <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
                                End Measure
                              </label>
                              <input
                                type="number"
                                value={tempEndMeasure}
                                onChange={(e) => handleEndMeasureChange(e)}
                                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
                        <div
                          className="flex items-center justify-between p-4 cursor-pointer"
                          onClick={() => setIsModificationsExpanded(!isModificationsExpanded)}
                        >
                          <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                            Drill Modifications
                          </h3>
                          <div className="flex items-center">
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddModification();
                              }}
                              className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                            >
                              Add Modification
                            </button>
                            <svg
                              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${isModificationsExpanded ? 'transform rotate-180' : ''
                                }`}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        {isModificationsExpanded && (
                          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                            {modifications.length === 0 ? (
                              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                                No modifications added yet
                              </p>
                            ) : (
                              <div className="space-y-4">
                                {modifications.map((mod, index) => (
                                  <ModificationRow
                                    key={index}
                                    modification={mod}
                                    index={index}
                                    onDelete={handleDeleteModification}
                                    onTypeChange={handleModificationTypeChange}
                                    onRhythmSwitchChange={handleRhythmSwitchChange}
                                    onKeyChangeSelect={handleKeyChangeSelect}
                                    onTransposeIntervalChange={handleTransposeIntervalChange}
                                    onTransposeDirectionChange={handleTransposeDirectionChange}
                                    keySignatures={keySignatures}
                                    interval_mapper={interval_mapper}
                                    selectStyles={customSelectStyles(darkMode)}
                                  />
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {drillType === 'from_scratch' && (
                    <div className="space-y-4">
                      {/* Staff 1 */}
                      <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
                        <div
                          className="flex items-center justify-between p-4 cursor-pointer"
                          onClick={() => toggleStaff('staff1')}
                        >
                          <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                            Staff 1
                          </h3>
                          <div className="flex items-center">
                            {voices.staff1.length < MAX_VOICES_PER_STAFF && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddVoice('staff1');
                                }}
                                className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                              >
                                Add Voice
                              </button>
                            )}
                            <svg
                              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${expandedStaffs.staff1 ? 'transform rotate-180' : ''
                                }`}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        {expandedStaffs.staff1 && (
                          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                            {voices.staff1.map((voice, index) => (
                              <VoiceRow
                                key={index}
                                staffId="staff1"
                                voiceIndex={index}
                                voice={voice}
                                onDelete={handleDeleteVoice}
                                onRhythmChange={handleRhythmPatternChange}
                                onPitchPatternChange={handlePitchPatternChange}
                                onCustomPitchChange={handleCustomPitchPatternChange}
                                onRepeatRhythmChange={handleRepeatRhythmChange}
                                onOctaveChange={handleOctaveChange}
                                interval_mapper={interval_mapper}
                                selectStyles={customSelectStyles(darkMode)}
                              />
                            ))}
                            {voices.staff1.length === 0 && (
                              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                                No voices added yet
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Staff 2 */}
                      <div className="border border-gray-200 dark:border-gray-700 rounded-lg">
                        <div
                          className="flex items-center justify-between p-4 cursor-pointer"
                          onClick={() => toggleStaff('staff2')}
                        >
                          <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                            Staff 2
                          </h3>
                          <div className="flex items-center">
                            {voices.staff2.length < MAX_VOICES_PER_STAFF && (
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddVoice('staff2');
                                }}
                                className="mr-4 px-3 py-1 text-xs font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                              >
                                Add Voice
                              </button>
                            )}
                            <svg
                              className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${expandedStaffs.staff2 ? 'transform rotate-180' : ''
                                }`}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                            </svg>
                          </div>
                        </div>
                        {expandedStaffs.staff2 && (
                          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                            {voices.staff2.map((voice, index) => (
                              <VoiceRow
                                key={index}
                                staffId="staff2"
                                voiceIndex={index}
                                voice={voice}
                                onDelete={handleDeleteVoice}
                                onRhythmChange={handleRhythmPatternChange}
                                onPitchPatternChange={handlePitchPatternChange}
                                onCustomPitchChange={handleCustomPitchPatternChange}
                                onRepeatRhythmChange={handleRepeatRhythmChange}
                                onOctaveChange={handleOctaveChange}
                                interval_mapper={interval_mapper}
                                selectStyles={customSelectStyles(darkMode)}
                              />
                            ))}
                            {voices.staff2.length === 0 && (
                              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-2">
                                No voices added yet
                              </p>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="pt-2">
                        <div className="flex items-center">
                          <input
                            id="let-all-voices-finish"
                            type="checkbox"
                            checked={letAllVoicesFinish}
                            onChange={(e) => setLetAllVoicesFinish(e.target.checked)}
                            className="w-4 h-4 text-violet-600 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-violet-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="let-all-voices-finish"
                            className="ml-2 text-sm text-gray-700 dark:text-gray-300"
                          >
                            Let all voices finish
                          </label>
                        </div>
                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400 ml-6">
                          *By default, we will cut off all voices when the staff 1, voice 1 finishes
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Left side - Preview */}
        <div className="w-1/2 border-l border-gray-200 dark:border-gray-600">
          <div className="h-full p-6">
            {isPreviewLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-violet-600"></div>
              </div>
            ) : (
              previewFlatScoreId && previewFlatSharingKey ? (
                <FlatEmbed
                  scoreId={previewFlatScoreId}
                  sharingKey={previewFlatSharingKey}
                  height="600px"
                  width="100%"
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-full bg-gray-50 dark:bg-gray-900/50 rounded-xl border border-gray-200 dark:border-gray-700/50">
                  <div className="text-center p-8">
                    <svg
                      className="w-16 h-16 mx-auto text-gray-400 dark:text-gray-600 mb-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
                      />
                    </svg>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      No Preview Available
                    </h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Click "Update Preview" to see your exercise
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateDrill;