import API from ".";

const createDrill = (user, name, piece_id, creation_json, is_preview) => {

  let obj = {
    "piece_id": piece_id ? piece_id : "-",
    "drill_name": name,
    "creation_json": creation_json,
    "preview": is_preview
  }

  console.log(obj);

  return new API(user).postRequest('/drill/create', obj);
}

export default createDrill;   