import { useState, useEffect, useContext } from 'react'
import { motion } from 'framer-motion'

import { AccountContext } from '../../stores/Account'
import AdvancedSearch from '../components/AdvancedSearch'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import useStateParam from '../../hooks/useStateParam'
import { useSearchParams, useNavigate } from 'react-router-dom'
import searchMusic from '../../api/searchMusic'
import UploadPiece from '../components/UploadPiece'
import PieceCard from '../components/PieceCard'
import CreateList from '../components/CreateList'
// import getAlbums from '../../api/getAlbums'
//import KeyboardShortcuts from '../components/KeyboardShortcuts'
import Pagination from '../components/Pagination'
import MusicLibraryLoadingView from '../components/MusicLibraryLoadingView'
import RequestPiece from '../components/RequestPiece'
import Piece from './Piece'
import AddToList from '../components/AddToList'

let toggles = [{
  label: "All Music",
  id: "all",
  icon: <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
  </svg>
},
{
  label: "Recently Viewed",
  id: 'viewed',
  icon: <svg className="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
  </svg>
},
{
  label: "Starred Pieces",
  id: 'starred',
  icon: <svg className="w-4 h-4 me-2 text-yellow-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
  </svg>
},
{
  label: "My Uploads",
  id: 'my',
  icon: null
}]

// Create a SelectablePieceCard wrapper component
const SelectablePieceCard = ({ piece, isSelectable, isSelected, onSelect }) => {
  return (
    <div
      onClick={isSelectable ? onSelect : () => window.location.href = `/dashboard/music/piece/${piece.id}`}
      className={`relative cursor-pointer ${isSelected ? 'transform scale-[0.98]' : ''}`}
    >
      {isSelectable && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className="absolute top-2 left-2 z-10"
        >
          <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center transition-colors duration-200 ${isSelected
            ? 'bg-violet-500 border-violet-500 dark:bg-violet-400 dark:border-violet-400'
            : 'border-gray-300 dark:border-gray-600'
            }`}>
            {isSelected && (
              <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            )}
          </div>
        </motion.div>
      )}
      <PieceCard piece={piece} />
    </div>
  );
};

function Music() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSightreading, setIsSightreading] = useState(false);
  const [currentPieceIndex, setCurrentPieceIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const [firstRender, setFirstRender] = useState(true);

  const resultsPerPage = 10
  // const [albums, setAlbums] = useState([]);

  const [totalResults, setTotalResults] = useState(0);

  const { user } = useContext(AccountContext);

  const [searchWord, setSearchWord] = useStateParam('search', undefined);

  const [toggle, setToggle] = useStateParam('pieces', ["all", "viewed", "starred", "my"], "all"); // ...albums.map(album => album.id)
  const [page, setPage] = useStateParam('page', "int", 1);

  const [isSearching, setIsSearching] = useState(false);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [selectedPieces, setSelectedPieces] = useState([]);

  const handleUnshuffle = () => {
    searchParams.delete('shuffle');
    setSearchParams(searchParams, { replace: true });
    window.location.reload();
  };

  const search = (offset) => {
    setIsSearching(true);
    return searchMusic(
      user,
      searchWord,
      toggle,
      searchParams.get('shuffle') === 'true' ? 'shuffle' : null,
      searchParams.get('instrument'),
      searchParams.get('difficulty'),
      searchParams.get('period'),
      searchParams.get('key'),
      searchParams.get('composer'),
      offset,
      searchParams.get('ptype'),
      searchParams.get('match_piece_id'),
      searchParams.get('match_period'),
      searchParams.get('match_pitch'),
      searchParams.get('match_rhythm'),
      searchParams.get('match_key'),
      searchParams.get('match_composer'),
      searchParams.get('tags')
    ).finally(() => {
      setIsSearching(false);
    });
  }

  // useEffect(() => {
  //   getAlbums(user).then((res) => {
  //     console.log('albums', res)
  //     setAlbums(res.albums);
  //   }).catch((err) => {
  //     console.log('albums error', err)
  //   })
  // }, []);

  //could use useDidMountEffect here instead of firstRender to prevent running this on first load,
  //but this is less buggy for now...
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchWord !== undefined && searchWord !== null) {
        setIsLoading(true);
        setData([])
        setPage(1)
        search(0).then((data) => {
          setData(data.pieces || []);
          setTotalResults(data.count || 0);
          setIsLoading(false);
          setFirstRender(false)
        }).catch((err) => {
          setIsLoading(false);
        })
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    setData([])
    setPage(1)
    setIsLoading(true);
    setTotalResults(0);
    search(0).then((data) => {
      setData(data.pieces || []);
      setTotalResults(data.count || 0);
      setIsLoading(false);
      setFirstRender(false)
    }).catch((err) => {
      setIsLoading(false);
    })
  }, [toggle])

  function onPageChange(p) {
    if (!firstRender) {
      setPage(p)
      setData([]);
      search((p - 1) * resultsPerPage).then((more_pieces) => {
        setData(more_pieces.pieces || []);
      }).catch((err) => console.log(err));
    }
  }

  const handleFormInput = (e) => {
    e.preventDefault();
  }

  const { width } = useWindowDimensions();

  const handleNext = () => {
    if (currentPieceIndex < totalResults - 1) {
      if (currentPieceIndex === data.length - 1) {
        const nextPage = page + 1;
        setPage(nextPage);
        setData([]);
        search((nextPage - 1) * resultsPerPage).then((more_pieces) => {
          setData(more_pieces.pieces || []);
          setCurrentPieceIndex(0);
        }).catch((err) => console.log(err));
      } else {
        setCurrentPieceIndex(currentPieceIndex + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentPieceIndex > 0) {
      setCurrentPieceIndex(currentPieceIndex - 1);
    } else if (page > 1) {
      const prevPage = page - 1;
      setPage(prevPage);
      setData([]);
      search((prevPage - 1) * resultsPerPage).then((more_pieces) => {
        setData(more_pieces.pieces || []);
        setCurrentPieceIndex(more_pieces.pieces.length - 1);
      }).catch((err) => console.log(err));
    }
  };

  return (
    <div className='w-full overflow-hidden'>
      {/* <Heading title="Music Library" /> */}
      <div className="box-border overflow-x-hidden shadow-md w-full bg-white dark:bg-gray-800/50">
        <div className='flex flex-col lg:flex-row justify-between items-center pt-2 bg-white dark:bg-gray-800/50'>
          {/* Left side toggles */}
          <div className="px-4 py-3">
            <div className="flex flex-wrap gap-2">
              {toggles && toggles.map((toggle_item) => (
                <button
                  key={toggle_item.id}
                  type="button"
                  onClick={() => setToggle(toggle_item.id)}
                  disabled={isSightreading}
                  className={`
                    text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
                    ${toggle === toggle_item.id
                      ? 'text-violet-600 bg-violet-50 dark:bg-violet-900/20 dark:text-violet-400'
                      : 'text-gray-600 hover:text-violet-600 hover:bg-violet-50 dark:text-gray-400 dark:hover:text-violet-400 dark:hover:bg-violet-900/20'
                    }
                    ${isSightreading ? 'opacity-50 cursor-not-allowed' : ''}
                  `}
                >
                  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    {toggle_item.id === 'all' && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                    )}
                    {toggle_item.id === 'viewed' && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    )}
                    {toggle_item.id === 'starred' && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
                    )}
                    {toggle_item.id === 'my' && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    )}
                  </svg>
                  <span className="whitespace-nowrap">{toggle_item.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Right side buttons */}
          <div className="px-4 py-3 flex items-center space-x-3">
            {!isSightreading && (
              <>
                {/* <UploadPiece button_view={
                  <button type="button" className="flex items-center px-4 py-2 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-violet-700 transition-all duration-200">
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                    </svg>
                    <span className="whitespace-nowrap">Upload a piece</span>
                  </button>
                } /> */}
                <RequestPiece />
              </>
            )}
            <button
              onClick={() => setIsSightreading(!isSightreading)}
              className={`flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${isSightreading
                ? 'text-white bg-red-600 hover:bg-red-700'
                : 'text-violet-600 bg-violet-50 hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30'
                }`}
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                {isSightreading ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                )}
              </svg>
              <span className="whitespace-nowrap">{isSightreading ? 'Library' : 'Sightread'}</span>
            </button>
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <div className="flex flex-col w-full flex-wrap items-center justify-between px-4 space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
            <div className="w-full">
              <form className="flex items-center" onSubmit={handleFormInput}>
                <label for="simple-search" className="sr-only">Search</label>
                <button
                  onClick={() => {
                    setIsMultiSelect(!isMultiSelect);
                    setSelectedPieces([]);
                  }}
                  type="button"
                  className={`p-2 mr-2 rounded-lg transition-all duration-200 ${isMultiSelect
                    ? 'bg-violet-100 text-violet-600 hover:bg-violet-200 dark:bg-violet-900/30 dark:text-violet-400 dark:hover:bg-violet-900/40'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-400 dark:hover:bg-gray-600'
                    }`}
                  title={isMultiSelect ? "Exit selection mode" : "Select multiple pieces"}
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
                <button
                  onClick={() => {
                    if (searchParams.get('shuffle') === 'true') {
                      handleUnshuffle();
                    } else {
                      searchParams.set('shuffle', 'true');
                      setSearchParams(searchParams);
                      window.location.reload();
                    }
                  }}
                  className={`p-2 mr-2 rounded-lg transition-all duration-200 ${searchParams.get('shuffle') === 'true'
                    ? 'text-violet-600 bg-violet-50 hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30'
                    : 'text-gray-500 hover:text-gray-700 bg-gray-100 hover:bg-gray-200 dark:text-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:hover:text-gray-300'
                    }`}
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                </button>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearchWord(e.target.value)}
                    value={searchWord || ''}
                    disabled={isSightreading}
                    type="text"
                    id="simple-search"
                    className={`block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:border-violet-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-violet-600 dark:focus:ring-violet-600 dark:focus:border-violet-500 ${isSightreading ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    placeholder="Search by title, composer, or genre..."
                    required=""
                  />
                  {isSearching && (
                    <div className="absolute right-3 top-2.5">
                      <div className="w-5 h-5 border-t-2 border-violet-500 border-solid rounded-full animate-spin"></div>
                    </div>
                  )}
                  {searchWord && !isSearching && (
                    <button
                      type="button"
                      onClick={() => setSearchWord('')}
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                    >
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="w-full sm:w-auto pr-4">
            {isMultiSelect ? (
              <AddToList
                piece_ids={selectedPieces}
                setDisabled={() => { }}
                button_view={
                  <button
                    className="text-nowrap whitespace-nowrap flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                    disabled={selectedPieces.length === 0}
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                    </svg>
                    <span className="whitespace-nowrap">Add to Album ({selectedPieces.length})</span>
                  </button>
                }
              />
            ) : (
              <div className={isSightreading ? 'opacity-50 pointer-events-none' : ''}>
                <AdvancedSearch />
              </div>
            )}
          </div>
        </div>

        {isSightreading ? (
          <div className="p-8">
            {data.length > 0 && (
              <Piece piece={data[currentPieceIndex]} sightreading={true} />
            )}
          </div>
        ) : isLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4 w-full overflow-y-scroll no-scrollbar">
            {Array.from({ length: 10 }, (_, index) => (
              <MusicLibraryLoadingView key={index} />
            ))}
          </div>
        ) : data.length > 0 ? (
          <div className="p-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 gap-y-4">
              {data.map((piece, index) => (
                <div key={piece.id} className="flex justify-center">
                  <PieceCard
                    piece={piece}
                    isMultiSelect={isMultiSelect}
                    isSelected={selectedPieces.includes(piece.id)}
                    onSelect={(pieceId) => {
                      setSelectedPieces(prev =>
                        prev.includes(pieceId)
                          ? prev.filter(id => id !== pieceId)
                          : [...prev, pieceId]
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-12">
            {searchWord ? (
              <>
                <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                  No results found
                </h3>
                <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                  We couldn't find any pieces matching "{searchWord}". Try adjusting your search or filters.
                </p>
              </>
            ) : (
              <>
                <svg className="w-16 h-16 text-gray-400 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3" />
                </svg>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-1">
                  No pieces available
                </h3>
                <p className="text-gray-500 dark:text-gray-400 text-center max-w-md">
                  {toggle === 'my' ? (
                    "You haven't uploaded any pieces yet. Click the Upload button to get started!"
                  ) : toggle === 'starred' ? (
                    "You haven't starred any pieces yet. Browse the library and star pieces you like!"
                  ) : toggle === 'viewed' ? (
                    "You haven't viewed any pieces yet. Start exploring the library!"
                  ) : (
                    "Start by searching for pieces or use the filters to browse the library."
                  )}
                </p>
              </>
            )}
          </div>
        )}

        <div className="p-4">
          <Pagination
            totalResults={totalResults}
            resultsPerPage={resultsPerPage}
            onChange={onPageChange}
            label="Table navigation"
          />
        </div>
      </div>
    </div>
  )
}

export default Music
