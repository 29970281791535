import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getInitial } from "../../utils/string";
import getDrill from '../../api/getDrill';
import { AccountContext } from '../../stores/Account';
import FlatEmbed from '../components/FlatEmbed';
import MultiPageViewFlat from '../components/MultiPageViewFlat';
import viewDrill from '../../api/viewDrill';
import updateDrillNotes from '../../api/updateDrillNotes';
import Heading from '../components/Heading';
import drillPracticeLog from '../../api/drillPracticeLog';
import useClickOutside from '../../hooks/useClickOutside';
import Pagination from '../components/Pagination';

/*
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        Created {new Date(drill.created_time).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                            Last viewed {new Date(drill.last_viewed).toLocaleDateString()}
                        </div>
*/
function Drill(props) {
    const { id } = useParams();
    const [drill, setDrill] = useState({ 'drill_name': 'Untitled Drill' });
    const { user } = useContext(AccountContext);
    const [activeTab, setActiveTab] = useState('view');
    const [fullScreenFlat, setFullScreenFlat] = useState(false);
    const [practiceLog, setPracticeLog] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [notes, setNotes] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [practiceNotesInput, setPracticeNotesInput] = useState('');

    const [timeRange, setTimeRange] = useState(7);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const recordsPerPage = 5;

    const [currentPage, setCurrentPage] = useState(1);

    const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

    const onTimeRangeChange = (value) => {
        setTimeRange(value);
        setCurrentPage(1);
        // Add any additional logic for when time range changes
    };

    const timeRanges = [
        { value: 7, label: 'Last 7 days' },
        { value: 30, label: 'Last 30 days' },
        { value: 90, label: 'Last 3 months' },
        { value: 365, label: 'Last year' },
        { value: 1000000, label: 'All time' }
    ];

    useEffect(() => {
        getDrill(user, id).then((drill) => {
            console.log(drill);
            setDrill(drill.drill);
            setNotes(drill.drill.drill_notes || '');
        }).catch((error) => {
            console.error(error);
        });
    }, [id]);

    useEffect(() => {
        if (drill) {
            viewDrill(user, drill.id)
        }
    }, [drill])

    const handleUpdatePracticeNotes = async () => {
        setIsUpdating(true);
        try {
            await updateDrillNotes(user, drill.id, notes);
            setDrill({ ...drill, drill_notes: notes });
            setIsEditing(false);
        } catch (error) {
            console.error('Failed to update notes:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <div className="flex flex-row gap-2 p-2 h-screen">
            {/* Main Content */}
            <div className="flex flex-col flex-1 min-w-0 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
                {/* Header */}
                <div>
                    <div className="flex flex-row justify-between items-center pt-4 pb-2">
                        <Heading title={drill.drill_name ? drill.drill_name : "Untitled Drill"} />
                    </div>
                    {/* Composer Info */}
                    <div className="flex flex-row items-center justify-between w-full px-4 mb-4">
                        <div className='flex items-center'>
                            {/* <div className="flex-shrink-0">
                {composer_to_pic(piece.composer_lname) !== "none" ?
                  <img
                    src={composer_to_pic(piece.composer_lname)}
                    className="h-8 w-8 bg-gray-300 rounded-lg object-cover"
                  /> : <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-600">
                    <span className="font-medium text-gray-600 dark:text-gray-300">{getInitial(piece.composer_fname) + getInitial(piece.composer_lname)}</span>
                  </div>
                }
              </div> */}
                            <div className="ml-2">
                                <div className="text-xs font-semibold text-black truncate dark:text-white">{drill.piece_title}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Content based on active tab */}
                <div className="flex-1 px-4 overflow-y-auto no-scrollbar">
                    {activeTab === 'view' && (
                        <div className="relative h-[calc(100vh-150px)]">
                            {(
                                <>
                                    {fullScreenFlat && (
                                        <MultiPageViewFlat
                                            title={drill.drill_name}
                                            composer={`Subito Exercise`}
                                            scoreId={drill.flat_score_id}
                                            sharingKey={drill.flat_sharing_key}
                                            onClose={() => setFullScreenFlat(false)}
                                        />
                                    )}
                                    <div className="relative">
                                        <div className="top-4 right-4 absolute z-10">
                                            <svg
                                                onClick={() => setFullScreenFlat(true)}
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="cursor-pointer fill-current text-gray-500 h-5 w-5 z-10 hover:text-gray-600 transition-all duration-300"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="m22 5c0-.478-.379-1-1-1h-18c-.62 0-1 .519-1 1v14c0 .621.52 1 1 1h18c.478 0 1-.379 1-1zm-7.5 13.5h-11v-7h11zm1.5 0v-7.5c0-.478-.379-1-1-1h-11.5v-4.5h17v13z" fillRule="nonzero" />
                                            </svg>
                                        </div>
                                        <FlatEmbed
                                            scoreId={drill.flat_score_id}
                                            sharingKey={drill.flat_sharing_key}
                                            height="calc(100vh - 170px)"
                                            width="100%"
                                            zoom="0.5"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}

                    {activeTab === 'practice' && (
                        <div className="overflow-y-auto">
                            <div className="p-6 space-y-6">
                                <div className="dark:border-gray-700">
                                    {/* Overview Statistics */}
                                    <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-6">
                                        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">Tot. Practice Time</p>
                                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                                {practiceLog && practiceLog.summary && practiceLog.summary.total_practice_minutes ? practiceLog.summary.total_practice_minutes.toFixed(0) : "0"} minutes
                                            </p>
                                        </div>
                                        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">Avg. Time per Day</p>
                                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                                {practiceLog && practiceLog.summary && practiceLog.summary.avg_minutes_per_day ? practiceLog.summary.avg_minutes_per_day.toFixed(0) : "0"} minutes
                                            </p>
                                        </div>
                                        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">Days Practiced</p>
                                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                                {practiceLog && practiceLog.summary && practiceLog.summary.days_practiced ? practiceLog.summary.days_practiced : "0"} days
                                            </p>
                                        </div>
                                        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">First Practice</p>
                                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                                {practiceLog && practiceLog.summary && practiceLog.summary.first_date_practiced ? new Date(practiceLog.summary.first_date_practiced).toLocaleDateString() : "N/A"}
                                            </p>
                                        </div>
                                        <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4">
                                            <p className="text-xs text-gray-500 dark:text-gray-400">Last Practice</p>
                                            <p className="text-xl font-semibold text-gray-900 dark:text-white">
                                                {practiceLog && practiceLog.summary && practiceLog.summary.most_recent_date_practiced ? new Date(practiceLog.summary.most_recent_date_practiced).toLocaleDateString() : "N/A"}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Time Range Selector */}
                                    <div className="flex items-center justify-between mb-6 border-t border-gray-200 dark:border-gray-700 pt-6">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            Practice Log
                                        </h3>
                                        <div className="relative" ref={dropdownRef}>
                                            <button
                                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                                className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
                                                type="button"
                                            >
                                                {timeRanges.find(r => r.value === timeRange)?.label}
                                                <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                </svg>
                                            </button>
                                            {isDropdownOpen && (
                                                <div className="absolute right-0 mt-2 w-44 bg-white dark:bg-gray-700 rounded-lg shadow-lg z-10">
                                                    {timeRanges.map((range) => (
                                                        <button
                                                            key={range.value}
                                                            onClick={() => {
                                                                setIsDropdownOpen(false);
                                                                onTimeRangeChange(range.value);
                                                            }}
                                                            className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600
                                ${timeRange === range.value ? 'text-violet-600 dark:text-violet-400' : 'text-gray-700 dark:text-gray-200'}`}
                                                        >
                                                            {range.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Practice Log */}
                                    <div className="max-h-240 overflow-y-auto no-scrollbar">
                                        {(!practiceLog?.log || practiceLog.log.length === 0) ? (
                                            <div className="flex flex-col items-center justify-center py-12 text-center">
                                                <div className="w-16 h-16 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center mb-4">
                                                    <svg className="w-8 h-8 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </div>
                                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                                                    No practice records yet
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400 max-w-sm">
                                                    Start practicing this piece and we'll keep track of your progress here.
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="space-y-3">
                                                {practiceLog && practiceLog.log && [...practiceLog.log]
                                                    .sort((a, b) => new Date(b.practice_date) - new Date(a.practice_date))
                                                    .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
                                                    .map((record) => (
                                                        <div
                                                            key={record.practice_date}
                                                            className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-4"
                                                        >
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center space-x-3">
                                                                    <div className="w-10 h-10 rounded-full bg-violet-100 dark:bg-violet-900/30 flex items-center justify-center">
                                                                        <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                        </svg>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-sm font-medium text-gray-900 dark:text-white">
                                                                            {record.practice_minutes} minutes
                                                                        </p>
                                                                        <p className="text-xs text-gray-500 dark:text-gray-400">
                                                                            {new Date(record.practice_date.replace(/(\d{2})\/(\d{2})\/(\d{2}) (\d{2}):(\d{2}):(\d{2})/, '20$3-$1-$2T$4:$5:$6')).toLocaleDateString()}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <span className="px-2.5 py-0.5 text-xs font-medium text-violet-600 bg-violet-100 rounded-full dark:bg-violet-900/30 dark:text-violet-400">
                                                                    {new Date(record.practice_date).toLocaleDateString()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        )}

                                        {/* Pagination */}
                                        {practiceLog?.log?.length > recordsPerPage && (
                                            <div className="mt-4 w-full">
                                                <Pagination
                                                    page={currentPage}
                                                    totalResults={practiceLog && practiceLog.log ? practiceLog.log.length : 0}
                                                    resultsPerPage={recordsPerPage}
                                                    onChange={page => setCurrentPage(page)}
                                                    label="Practice Log Navigation"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="p-6">
                                    <div className="flex items-center justify-between mb-4">
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            Practice Notes
                                        </h3>
                                        <div className="flex space-x-3">
                                            {isEditing ? (
                                                <>
                                                    <button
                                                        onClick={() => {
                                                            setPracticeNotesInput(drill.drill_notes || '');
                                                            setIsEditing(false);
                                                        }}
                                                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                                        disabled={isUpdating}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={handleUpdatePracticeNotes}
                                                        disabled={isUpdating}
                                                        className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200 disabled:opacity-50"
                                                    >
                                                        {isUpdating ? 'Saving...' : 'Save Changes'}
                                                    </button>
                                                </>
                                            ) : (
                                                <button
                                                    onClick={() => setIsEditing(true)}
                                                    className="flex items-center px-4 py-2 text-sm font-medium text-violet-600 bg-violet-50 rounded-lg hover:bg-violet-100 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 transition-all duration-200"
                                                >
                                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                    </svg>
                                                    Edit Notes
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {isEditing ? (
                                        <textarea
                                            value={practiceNotesInput}
                                            onChange={(e) => setPracticeNotesInput(e.target.value)}
                                            rows="4"
                                            className="w-full p-3 text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-violet-500 focus:border-violet-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                            placeholder="Add notes about this drill..."
                                        />
                                    ) : (
                                        <div className="text-gray-600 dark:text-gray-300">
                                            {drill.drill_notes || "This drill has no practice notes yet."}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Right Sidebar */}
            <div className="w-80 h-full flex-shrink-0 bg-white rounded-2xl shadow-lg dark:bg-gray-800 border border-gray-200/50 dark:border-gray-700/50 overflow-y-auto no-scrollbar">
                <div className="p-4 h-full flex flex-col">

                    {/* Navigation Tabs */}
                    <div className="bg-gray-50 dark:bg-gray-900/50 rounded-lg p-1.5 mb-5">
                        <nav className="grid grid-cols-2 gap-1">
                            <button
                                onClick={() => setActiveTab('view')}
                                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'view'
                                    ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                                    : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                                    }`}
                            >
                                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                View
                            </button>
                            <button
                                onClick={() => setActiveTab('practice')}
                                className={`px-3 py-2 text-xs font-medium rounded-md transition-all duration-200 ease-in-out inline-flex items-center justify-center ring-1 ring-transparent ${activeTab === 'practice'
                                    ? 'bg-white dark:bg-gray-800 text-violet-600 dark:text-violet-400 shadow-sm ring-violet-200 dark:ring-violet-800'
                                    : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:ring-gray-200 dark:hover:ring-gray-700'
                                    }`}
                            >
                                <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                                </svg>
                                Practice
                            </button>
                        </nav>
                    </div>

                    {/* Stats Section */}
                    <div className="mt-0">
                        <div className="bg-gray-50 dark:bg-gray-900 rounded-xl p-2">
                            <div className="flex items-center justify-between mb-4 mt-1">
                                <h3 className="text-sm font-semibold text-gray-900 dark:text-white flex items-center">
                                    <svg className="w-4 h-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                                    </svg>
                                    Practice Statistics
                                </h3>
                            </div>

                            <div className="grid grid-cols-1 gap-5">
                                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 dark:text-gray-400">Total Practice Time</span>
                                        <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                                            {practiceLog && practiceLog.summary && practiceLog.summary.total_practice_minutes ? practiceLog.summary.total_practice_minutes.toFixed(0) : "0"} minutes
                                        </span>
                                    </div>
                                    <div className="bg-violet-100 dark:bg-violet-900/30 p-2 rounded-lg">
                                        <svg className="w-5 h-5 text-violet-600 dark:text-violet-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 dark:text-gray-400">Average Practice Time</span>
                                        <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                                            {practiceLog && practiceLog.summary && practiceLog.summary.avg_minutes_per_day ? practiceLog.summary.avg_minutes_per_day.toFixed(0) : "0"} mins/day
                                        </span>
                                    </div>
                                    <div className="bg-emerald-100 dark:bg-emerald-900/30 p-2 rounded-lg">
                                        <svg className="w-5 h-5 text-emerald-600 dark:text-emerald-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                        </svg>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-700/30 rounded-lg">
                                    <div className="flex flex-col">
                                        <span className="text-xs text-gray-500 dark:text-gray-400">Last Practice Session</span>
                                        <span className="text-lg font-semibold text-gray-900 dark:text-white mt-1">
                                            {practiceLog && practiceLog.summary && practiceLog.summary.most_recent_date_practiced ? new Date(practiceLog.summary.most_recent_date_practiced).toLocaleDateString() : "N/A"}
                                        </span>
                                    </div>
                                    <div className="bg-sky-100 dark:bg-sky-900/30 p-2 rounded-lg">
                                        <svg className="w-5 h-5 text-sky-600 dark:text-sky-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Drill
