import { useSearchParams } from 'react-router-dom';
import Heading from '../components/Heading';
import { useState } from 'react';
import FlatEmbed from '../components/FlatEmbed';

function Reviewer() {
  const [searchParams] = useSearchParams();
  const piece = searchParams.get('piece');
  const [isEditing, setIsEditing] = useState(false);

  // Track which fields are being edited
  const [editingFields, setEditingFields] = useState({
    title: false,
    composer: false,
    timePeriod: false,
    keySignature: false,
    difficulty: false
  });

  // Track field values
  const [values, setValues] = useState({
    title: 'Clair de Lune',
    composer: 'Claude Debussy',
    timePeriod: 'Romantic',
    keySignature: 'Db Major',
    difficulty: 'Advanced'
  });

  // Add these states at the top with other states
  const [isSavingMetadata, setIsSavingMetadata] = useState(false);
  const [isFinishingUpload, setIsFinishingUpload] = useState(false);

  const toggleEdit = (field) => {
    setEditingFields(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleChange = (field, value) => {
    setValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const renderField = (field, label) => (
    <div className="group">
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{label}</span>
        {editingFields[field] ? (
          <button
            onClick={() => toggleEdit(field)}
            className="transition-opacity"
          >
            <svg className="w-5 h-5 text-green-500 hover:text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </button>
        ) : (
          <button
            onClick={() => toggleEdit(field)}
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <svg className="w-4 h-4 text-gray-400 hover:text-violet-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
            </svg>
          </button>
        )}
      </div>
      {editingFields[field] ? (
        <input
          type="text"
          value={values[field]}
          onChange={(e) => handleChange(field, e.target.value)}
          className="mt-1 w-full px-2 py-1 text-sm border rounded-md border-violet-500 focus:outline-none focus:ring-1 focus:ring-violet-500 dark:bg-gray-700 dark:text-white"
          autoFocus
        />
      ) : (
        <p className="text-sm text-gray-900 dark:text-white mt-1">{values[field]}</p>
      )}
    </div>
  );

  return (
    <div className="flex">
      {/* Main Content */}
      <div className="flex-1 mr-64">
        <div className="bg-white dark:bg-gray-800">
          {/* Header */}
          <div className="flex flex-row justify-between items-center pt-4 pb-2">
            <Heading title="Review your piece" />
          </div>

          {/* Info Message */}
          <div className="flex flex-row items-center px-4 mb-4">
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Our optical music recognition system is still experimental and you may need to clean up the piece a bit.
              You will also need to add dynamics, slurs, ties, and other ornaments as we do not currently identify them.
            </div>
          </div>

          {/* Edit Button */}
          <div className="px-4 mb-4">
            <button
              onClick={() => setIsEditing(!isEditing)}
              className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-150 ${isEditing
                ? 'bg-violet-100 text-violet-700 hover:bg-violet-200 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
                }`}
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </svg>
              {isEditing ? 'Editing...' : 'Edit Piece'}
            </button>
          </div>

          {/* Main Content Area - FlatEmbed */}
          <div className="px-4">
            <div className="relative">
              <FlatEmbed
                scoreId="6730da4421e0a734e5da206b"
                sharingKey="07b34a5c341c18d07489c0ffbf6d3aab9ba6f9849ed9e041f4b03f982b7fe2b913d1c22460152f0fb4bb09d30d5c26c760a132dd10dfb14178f141138a11df4e"
                height={1000}
                mode={isEditing ? 'edit' : 'view'}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Right Sidebar */}
      <div className="w-64 bg-white dark:bg-gray-800 border-l border-gray-200 dark:border-gray-700 fixed right-0 top-0 bottom-0 overflow-y-auto">
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-6 text-gray-900 dark:text-white">Piece Details</h3>

          {/* Piece Metadata */}
          <div className="space-y-4">
            {renderField('title', 'Title')}
            {renderField('composer', 'Composer')}
            {renderField('timePeriod', 'Time Period')}
            {renderField('keySignature', 'Key Signature')}
            {renderField('difficulty', 'Difficulty')}
          </div>

          {/* Fixed Button Group at bottom */}
          <div className="fixed bottom-0 right-0 w-64 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
            <div className="p-4 space-y-3">
              <button
                className="w-full px-4 py-2.5 text-sm font-medium text-violet-600 bg-violet-100 rounded-lg hover:bg-violet-200 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:opacity-50 transition-colors duration-150 flex items-center justify-center gap-2"
                onClick={async () => {
                  setIsSavingMetadata(true);
                  try {
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    console.log('Saved metadata:', values);
                  } catch (error) {
                    console.error('Error saving metadata:', error);
                  } finally {
                    setIsSavingMetadata(false);
                  }
                }}
                disabled={isSavingMetadata || isFinishingUpload}
              >
                {isSavingMetadata ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-violet-600 border-t-transparent"></div>
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>
                    <span>Save Metadata</span>
                  </>
                )}
              </button>
              <button
                className="w-full px-4 py-2.5 text-sm font-medium text-white bg-violet-600 rounded-lg hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:opacity-50 transition-colors duration-150 flex items-center justify-center gap-2"
                onClick={async () => {
                  setIsFinishingUpload(true);
                  try {
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    console.log('Finished upload process');
                    window.location.href = '/dashboard/upload';
                  } catch (error) {
                    console.error('Error finishing upload:', error);
                  } finally {
                    setIsFinishingUpload(false);
                  }
                }}
                disabled={isSavingMetadata || isFinishingUpload}
              >
                {isFinishingUpload ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                    <span>Finishing...</span>
                  </>
                ) : (
                  <>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Finish Uploading</span>
                  </>
                )}
              </button>
            </div>
          </div>

          {/* Add padding at the bottom to account for fixed buttons */}
          <div className="h-28" />
        </div>
      </div>
    </div>
  );
}

export default Reviewer;
