import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ChatInterface({
  onSubmit,
  onResponse,
  initialPrompt,
  messages,
  setMessages
}) {
  const [currentInput, setCurrentInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const isExercisesPage = location.pathname === '/dashboard/exercises';

  const [isLoading, setIsLoading] = useState(false);
  // Use local state when messages aren't provided
  const [localMessages, setLocalMessages] = useState([]);
  const actualMessages = messages || localMessages;
  const setActualMessages = setMessages || setLocalMessages;

  // Handle initial prompt when component mounts
  useEffect(() => {
    if (initialPrompt && onResponse) {
      setCurrentInput(initialPrompt);
      handleSubmit({ preventDefault: () => { } }, initialPrompt);
    }
  }, [initialPrompt]);

  const scrollToBottom = (force = false) => {
    setTimeout(() => {
      const container = messagesEndRef.current?.parentElement;
      if (!container) return;

      // Check if we're near the bottom already
      const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;

      // Always scroll if forced, otherwise only scroll if near bottom
      if (force || isNearBottom) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const mockApiCall = async (input) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    return "I'll create a drill with a quarter note rhythm pattern in the first voice, and eighth notes in the second voice. I'll set the key signature to C major and make it repeat twice.";
  };

  const simulateTyping = async (text) => {
    setIsTyping(true);
    const words = text.split(' ');
    let currentText = '';

    for (let word of words) {
      currentText += word + ' ';
      setActualMessages(prev => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1].content = currentText;
        return newMessages;
      });
      scrollToBottom(false);
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    setIsTyping(false);
  };

  const handleSubmit = async (e, inputOverride) => {
    e.preventDefault();
    const input = inputOverride || currentInput;
    if (!input.trim()) return;

    try {
      if (isExercisesPage) {
        // Redirect to create-drill page with prompt
        window.location.href = `/dashboard/exercises/create-drill?prompt=${encodeURIComponent(input)}`;
        return;
      }

      const newUserMessage = { role: 'user', content: input };
      setActualMessages(prev => [...prev, newUserMessage]);
      setCurrentInput('');

      setIsLoading(true);
      const response = await onSubmit(input);

      if (onResponse) {
        onResponse(response);
      }

      // Add empty assistant message first
      setActualMessages(prev => [...prev, { role: 'assistant', content: '' }]);

      setIsLoading(false);
      setIsTyping(true);
      // Simulate typing for the explanation
      await simulateTyping(response.explanation || 'Sorry, Subito could not process your request. Please try again.');
    } catch (error) {
      console.error('Error in chat:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error processing your request.'
      };
      setActualMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Messages container */}
      <div className="flex-1 overflow-y-auto relative no-scrollbar">
        {actualMessages.length === 0 ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center space-y-6 max-w-md px-4">
              <div className="w-16 h-16 mx-auto bg-violet-50 dark:bg-violet-900/20 rounded-full flex items-center justify-center">
                <svg
                  className="w-8 h-8 text-violet-600 dark:text-violet-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12 0c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
                  />
                </svg>
              </div>
              <div className="space-y-2">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Create a Custom Drill
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Describe what you want to practice and Subito will create a personalized drill for you. Try:
                </p>
                <div className="space-y-3 pt-2">
                  {[
                    "Create a rhythm drill with quarter notes and eighth notes",
                    "Make a drill focusing on intervals in C major",
                    "Generate a sight-reading exercise with mixed rhythms"
                  ].map((example, i) => (
                    <div
                      key={i}
                      className="p-2 bg-gray-50 dark:bg-gray-800 rounded-lg text-sm text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50 cursor-pointer transition-colors"
                      onClick={() => {
                        setCurrentInput(example);
                        document.querySelector('textarea')?.focus();
                      }}
                    >
                      "{example}"
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {actualMessages.map((message, index) => (
              <div
                key={index}
                className={`py-4 rounded-lg px-2 ${message.role === 'assistant' ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700/50'
                  }`}
              >
                <div className="">
                  <div className="text-gray-900 dark:text-white text-sm break-words whitespace-pre-wrap">
                    {message.content}
                    {message.role === 'assistant' && isTyping && actualMessages.length - 1 === index && (
                      <span className="inline-block w-2 h-4 ml-1 bg-gray-400 dark:bg-gray-500 animate-pulse" />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {isLoading && <span className="inline-block w-2 h-2 mx-1 bg-gray-400 dark:bg-gray-500 rounded-full animate-ping"></span>}
          </>

        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Fixed input form at bottom */}
      <div className="border-t border-gray-200 dark:border-gray-700">
        <div className="">
          <form onSubmit={handleSubmit} className="relative p-2">
            <textarea
              value={currentInput}
              onChange={(e) => setCurrentInput(e.target.value)}
              placeholder={isExercisesPage ? "Describe the drill you want to create..." : "Type your message..."}
              rows={4}
              disabled={isTyping || isLoading}
              className="w-full py-2 pr-12 text-sm text-gray-900 dark:text-white bg-white dark:bg-gray-700 border-box border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 resize-none disabled:opacity-50 disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:cursor-not-allowed no-scrollbar"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            />
            <button
              type="submit"
              disabled={!currentInput.trim() || isTyping}
              className="absolute bottom-4 right-4 p-1.5 text-gray-400 hover:text-violet-600 dark:text-gray-500 dark:hover:text-violet-400 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150 text-xs"
            >
              {isExercisesPage ? 'Create' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChatInterface;

// import { useState, useEffect, useRef } from 'react';
// import { useLocation, useSearchParams } from 'react-router-dom';

// function ChatInterface({
//   onSubmit,
//   onResponse,
//   initialPrompt,
//   messages,
//   setMessages
// }) {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [currentInput, setCurrentInput] = useState('');
//   const [isTyping, setIsTyping] = useState(false);
//   const messagesEndRef = useRef(null);
//   const location = useLocation();
//   const isExercisesPage = location.pathname === '/dashboard/exercises';

//   // Use local state when messages aren't provided
//   const [localMessages, setLocalMessages] = useState([]);
//   const actualMessages = messages || localMessages;
//   const setActualMessages = setMessages || setLocalMessages;
//   const [isLoading, setIsLoading] = useState(false);

//   // Handle initial prompt when component mounts
//   useEffect(() => {
//     if (initialPrompt && onResponse) {
//       setCurrentInput(initialPrompt);
//       handleSubmit({ preventDefault: () => { } }, initialPrompt);
//     }
//   }, [initialPrompt]);

//   const scrollToBottom = (force = false) => {
//     setTimeout(() => {
//       const container = messagesEndRef.current?.parentElement;
//       if (!container) return;

//       // Check if we're near the bottom already
//       const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;

//       // Always scroll if forced, otherwise only scroll if near bottom
//       if (force || isNearBottom) {
//         messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//       }
//     }, 0);
//   };

//   const mockApiCall = async (input) => {
//     await new Promise(resolve => setTimeout(resolve, 1000));
//     return "I'll create a drill with a quarter note rhythm pattern in the first voice, and eighth notes in the second voice. I'll set the key signature to C major and make it repeat twice.";
//   };

//   const simulateTyping = async (text) => {
//     setIsTyping(true);
//     const words = text.split(' ');
//     let currentText = '';

//     for (let word of words) {
//       currentText += word + ' ';
//       setActualMessages(prev => {
//         const newMessages = [...prev];
//         newMessages[newMessages.length - 1].content = currentText;
//         return newMessages;
//       });
//       scrollToBottom(false);
//       await new Promise(resolve => setTimeout(resolve, 100));
//     }
//     setIsTyping(false);
//   };

//   const handleSubmit = async (e, inputOverride) => {
//     e.preventDefault();
//     const input = inputOverride || currentInput;
//     if (!input.trim()) return;

//     setIsLoading(true);

//     const newUserMessage = { role: 'user', content: input };
//     setActualMessages(prev => [...prev, newUserMessage]);
//     setCurrentInput('');

//     try {
//       if (isExercisesPage) {
//         // Redirect to create-drill page with prompt
//         window.location.href = `/dashboard/exercises/create-drill?prompt=${encodeURIComponent(input)}`;
//         return;
//       }

//       const response = await onSubmit(input);

//       // Remove 'prompt' from URL if present
//       if (searchParams.has('prompt')) {
//         const newParams = new URLSearchParams(searchParams);
//         newParams.delete('prompt');
//         setSearchParams(newParams);
//       }

//       if (onResponse) {
//         onResponse(response);
//       }

//       // Add empty assistant message first
//       setActualMessages(prev => [...prev, { role: 'assistant', content: '' }]);

//       // Endpoint has returned so stop loading, begin typing effect.
//       setIsLoading(false);
//       setIsTyping(true);
//       await simulateTyping(response.explanation || 'Created new drill');
//     } catch (error) {
//       console.error('Error in chat:', error);
//       const errorMessage = {
//         role: 'assistant',
//         content: 'Sorry, there was an error processing your request.'
//       };
//       setActualMessages(prev => [...prev, errorMessage]);
//     } finally {
//       setIsTyping(false);
//     }
//   };

//   return (
//     <div className="flex flex-col h-full">
//       {/* Messages container */}
//       <div className="flex-1 overflow-y-auto relative no-scrollbar">
//         {actualMessages.length === 0 ? (
//           <div className="absolute inset-0 flex items-center justify-center">
//             <div className="text-center space-y-6 max-w-md px-4">
//               <div className="w-16 h-16 mx-auto bg-violet-50 dark:bg-violet-900/20 rounded-full flex items-center justify-center">
//                 <svg
//                   className="w-8 h-8 text-violet-600 dark:text-violet-400"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={1.5}
//                     d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12 0c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"
//                   />
//                 </svg>
//               </div>
//               <div className="space-y-2">
//                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
//                   Create a Custom Drill
//                 </h3>
//                 <p className="text-sm text-gray-500 dark:text-gray-400">
//                   Describe what you want to practice and Subito will create a personalized drill for you. Try:
//                 </p>
//                 <div className="space-y-3 pt-2">
//                   {[
//                     "Create a rhythm drill with quarter notes and eighth notes",
//                     "Make a drill focusing on intervals in C major",
//                     "Generate a sight-reading exercise with mixed rhythms"
//                   ].map((example, i) => (
//                     <div
//                       key={i}
//                       className="p-2 bg-gray-50 dark:bg-gray-800 rounded-lg text-sm text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50 cursor-pointer transition-colors"
//                       onClick={() => {
//                         setCurrentInput(example);
//                         document.querySelector('textarea')?.focus();
//                       }}
//                     >
//                       "{example}"
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <>
//             {actualMessages.map((message, index) => (
//               <div
//                 key={index}
//                 className={`py-4 rounded-lg px-2 ${message.role === 'assistant'
//                   ? 'bg-white dark:bg-gray-800'
//                   : 'bg-gray-50 dark:bg-gray-700/50'
//                   }`}
//               >
//                 <div>
//                   <div className="text-gray-900 dark:text-white text-sm break-words whitespace-pre-wrap">
//                     {message.role === 'assistant' && actualMessages.length - 1 === index && (isTyping || isLoading) ? (
//                       <>
//                         <span className="inline-block w-2 h-2 mr-1 bg-gray-400 dark:bg-gray-500 rounded-full animate-ping"></span>
//                         {message.content}
//                       </>
//                     ) : (
//                       message.content
//                     )}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </>
//         )}
//         <div ref={messagesEndRef} />
//       </div>

//       {/* Fixed input form at bottom */}
//       <div className="border-t border-gray-200 dark:border-gray-700">
//         <div className="">
//           <form onSubmit={handleSubmit} className="relative p-2">
//             <textarea
//               value={currentInput}
//               onChange={(e) => setCurrentInput(e.target.value)}
//               placeholder={isExercisesPage ? "Describe the drill you want to create..." : "Type your message..."}
//               rows={4}
//               disabled={isTyping || isLoading}
//               className="w-full py-2 pr-12 text-sm text-gray-900 dark:text-white bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500 resize-none disabled:opacity-50 disabled:bg-gray-50 dark:disabled:bg-gray-800 disabled:cursor-not-allowed no-scrollbar"
//               onKeyDown={(e) => {
//                 if (e.key === 'Enter' && !e.shiftKey) {
//                   e.preventDefault();
//                   handleSubmit(e);
//                 }
//               }}
//             />
//             <button
//               type="submit"
//               disabled={!currentInput.trim() || isTyping}
//               className="absolute bottom-4 right-4 p-1.5 text-gray-400 hover:text-violet-600 dark:text-gray-500 dark:hover:text-violet-400 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150 text-xs"
//             >
//               {isExercisesPage ? 'Create' : 'Send'}
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ChatInterface; 