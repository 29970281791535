import { AccountContext } from '../../stores/Account';
import { useContext, useState, useEffect } from 'react';
import { Label } from '../../components/Input';
import uploadPiece from '../../api/uploadPiece';
import getPresignedPost from '../../api/getPresignedPost';
import putToS3 from '../../api/putToS3';
import bulkPieceUpload from '../../api/bulkPieceUpload';

const FileUploader = ({ pdfFiles, setPdfFiles, fileType, setFileType }) => {
  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const validFiles = files.filter(file => {
      if (fileType === 'pdf') {
        return file.type === 'application/pdf';
      } else {
        return file.name.endsWith('.xml') || file.name.endsWith('.musicxml');
      }
    });
    setPdfFiles(validFiles);
  };

  return (
    <div className="w-full">
      <div className="inline-flex rounded-lg border border-gray-200 dark:border-gray-700 p-1 mb-4">
        <button
          type="button"
          onClick={() => setFileType('pdf')}
          className={`px-4 py-2 text-sm font-medium rounded-md ${fileType === 'pdf'
            ? 'bg-violet-600 text-white'
            : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
        >
          PDF
        </button>
        <button
          type="button"
          onClick={() => setFileType('musicxml')}
          className={`px-4 py-2 text-sm font-medium rounded-md ${fileType === 'musicxml'
            ? 'bg-violet-600 text-white'
            : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
            }`}
        >
          MusicXML
        </button>
      </div>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className="flex items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        <div className="flex flex-col items-center justify-center px-4 py-6 text-center">
          <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {fileType === 'pdf' ? 'PDF files only' : 'MusicXML files only (.xml, .musicxml)'}
          </p>
        </div>
        <input
          type="file"
          accept={fileType === 'pdf' ? '.pdf' : '.xml,.musicxml'}
          multiple
          className="hidden"
          onChange={(e) => setPdfFiles(Array.from(e.target.files))}
        />
      </div>
    </div>
  );
};

const UploadProgressPopup = ({ promises = [], onComplete }) => {
  const [completed, setCompleted] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const total = promises.length;

  useEffect(() => {
    if (!promises.length) return;

    const processPromises = async () => {
      await Promise.all(
        promises.map(async (promise) => {
          await promise;
          setCompleted(prev => prev + 1);
        })
      );

      if (onComplete) {
        onComplete();
      }

      setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    };

    processPromises();
  }, [promises, onComplete]);

  if (!isVisible || !promises.length) return null;

  const progress = total ? (completed / total) * 100 : 0;

  return (
    <div className="fixed bottom-4 right-4 bg-white rounded-lg shadow-lg p-4 w-64 transform transition-transform duration-200 ease-in-out hover:scale-105 z-50">
      <div className="mb-2 flex justify-between items-center">
        <span className="text-sm font-medium text-gray-700">
          Uploading {completed} of {total} files
        </span>
        <button
          onClick={() => setIsVisible(false)}
          className="text-gray-400 hover:text-gray-600 text-sm"
        >
          ×
        </button>
      </div>

      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          className="bg-violet-600 h-2.5 rounded-full transition-all duration-300 ease-out"
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="mt-1 text-right text-xs text-gray-500">
        {Math.round(progress)}%
      </div>
    </div>
  );
};

function Upload() {
  const [instrument, setInstrument] = useState(null)
  const [composerfname, setComposerFName] = useState(null);
  const [composerlname, setComposerLName] = useState(null);
  const [timePeriod, setTimePeriod] = useState(null);
  const [pieceName, setPieceName] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [mode, setMode] = useState('single');
  const [file, setFile] = useState(null);
  const [key, setKey] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [fileType, setFileType] = useState('pdf');
  const [uploadPromises, setUploadPromises] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [callback, setCallback] = useState(null);
  const [uploadResults, setUploadResults] = useState({ success: [], failed: [] });

  const { user } = useContext(AccountContext);

  const uploadSingleFile = async (file, metadata) => {
    try {
      const res = await getPresignedPost(user, 1, fileType);
      const file_id = res.file_id;
      console.log('file_id:', file_id);
      const post_url = res.post;
      const data = await file.arrayBuffer();
      await putToS3(post_url, data, fileType);
      await uploadPiece(
        user,
        file_id,
        metadata.instrument,
        metadata.composerfname,
        metadata.composerlname,
        metadata.timePeriod,
        metadata.pieceName,
        metadata.difficulty,
        metadata.key,
        fileType,
        isPrivate
      );
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  };

  const bulkPut = async (url, file) => {
    try {
      const data = await file.arrayBuffer();
      await putToS3(url, data, fileType);
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    }
  };

  const getSortedResults = () => {
    const allResults = [
      ...uploadResults.success.map(item => ({ ...item, status: 'success' })),
      ...uploadResults.failed.map(item => ({ ...item, status: 'failed' }))
    ];

    return allResults.sort((a, b) => a.title.localeCompare(b.title));
  };

  const submit = async () => {
    if (mode === 'single' && file) {
      setIsUploading(true);
      const metadata = {
        instrument,
        composerfname,
        composerlname,
        timePeriod,
        pieceName,
        difficulty,
        key
      };

      try {
        await uploadSingleFile(file, metadata);
        setUploadResults({
          success: [{ title: pieceName, composer: `${composerfname} ${composerlname}` }],
          failed: []
        });
      } catch (error) {
        console.error('Upload failed:', error);
        setUploadResults({
          success: [],
          failed: [{ title: pieceName, composer: `${composerfname} ${composerlname}`, error: error.message }]
        });
      } finally {
        setIsUploading(false);
      }
    } else if (mode === 'bulk' && pdfFiles.length > 0) {
      setIsUploading(true);
      try {
        const res = await getPresignedPost(user, pdfFiles.length, fileType);
        const file_ids = res.map((obj) => obj.file_id);
        const file_names = pdfFiles.map((file) => file.name.replace('.pdf', '').replace('.xml', '').replace('.musicxml', '').replace('|||', ''));
        const promises = pdfFiles.map((file, index) => bulkPut(res[index].post, file));

        await Promise.all(promises);

        // Wait 1 second before bulk upload
        await new Promise(resolve => setTimeout(resolve, 1000));

        const result = await bulkPieceUpload(
          user,
          file_names.join("|||"),
          file_ids.join(","),
          fileType,
          isPrivate
        );

        setUploadResults({
          success: result.success.map(item => ({ title: item.title, composer: item.composer })),
          failed: result.failed.map(item => ({ title: item.title, composer: item.composer, error: item.error }))
        });
      } catch (error) {
        console.error('Bulk upload failed:', error);
        setUploadResults({
          success: [],
          failed: pdfFiles.map(file => ({
            title: file.name,
            composer: 'Unknown',
            error: 'Upload failed'
          }))
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        {/* Left side - Upload Form */}
        <div className="w-1/2 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 overflow-y-auto no-scrollbar">
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-6 text-gray-900 dark:text-white">Upload Music</h2>

            {/* Upload Type Selection */}
            <ul className="grid w-full gap-4 md:grid-cols-2 mb-4">
              <li onClick={() => setMode('single')}>
                <input
                  type="radio"
                  id="hosting-small"
                  name="hosting"
                  value="hosting-small"
                  className="hidden peer"
                  checked={mode === 'single'}
                  required
                />
                <label htmlFor="hosting-small" className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-violet-600 peer-checked:border-violet-600 peer-checked:text-violet-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-sm font-semibold">Single File Upload</div>
                    <div className="w-full text-xs">For one piece of music</div>
                  </div>
                </label>
              </li>
              <li onClick={() => setMode('bulk')}>
                <input
                  type="radio"
                  id="hosting-big"
                  name="hosting"
                  value="hosting-big"
                  className="hidden peer"
                  checked={mode === 'bulk'}
                />
                <label htmlFor="hosting-big" className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-violet-500 peer-checked:border-violet-600 peer-checked:text-violet-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                  <div className="block">
                    <div className="w-full text-sm font-semibold">Bulk Upload</div>
                    <div className="w-full text-xs">For a collection of pieces</div>
                  </div>
                </label>
              </li>
            </ul>

            {/* Form Fields - conditionally rendered based on mode */}
            {mode === 'single' ? (
              <>
                <div
                  className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <div className="w-full">
                    <Label label="Instrument (solo)" />
                    <select id="instruments"
                      onChange={(e) => setInstrument(e.target.value)}
                      value={instrument}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                      <option selected value="-">Select an instrument</option>
                      <option value="piano">Piano</option>
                    </select>
                  </div>
                  <div className="w-full">
                    <Label label="Difficulty score" />
                    <select id="time_periods"
                      onChange={(e) => setDifficulty(e.target.value)}
                      value={difficulty}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                      <option selected value="-">Choose a target difficulty</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Advanced">Advanced</option>
                    </select>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <div className="w-full">
                    <Label label="Piece name" />
                    <input type="text" id="first_name"
                      onChange={(e) => setPieceName(e.target.value)}
                      value={pieceName}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Clair de lune" required />
                  </div>
                </div>
                <div
                  className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <div className="w-full">
                    <Label label="Time period" />
                    <select id="countries"
                      onChange={(e) => setTimePeriod(e.target.value)}
                      value={timePeriod}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                      <option selected value="-">Choose a time period</option>
                      <option value="pre_baroque">Renaissance (c. 1400 - 1600)</option>
                      <option value="baroque">Baroque (c. 1600 - 1750)</option>
                      <option value="classical">Classical (c. 1750 - 1830)</option>
                      <option value="early_romantic">Early Romantic (c.1830 - 1860)</option>
                      <option value="late_romantic">Late Romantic (c.1860 - 1920)</option>
                      <option value="modern">20th and 21st century (c. 1920 - present)</option>
                    </select>
                  </div>
                  <div className="w-full">
                    <Label label="Key signature" />
                    <select id="key"
                      onChange={(e) => setKey(e.target.value)}
                      value={key}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                      <option selected value="">Choose a key</option>
                      <option value="C_major">C Major</option>
                      <option value="A_minor">A Minor</option>
                      <option value="G_major">G Major</option>
                      <option value="E_minor">E Minor</option>
                      <option value="D_major">D Major</option>
                      <option value="B_minor">B Minor</option>
                      <option value="A_major">A Major</option>
                      <option value="F#_minor">F# Minor</option>
                      <option value="E_major">E Major</option>
                      <option value="C#_minor">C# Minor</option>
                      <option value="B_major">B Major</option>
                      <option value="G#_minor">G# Minor</option>
                      <option value="F#_major">F# Major</option>
                      <option value="D#_minor">D# Minor</option>
                      <option value="C#_major">C# Major</option>
                      <option value="A#_minor">A# Minor</option>
                      <option value="F_major">F Major</option>
                      <option value="D_minor">D Minor</option>
                      <option value="Bb_major">Bb Major</option>
                      <option value="G_minor">G Minor</option>
                      <option value="Eb_major">Eb Major</option>
                      <option value="C_minor">C Minor</option>
                      <option value="Ab_major">Ab Major</option>
                      <option value="F_minor">F Minor</option>
                      <option value="Db_major">Db Major</option>
                      <option value="Bb_minor">Bb Minor</option>
                      <option value="Gb_major">Gb Major</option>
                      <option value="Eb_minor">Eb Minor</option>
                      <option value="Cb_major">Cb Major</option>
                      <option value="Ab_minor">Ab Minor</option>
                    </select>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <div className="w-full">
                    <Label label="Composer first name" />
                    <input type="text" id="first_name"
                      onChange={(e) => setComposerFName(e.target.value)}
                      value={composerfname}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Wolfgang Amadeus" required />
                  </div>
                  <div className="w-full">
                    <Label label="Composer last name" />
                    <input type="text" id="first_name"
                      onChange={(e) => setComposerLName(e.target.value)}
                      value={composerlname}
                      className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-violet-600 focus:border-violet-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Mozart" required />
                  </div>
                </div>
                <div
                  className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0">
                  <div className="w-full">
                    <Label label="Piece Upload File" />
                    <div className="w-full">
                      <div className="inline-flex rounded-lg border border-gray-200 dark:border-gray-700 p-1 mb-3">
                        <button
                          type="button"
                          onClick={() => setFileType('pdf')}
                          className={`px-4 py-2 text-sm font-medium rounded-md ${fileType === 'pdf'
                            ? 'bg-violet-600 text-white'
                            : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                            }`}
                        >
                          PDF
                        </button>
                        <button
                          type="button"
                          onClick={() => setFileType('musicxml')}
                          className={`px-4 py-2 text-sm font-medium rounded-md ${fileType === 'musicxml'
                            ? 'bg-violet-600 text-white'
                            : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                            }`}
                        >
                          MusicXML
                        </button>
                      </div>
                      <input
                        accept={fileType === 'pdf' ? '.pdf' : '.xml,.musicxml'}
                        onChange={handleFileChange}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-white dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        aria-describedby="file_input_help"
                        id="file_input"
                        type="file"
                      />
                      <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
                        {fileType === 'pdf'
                          ? "Upload a PDF file of your sheet music."
                          : "Upload a MusicXML file (.xml, .musicxml)"
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : mode === 'bulk' ? (
              <FileUploader
                pdfFiles={pdfFiles}
                setPdfFiles={setPdfFiles}
                fileType={fileType}
                setFileType={setFileType}
              />
            ) : null}

            {/* Privacy Toggle */}
            <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
              <div className="flex items-center">
                <input
                  id="private-pieces"
                  type="checkbox"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(e.target.checked)}
                  className="w-5 h-5 text-violet-600 bg-white dark:bg-gray-600 border-gray-300 dark:border-gray-500 rounded focus:ring-violet-500 focus:ring-offset-0"
                />
                <div className="ml-3">
                  <label htmlFor="private-pieces" className="font-medium text-gray-700 dark:text-gray-200">
                    Private Pieces
                  </label>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Only you will be able to see these pieces
                  </p>
                </div>
              </div>
            </div>

            {/* Upload/Recognize Buttons */}
            <div className="mt-6">
              {fileType === 'pdf' && mode === 'single' ? (
                <div>
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      onClick={submit}
                      disabled={isUploading}
                      className="w-full py-2 px-4 bg-violet-600 text-white rounded-lg hover:bg-violet-700 disabled:opacity-50"
                    >
                      {isUploading ? 'Uploading...' : 'Upload'}
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "/dashboard/annotator?piece=45435453&page=1&step=block";
                      }}
                      disabled={isUploading}
                      className="w-full py-2 px-4 bg-violet-100 text-violet-700 rounded-lg hover:bg-violet-200 disabled:opacity-50 dark:bg-violet-900/20 dark:text-violet-400 dark:hover:bg-violet-900/30"
                    >
                      Recognize
                    </button>
                  </div>
                  <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
                    Click "Recognize" to use our state of the art optical music recognition (OMR) system that will convert your PDF sheet music into MusicXML format, enabling access to Subito's full suite of features including intelligent practice drill generation and interactive playback for your piece.
                  </p>
                </div>
              ) : (
                <button
                  onClick={submit}
                  disabled={isUploading}
                  className="w-full py-2 px-4 bg-violet-600 text-white rounded-lg hover:bg-violet-700 disabled:opacity-50"
                >
                  {isUploading ? 'Uploading...' : 'Upload'}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Right side - Upload Status */}
        <div className="w-1/2 bg-white dark:bg-gray-800 overflow-y-auto">
          <div className="p-6">
            <h2 className="text-lg font-semibold mb-6 text-gray-900 dark:text-white">Upload Status</h2>

            {isUploading ? (
              <div className="space-y-4">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Uploading files...
                  </span>
                  <div className="animate-spin rounded-full h-5 w-5 border-2 border-violet-600 border-t-transparent"></div>
                </div>
                {pdfFiles.map((file, index) => (
                  <div key={index} className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <span className="text-sm text-gray-600 dark:text-gray-300">{file.name}</span>
                    <div className="flex items-center space-x-2">
                      <div className="w-4 h-4">
                        <div className="animate-pulse w-4 h-4 bg-gray-200 dark:bg-gray-600 rounded-full"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : uploadResults.success.length > 0 || uploadResults.failed.length > 0 ? (
              <div className="space-y-2">
                {getSortedResults().map((item, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-lg flex items-start space-x-3 ${item.status === 'success'
                      ? 'bg-green-50 dark:bg-green-900/20'
                      : 'bg-red-50 dark:bg-red-900/20'
                      }`}
                  >
                    {/* Status Icon */}
                    <div className="flex-shrink-0 mt-1">
                      {item.status === 'success' ? (
                        <svg className="w-4 h-4 text-green-600 dark:text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      ) : (
                        <svg className="w-4 h-4 text-red-600 dark:text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      )}
                    </div>
                    <div className="flex-1">
                      <div className={`font-medium ${item.status === 'success'
                        ? 'text-green-700 dark:text-green-300'
                        : 'text-red-700 dark:text-red-300'
                        }`}>
                        {item.title}
                      </div>
                      {item.error && (
                        <div className="text-xs mt-1 text-red-500 dark:text-red-400">
                          {item.error}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : pdfFiles.length > 0 ? (
              <div className="space-y-2">
                {pdfFiles.map((file, index) => (
                  <div key={index} className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <span className="text-sm text-gray-600 dark:text-gray-300">{file.name}</span>
                    <div className="flex items-center space-x-3">
                      <span className="text-xs text-gray-500 dark:text-gray-400">Ready to upload</span>
                      <button
                        onClick={() => setPdfFiles(pdfFiles.filter((_, i) => i !== index))}
                        className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : mode === 'single' && file ? (
              <div className="space-y-2">
                <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                  <span className="text-sm text-gray-600 dark:text-gray-300">{file.name}</span>
                  <div className="flex items-center space-x-3">
                    <span className="text-xs text-gray-500 dark:text-gray-400">Ready to upload</span>
                    <button
                      onClick={() => setFile(null)}
                      className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-center text-gray-500 dark:text-gray-400">
                No uploads yet
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upload;